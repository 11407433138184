
import  './Report.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';

const Report = () =>{

   Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [report, setReport] = useState({isLoading: true, data: null});

   const [from_date, setFromDate] = useState("");
   const [to_date, setToDate] = useState("");

   useEffect(() => {
       dispatch(setPageName('Report'));
   }, []);

   useEffect(() => {
    if(userToken != ''){
      ReportFetch("",""); 
    }
  }, [userToken]); 


   const ReportFetch = (fromdate, todate) => {
     setLoading(true);
      postWithToken(API_URL, 'user/reports', {from_date: fromdate, to_date: todate}, userToken).then((result) => {
          console.log(result);
          setLoading(false);
          if(result.status === true){
            setReport({isLoading: false, data: result.data});
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

   //alert start
  
   const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
})

function onCloseAlert(){
      setAlert({
      show: false,
      type: null,
      text: [],
      })
}

function onShowAlert(type,msg){
      setAlert({
      type: type,
      text: msg,
      show: true
      })
}

//alert end 

      
          $(function(){
            var dtToday = new Date();
          
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
          
            if(month < 10)
                month = '0' + month.toString();
            if(day < 10)
                day = '0' + day.toString();
          
            var maxDate = year + '-' + month + '-' + day;    
            $('#from_date').attr('max', maxDate);
            $('#to_date').attr('max', maxDate);
          });


          const setDate = (val, type) => {

            const all_error_msg = [];
         
            if(type == "from"){
         
                if(to_date != ""){
                    if(Date.parse(val) > Date.parse(to_date)){
                        all_error_msg.push("From date must be less then to date");
                       onShowAlert("error", all_error_msg);
                    }else{
                        setFromDate(val);
                    }
                }else{
                    setFromDate(val);
                }
         
            }else if(type == "to"){
                if(from_date != ""){
                    if(Date.parse(from_date) > Date.parse(val)){
                        all_error_msg.push("To date must be greater than from date");
                        onShowAlert("error", all_error_msg);
                    }else{
                        setToDate(val);
                    }
                }else{
                    setToDate(val);
                }
            }
         
          }
         
          const clear_date = () => {
            setFromDate("");
            setToDate("");
            ReportFetch("", ""); 
          }

          const filter_by_date = () => {

            let flg = true;
            const all_error_msg = [];
         
            if (from_date == "") {
              all_error_msg.push("please select from date");
         
              if (flg === true) {
                flg = false;
              }
            }
            
            if (to_date == "") {
              all_error_msg.push("please select to date");
              if (flg === true) {
                flg = false;
              }
            }
         
            if(from_date != "" && to_date != ""){
                if(Date.parse(from_date) > Date.parse(to_date)){
                    all_error_msg.push("From date must be less then to date");
                }
         
                if(Date.parse(from_date) > Date.parse(to_date)){
                    all_error_msg.push("To date must be greater than from date");
                }
            }
         
            if (flg === true) {
              ReportFetch(from_date, to_date); 
            }else{
                onShowAlert("error", all_error_msg);
                
            } 
         
          }


    return(
     <div className='mypurchase'>
       <CustomAlert
              header={"Report"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                  <div className="row">
                    <div className='col-md-2'>
                        <div className='form-group'>
                          <input type='date' style={{height: 45, paddingLeft: 10}} id="from_date" placeholder='From Date' value={from_date} onChange={(event) => {setDate(event.target.value,'from')}}/>
                        </div>
                    </div>  
                    <div className='col-md-2'>
                      <div className='form-group'>
                        <input type='date' style={{height: 45, paddingLeft: 10}} id="date_of_birth" placeholder='To Date' value={to_date} onChange={(event) => {setDate(event.target.value,'to')}}/>
                      </div>
                    </div> 
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <button type='button' className='sucessBtn' onClick={() => {filter_by_date()}} >Filter</button>
                        <button type='button' className='cancelBtn' onClick={() => {clear_date()}}>Clear</button>
                      </div>  
                    </div>  
                  </div>
                        
                     {
                       report.isLoading === false && report.data != null ?
                       <div>
                              <div className='row'>
                                  <div className='col-md-4'>
                                      <div className='card'>
                                          <h2>Total Purchase</h2>
                                          
                                         <Link to="/associate/mypurchase"><span className='grdlabel'>{report.data.total_purchased}</span></Link>
                                            
                                      </div>
                                  </div>
                                  <div className='col-md-4'>
                                      <div className='card'>
                                          <h2>PBV</h2>
                                          <Link to="/associate/mydownline"><span className='grdlabel'>{report.data.total_points}</span></Link>
                                           
                                      </div>
                                  </div>
                                  <div className='col-md-4'>
                                      <div className='card'>
                                          <h2>Commission</h2>
                                          <Link to="/associate/incentive"><span className='grdlabel'>{report.data.total_commission}</span></Link>
                                      </div>
                                  </div>
                              </div>

                              <div className='row'>
                                  <div className='col-md-4'>
                                      <div className='card'>
                                          <h2>Withdraw</h2>
                                          <Link to="/associate/walletpayment"><span className='grdlabel'>{report.data.current_wallet_blance}</span></Link>
                                      </div>
                                  </div>
                                  {
                                    report.user_level != null ?
                                      <div className='col-md-4'>
                                          <div className='card'>
                                              <h2>Level</h2>
                                              
                                                <span className='grdlabel'>{report.data.user_level}</span>
                                                
                                          </div>
                                      </div>
                                      : null
                                  }  
                             
                              </div>

                        </div>
                              
                        : null }
                    
                </div>
                    
            </div>
        </div>
     </div>
    )
 }
 export default Report;