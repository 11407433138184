import   './Register.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName } from "../../../../Store/Reducers/CommonReducer";


const Register = () =>{

  
  const dispatch = useDispatch();

  const { API_URL, userData, pageName} = useSelector(state => state.common);

  useEffect(() => {
      dispatch(setPageName('Register'));
  }, []);


    return(
     <div className='register'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                  <h3 className=''>Select Registration Type</h3>
                  <Tabs>
                    <TabList>
                        <Tab>Register as Direct Seller</Tab>
                        <Tab>Register as Preferred Customer</Tab>
                    </TabList>
                    <div className='tabcontent'>
                    <TabPanel>
                <p>Before registration OTP SMS will be sent on mobile for verification and KYC can be uploaded later on, however commission, if earned, will be released after KYC 
verification only.</p>
<button type='button' className='grdlabel'> Register With Mobile OTP</button>
                    </TabPanel>
                    <TabPanel>
                    <h2>Any content 2</h2>
                    </TabPanel>
            
                    </div>
             
                  </Tabs>
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default Register;