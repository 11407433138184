import   './SaleList.css';
import product from '../../../assets/img/product.png'
const SaleList_Franchise = () =>{
    return(
     <div className='product saleList'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                <form>
                    
                    
                        <div class="form-group">
                             <input type='text' placeholder='Search...'/>
                            </div><button type="submit" class="seachbtn"> Sale Product</button></form>
                    <div className='row'>
                    <div  className='col-md-12'>
                    <div className='productlist'>
                    <div className='productlistitem'>
                        <div className='productLft'>
                        <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                  
                        <div className='productRyt'>
                            <p>Associate ID #545HGXD</p>
                            <button type='button' className='downloadIvc'><i class="fa fa-download" aria-hidden="true"></i> Download Invoice</button>
                        </div>
                        </div>
                        <div className='productlistitem'>
                        <div className='productLft'>
                        <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                  
                        <div className='productRyt'>
                            <p>Associate ID #545HGXD</p>
                            <button type='button' className='downloadIvc'><i class="fa fa-download" aria-hidden="true"></i> Download Invoice</button>
                        </div>
                        </div>
                        <div className='productlistitem'>
                        <div className='productLft'>
                        <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                  
                        <div className='productRyt'>
                            <p>Associate ID #545HGXD</p>
                            <button type='button' className='downloadIvc'><i class="fa fa-download" aria-hidden="true"></i> Download Invoice</button>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        
                    </div>
                 </div>
               
                    </div>
             
                  
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default SaleList_Franchise;