
import { Link } from 'react-router-dom';
import  './OrderBooking.css';

import { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName } from "../../../../Store/Reducers/CommonReducer";


const OrderBooking = () =>{


   const dispatch = useDispatch();

   const { API_URL, userData, pageName} = useSelector(state => state.common);

   useEffect(() => {
       dispatch(setPageName('Order Booking'));
   }, []);

    return(
     <div className='mypurchase'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                
                  <table className='table'>
                     <thead>
                        <tr>
                        <th>Sl.No</th>
                        <th>Date</th>
                        <th>Invoice No.</th>
                        <th>Seller Name</th>
                        <th>Code</th>
                        <th>Amount</th>
                        <th>Bv</th>
                        <th>Extra BV</th>
                        <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                        <td>#1</td>
                        <td>15/02/2024</td>
                        <td>SUP/25174/4</td>
                        <td>HARSHITA HERBAL</td>
                        <td>25174</td>
                        <td>2,408.00</td>
                        <td>1005</td>
                        <td>0</td>
                        <td><Link><i class="fa fa-eye" aria-hidden="true"></i></Link></td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>15/02/2024</td>
                        <td>SUP/25174/4</td>
                        <td>HARSHITA HERBAL</td>
                        <td>25174</td>
                        <td>2,408.00</td>
                        <td>1005</td>
                        <td>0</td>
                        <td><Link><i class="fa fa-eye" aria-hidden="true"></i></Link></td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>15/02/2024</td>
                        <td>SUP/25174/4</td>
                        <td>HARSHITA HERBAL</td>
                        <td>25174</td>
                        <td>2,408.00</td>
                        <td>1005</td>
                        <td>0</td>
                        <td><Link><i class="fa fa-eye" aria-hidden="true"></i></Link></td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>15/02/2024</td>
                        <td>SUP/25174/4</td>
                        <td>HARSHITA HERBAL</td>
                        <td>25174</td>
                        <td>2,408.00</td>
                        <td>1005</td>
                        <td>0</td>
                        <td><Link><i class="fa fa-eye" aria-hidden="true"></i></Link></td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>15/02/2024</td>
                        <td>SUP/25174/4</td>
                        <td>HARSHITA HERBAL</td>
                        <td>25174</td>
                        <td>2,408.00</td>
                        <td>1005</td>
                        <td>0</td>
                        <td><Link><i class="fa fa-eye" aria-hidden="true"></i></Link></td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>15/02/2024</td>
                        <td>SUP/25174/4</td>
                        <td>HARSHITA HERBAL</td>
                        <td>25174</td>
                        <td>2,408.00</td>
                        <td>1005</td>
                        <td>0</td>
                        <td><Link><i class="fa fa-eye" aria-hidden="true"></i></Link></td>
                        </tr>
                       
                     </tbody>
                     </table>
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default OrderBooking;