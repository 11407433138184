import   './Product.css';
import product from '../../../assets/img/product.png'
import invoice from '../../../assets/img/invoice.png'
const Product = () =>{
    return(
     <div className='product'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                    <div className='row'>
                    <div  className='col-md-5'>
                    <div className='productlist'>
                    <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                        <div className='productlistitem'>
                    <div className='productlistitem-img'>
                         <img src={product}/>
                        </div>
                        <div className='productlistitem-dtls'>
                            <h3>Health Booster Supplement</h3>
                            <p>Product Code: 004</p>
                            <p>Product Quentity: 40</p>
                            <b>₹ 14000</b>
                        </div>
                        </div>
                    </div>
                 </div>
                 <div  className='col-md-7'>
                    <div className='invoice'>
                    <img src={invoice}/>
                    </div>
                    </div>
                    </div>
             
                  
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default Product;