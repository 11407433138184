import   './Profile.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';


const Profile = () =>{

   Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [dob, setDateOfBirth] = useState("");
   const [pan_card, setPanCard] = useState("");
   const [gst_no, setGSTNo] = useState("");
   const [address, setAddress] = useState("");
   const [address_1, setAddressOne] = useState("");
   const [address_2, setAddressTwo] = useState("");
   const [city, setCity] = useState("");
   const [pin_code, setPinCode] = useState("");
   const [state, setState] = useState("");

   const [gender, setGender] = useState("");
   const [blood_group, setBloodGroup] = useState("");

   const [state_list, setStateList] = useState([]);

   const pagename = useCallback(() => {
      dispatch(setPageName('Profile'));
  },[pageName])

  useEffect(() => {
      pagename();
      stateList();
  }, []);

  const gender_list = [{id: 1, name: 'Male'}, {id:2, name: 'Female'}];

  useEffect(() => {

   console.log(userData);

   if(userData.state_id != null){
      setState(userData.state_id);
   }

   if(userData.dob != null){
      setDateOfBirth(userData.dob);
   }else{
      setDateOfBirth("");
   }

   if(userData.gender != null){
      setGender(userData.gender);
   }else{
      setGender("");
   }

   if(userData.blood_group != null){
      setBloodGroup(userData.blood_group);
   }else{
      setBloodGroup("");
   }

   if(userData.pan_card != null){
      setPanCard(userData.pan_card);
   }else{
      setPanCard("");
   }

   if(userData.gst_no != null){
      setGSTNo(userData.gst_no);
   }else{
      setGSTNo("");
   }

   if(userData.address != null){
      setAddress(userData.address);
   }else{
      setAddress("");
   }

   if(userData.address_1 != null){
      setAddressOne(userData.address_1);
   }else{
      setAddressOne("");
   }

   if(userData.address_2 != null){
      setAddressTwo(userData.address_2);
   }else{
      setAddressTwo("");
   }

   if(userData.city != null){
      setCity(userData.city);
   }else{
      setCity("");
   }

   if(userData.pin_code != null){
      setPinCode(userData.pin_code);
   }else{
      setPinCode("");
   }

  
  }, [userData]);

  const stateList = () => {
   postWithToken(API_URL, 'state-list', {}, userToken).then((result) => {
      console.log(result);
        if(result.status === true){
             setStateList(result.data);
          }else{
            
          }
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }

   //alert start
  
   const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  
  //alert end 

  

  $(function(){
   var dtToday = new Date();
 
   var month = dtToday.getMonth() + 1;
   var day = dtToday.getDate();
   var year = dtToday.getFullYear();
 
   if(month < 10)
       month = '0' + month.toString();
   if(day < 10)
       day = '0' + day.toString();
 
   var maxDate = year + '-' + month + '-' + day;    
   $('#date_of_birth').attr('max', maxDate);
 });

 function ctype_alnum(str) {
   var code, i, len;
     var isNumeric = false, isAlpha = false; //I assume that it is all non-alphanumeric
     
   for (i = 0, len = str.length; i < len; i++) {
     code = str.charCodeAt(i);
         
         
         switch (true){
             case code > 47 && code < 58: // check if 0-9
                 isNumeric = true;
                 break;
             case (code > 64 && code < 91) || (code > 96 && code < 123): //check if A-Z or a-z
                 isAlpha = true;
                 break;
             default: // not 0-9, not A-Z or a-z
                 return false; //stop function with false result, no more checks
                 
         }
 
   }
     
   return isNumeric && isAlpha; //return the loop results, if both are true, the string is certainly alphanumeric
 };

 const updateProfile = () => {
   let flg = true;
   const all_error_msg = [];

   if (dob == "") {
      all_error_msg.push("Please select your date of birth");

      if (flg === true) {
        flg = false;
      }
    }else{

      var dob_val = new Date(dob);  
      //calculate month difference from current date in time  
      var month_diff = Date.now() - dob_val.getTime();  
      
      //convert the calculated difference in date format  
      var age_dt = new Date(month_diff);   
      
      //extract year from date      
      var year = age_dt.getUTCFullYear();  
      
      //now calculate the age of the user  
      var age = Math.abs(year - 1970);  

      if(age < 18){
         all_error_msg.push("date of birth greater than 18 years");

         if (flg === true) {
           flg = false;
         }
      }
      
      //display the calculated age  
      //document.write("Age of the date entered: " + age + " years");  

   }

   if (pan_card.search(/\S/) == -1) {
      all_error_msg.push("Please enter your pan card number");

      if (flg === true) {
        flg = false;
      }
    }else{
       //console.log(ctype_alnum(pan_card));
       if(pan_card.length < 10){
          all_error_msg.push("Please enter 10-digit alphanumeric pan number");

          if (flg === true) {
            flg = false;
          } 
       }else if(!ctype_alnum(pan_card)){
          all_error_msg.push("Please enter 10-digit alphanumeric pan number");

          if (flg === true) {
            flg = false;
          } 
       }
          
    }

   /*if (gst_no.search(/\S/) == -1) {
      all_error_msg.push("Please enter your gst no number");

      if (flg === true) {
        flg = false;
      }
    }else{
      if(gst_no.length < 15){
         all_error_msg.push("Please enter 15-digit GSTIN number");

         if (flg === true) {
           flg = false;
         } 
      }
    }*/

   if (gender.search(/\S/) == -1) {
      all_error_msg.push("Please select your gender");

      if (flg === true) {
         flg = false;
      }
   }

   if (blood_group.search(/\S/) == -1) {
      all_error_msg.push("Please enter your blood group");

      if (flg === true) {
         flg = false;
      }
   }

    if (address.search(/\S/) == -1) {
      all_error_msg.push("Please enter address");

      if (flg === true) {
        flg = false;
      }
    }

    if (state == "") {
      all_error_msg.push("Please select state");

      if (flg === true) {
        flg = false;
      }
    }

    if (city.search(/\S/) == -1) {
      all_error_msg.push("Please enter city name");

      if (flg === true) {
        flg = false;
      }
    }

    if (pin_code.search(/\S/) == -1) {
      all_error_msg.push("Please enter pin code");

      if (flg === true) {
        flg = false;
      }
    }else{
         if(pin_code.length < 6){
            all_error_msg.push("Please enter 6-digit pin code");
  
            if (flg === true) {
              flg = false;
            } 
         }
    }

   if (flg === true) {

    setLoading(true);
    postWithToken(API_URL, 'user/profile-update', {gender: gender, blood_group: blood_group, state_id: state,pan_card: pan_card, gst_no: gst_no, dob: dob, address_1: address_1, address_2: address_2, address: address,city: city, pin_code: pin_code }, userToken).then((result) => {
      console.log(result);
        if(result.status === true){
              setLoading(false);
              all_error_msg.push(result.message);
              onShowAlert("success", all_error_msg); 
              fetchUserData();
          }else{
            all_error_msg.push(result.message);
            onShowAlert("error", all_error_msg);
            setLoading(false);
          }
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
    }else{
      onShowAlert("error", all_error_msg);
      
    } 
 }

 const fetchUserData = () => {
   
   postWithToken(API_URL, 'get-user-details', {}, userToken).then((result) => {
      if(result.status === true){
           dispatch(setUserDataAfterLogin(result.data));
           sessionStorage.setItem("userData",JSON.stringify(result.data));
       }else{
           setLoading(false);
       }
   })
   .catch((error) => {
       console.log("Error : ", error);
   });
}


    return(
     <div className='profile'>
       <CustomAlert
               header={"Profile Update"}
               btnText={"Accept"}
               text={alert.text}
               type={alert.type}
               show={alert.show}
               onClosePress={onCloseAlert}
               pressCloseOnOutsideClick={true}
               alertStyles={{}}
               headerStyles={{}}
               textStyles={{}}
               buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>

                <div className='card'>
                    <h3>Sponsor Information</h3>
                 <div className='row'>
                   
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Registration Date</label>
                        <input type='date' readOnly value={userData != null ? userData.joining_date  :  null}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Referral ID</label>
                        <input type='text' readOnly value={userData != null ? userData.user_referral_code  :  null}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Referral Name</label>
                        <input type='text' readOnly value={userData != null ? userData.name: null }/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Sponsor ID</label>
                        <input type='text' readOnly value={userData != null ?  userData.referral_code: null}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Sponsor Name</label>
                        <input type='text' readOnly value={userData != null ? userData.refer_user_name : null}/>
                     </div>
                    </div>
                 </div>
                 <h3>Personal Information</h3>
                 <div className='row'>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Name</label>
                        <input type='text' value={userData.name} readOnly/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Date Of Birth*</label>
                        <input type='date' id="date_of_birth" value={dob} onChange={(event) => setDateOfBirth(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                           <label>Select Gender*</label>
                           <select value={gender} onChange={(event) => setGender(event.target.value)}>
                                 <option value="">Select Gender</option>
                                 {
                                  
                                    gender_list.map((item, index) => {

                                       return(
                                          <option key={'gender_' + index} value={item.name}>{item.name}</option> 
                                       )

                                    })
                                   
                                 }
                           </select>
                        </div>
                     </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Blood Group*</label>
                        <input type='text' value={blood_group} onChange={(event) => setBloodGroup(event.target.value)} />
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>PAN*</label>
                        <input type='text' maxLength={10}  value={pan_card} onChange={(event) => setPanCard(event.target.value)}/>
                     </div>
                    </div>
                                     
                 </div>
                 <h3>Contact Information</h3>
                 <div className='row'>
                    <div className='col-md-8'>
                     <div className='form-group'>
                        <label>Address [1]*</label>
                        <input type='text' value={address} onChange={(event) => setAddress(event.target.value)}/>
                     </div>
                    </div>
                 
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Address[2]</label>
                        <input type='text'  value={address_1} onChange={(event) => setAddressOne(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Address[3]</label>
                        <input type='text'  value={address_2} onChange={(event) => setAddressTwo(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                           <label>Select State*</label>
                           <select value={state} onChange={(event) => setState(event.target.value)}>
                                 <option value="">Select State</option>
                                 {
                                    state_list.length > 0 ?
                                    state_list.map((item, index) => {

                                       return(
                                          <option key={'state_' + index} value={item.id}>{item.name}</option> 
                                       )

                                    })
                                    : null
                                 }
                           </select>
                        </div>
                     </div>

                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>City/Locality*</label>
                        <input type='text' value={city} onChange={(event) => setCity(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                     <div className='form-group'>
                        <label>Pincode*</label>
                        <input type='text' maxLength={6} value={pin_code} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                                return true;
                                        }else{event.preventDefault();}}} onChange={(event) => setPinCode(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             {/*<button type='button' className='cancelBtn'>Cancel</button>*/}
                             <label>&nbsp;</label>
                             <button type='button' className='sucessBtn' onClick={() => {updateProfile()}} >Submit</button>
                        </div>
                    </div>
                  
                 </div>
                
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default Profile;