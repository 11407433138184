import   './MakeTransfer.css';
const MakeTransfer = () =>{
    return(
     <div className='maketransfer'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                   <div className='row'>
                    <div className='col-md-12'>
                    <h2> Key Information</h2>
                        </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             <label>Your ID No.</label>
                             <input type='text' value={55454455} readOnly />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             <label>Name</label>
                             <input type='text' value='ARCHANA SMRITI SINHA' readOnly />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             <label>Name</label>
                             <input type='text' placeholder='ID Number'  />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             <label>Name</label>
                             <input type='text' value='ARCHANA SMRITI SINHA' readOnly />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             <label>Available BV</label>
                             <input type='text' value={0} readOnly />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             <label>BV</label>
                             <input type='text' placeholder='BV'  />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                             <button type='button' className='cancelBtn'>Cancel</button>
                             <button type='button' className='sucessBtn'>Submit</button>
                        </div>
                    </div>
                   </div>
                  
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default MakeTransfer;