import   './support.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';
const Support = () =>{

   Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [query_list, setQueryList] = useState({IsLoading: true, list: [], total_count: 0});
   const [query, setQuery] = useState("");
   const [list_limit, setListLimit] = useState(5);
   const [page_selected, setPageSelected] = useState(null);

   const pagename = useCallback(() => {
      dispatch(setPageName('Support'));
  },[pageName])

  useEffect(() => {
      pagename();
  }, []);

  useEffect(() => {
   if(userToken != ''){
     queryFetch(0,list_limit); 
   }
 }, [userToken]); 


  const queryFetch = (offset, limit) => {
     postWithToken(API_URL, 'francise/support-list', {offset: offset, limit: limit}, userToken).then((result) => {
         console.log(result);
         if(result.status === true){
            setQueryList({IsLoading : false, list: result.data, total_count: result.total_count});
         }
     })
     .catch((error) => {
         console.log("Error : ", error);
     });
 }

   //alert start
  
   const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  
  //alert end 
   
    const CreateQuery = () => {
      let flg = true;
      const all_error_msg = [];
 
       if (query.search(/\S/) == -1) {
         all_error_msg.push("Please enter your query");
   
         if (flg === true) {
           flg = false;
         }
       }


      if (flg === true) {

         setLoading(true);
         postWithToken(API_URL, 'francise/support/create', {question: query}, userToken).then((result) => {
            console.log(result);
            if(result.status === true){
                  setLoading(false);
                  all_error_msg.push(result.message);
                  onShowAlert("success", all_error_msg); 
                  
               }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
                  setLoading(false);
               }
            })
            .catch((error) => {
            console.log("Error : ", error);
            });
       }else{
         onShowAlert("error", all_error_msg);
         
       } 
    }

    const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(query_list.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % query_list.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              queryFetch(newOffset, list_limit);
              setItemOffset(newOffset);
            };
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }


    return(
      <>
     <div className='profile'>
             <CustomAlert
               header={"Support"}
               btnText={"Accept"}
               text={alert.text}
               type={alert.type}
               show={alert.show}
               onClosePress={onCloseAlert}
               pressCloseOnOutsideClick={true}
               alertStyles={{}}
               headerStyles={{}}
               textStyles={{}}
               buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                 <h3>Enter Your Query</h3>
                 <div className='row'>
                    
                    <div className='col-md-12'>
                     <div className='form-group'>
                        <textarea style={{width: '80%',height: 150}} onChange={(event) => setQuery(event.target.value)}></textarea>
                     </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='form-group'>
                             {/*<button type='button' className='cancelBtn'>Cancel</button>*/}
                             <button type='button' className='sucessBtn' onClick={() => {CreateQuery()}} >Submit</button>
                        </div>
                    </div>
                  
                 </div>
                 
                    
                  
                 </div>
                </div>
            </div>
        </div>


        { query_list.IsLoading === false && query_list.list.length > 0 ?
            <h3>Submited Query List</h3>
            : null }

        {
            query_list.IsLoading === false && query_list.list.length > 0 ?
               
                  query_list.list.map((item, index) => {

                           return(
                              <div className='support_card'>
                              <div className='row'>
                              <div  className='col-md-12'>
                  
                                    <div className="query_list_main">
                                       <ul>
                                          <li>Token Number: {item.question_code}</li>
                                          <li>Date : {Moment(item.date).format('DD/MM/YYYY')}</li>
                                          <li>Status: {item.status == 0 ? <span style={{color: 'orange'}}>Pending</span> : <span style={{color: 'green'}}>Completed</span> }</li>
                                       </ul>
                  
                                       <div className="query_question">
                                          <label>Query:</label> 
                                          <div className="query_question_in">{item.question}</div>
                                       </div>
                                       {
                                          item.answer != null ?
                                             <div className="query_answer">
                                                <label>Answer:</label> 
                                                <div className="query_answer_in">{item.answer}</div>
                                             </div>
                                          : null
                                       }      
                  
                                    </div>
                  
                                 </div>          
                              </div>
                           </div>
                           )
                   })
         : null}
         


            
                  {
                        query_list.IsLoading === false && query_list.list.length > 0 ?
                           <div className="pagination_div">
                                <PaginatedItems itemsPerPage={list_limit} />
                           </div>
                        :
                        null
                  }  

        </>
     
    )
 }
 export default Support;