import { Link } from 'react-router-dom';
import close from '../../../../assets/img/close.png';
import './Notification.css';

import { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName } from "../../../../Store/Reducers/CommonReducer";


const Notification = () =>{

    const dispatch = useDispatch();

   const { API_URL, userData, pageName} = useSelector(state => state.common);

   useEffect(() => {
       dispatch(setPageName('Notification'));
   }, []);


    return(
     <div className='notifications'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                    <h3>Up to 50% discount <Link><img src={close}/> </Link></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <div className='card'>
                    <h3>Up to 50% discount <Link><img src={close}/> </Link></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <div className='card'>
                    <h3>Up to 50% discount <Link><img src={close}/> </Link></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <div className='card'>
                    <h3>Up to 50% discount <Link><img src={close}/> </Link></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <div className='card'>
                    <h3>Up to 50% discount <Link><img src={close}/> </Link></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <div className='card'>
                    <h3>Up to 50% discount <Link><img src={close}/> </Link></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <div className='card'>
                    <h3>Up to 50% discount <Link><img src={close}/> </Link></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default Notification;