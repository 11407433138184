
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import GridLoader from "react-spinners/GridLoader";
import { setUserDataAfterLogin, localstorage_TokenAdd } from "../Store/Reducers/CommonReducer";

const Initial = () =>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#C00305");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;
  const { API_URL, userData} = useSelector(state => state.common);

    useEffect(() => {

        if(sessionStorage.getItem("userId") != null){
          
          if(JSON.parse(sessionStorage.getItem("userData")).role_name == 'FRANCISE'){
            navigate("/franchise/dashboard");
          }else if(JSON.parse(sessionStorage.getItem("userData")).role_name == 'ASSOCIATE'){
            navigate("/associate/dashboard");
          }
        }else{
            navigate("/login");
        }

    }, [userData]);

   return(
    <div className="activityLoader">
            <GridLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
            Loader="GridLoader"
            /> 
    </div>     
  )
}
export default Initial;