
import  './Report.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';

const Report_Franchise = () =>{

   Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [report, setReport] = useState({IsLoading: true, list: [], total_count: 0});
   const [list_limit, setListLimit] = useState(20);
   const [page_selected, setPageSelected] = useState(null);


   useEffect(() => {
       dispatch(setPageName('Report'));
   }, []);

   useEffect(() => {
    if(userToken != ''){
      ReportFetch(0,list_limit); 
    }
  }, [userToken]); 


   const ReportFetch = (offset, limit) => {
      postWithToken(API_URL, 'francise/reports', {offset: offset, limit: limit}, userToken).then((result) => {
          console.log(result);
          if(result.status === true){
            setReport({IsLoading : false, list: result.data, total_count: result.total_count});
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

   //alert start
  
   const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
})

function onCloseAlert(){
      setAlert({
      show: false,
      type: null,
      text: [],
      })
}

function onShowAlert(type,msg){
      setAlert({
      type: type,
      text: msg,
      show: true
      })
}

//alert end 

       const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(report.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % report.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              ReportFetch(newOffset, list_limit);
              setItemOffset(newOffset);
            };
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }


    return(
     <div className='mypurchase'>
       <CustomAlert
              header={"Report"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                  
                  <table className='table'>
                     <thead>
                        <tr>
                        <th>Associate name</th>
                        <th>Contact No</th>
                        <th>Date</th>
                        <th>Invoice No.</th>
                        <th>Total sales amount</th>
                        <th>Download Invoice</th>
                        </tr>
                     </thead>
                     {
                       report.IsLoading === false && report.list.length > 0 ?
                              <tbody>
                                 {

                                report.list.map((item, index) => {
                                    return(
                                      <tr key={'report_' + index}>
                                            <td>{item.user.name}</td>
                                            <td>{item.user.phone}</td>
                                            <td>{Moment(item.date).format('DD/MM/YYYY')}</td>
                                            <td>{item.invoice_no}</td>
                                            <td>{'\u20B9'} {item.total}</td>
                                            <td><a href={SITE_URL + 'invoice-generate/' + item.custom_order_id} download style={{ textDecoration: 'none', marginLeft: 10}}>Download Invoice</a></td>
                                      </tr>
                                       
                                       );
                                    })
                              }
                              
                              </tbody>
                               : report.IsLoading === false && report.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                     </table>
                </div>
                {
                       report.IsLoading === false && report.list.length > 0 ?
                          <div className="pagination_div">
                                  <PaginatedItems itemsPerPage={list_limit} />
                        </div>
                       :
                       null
                }         
            </div>
        </div>
     </div>
    )
 }
 export default Report_Franchise;