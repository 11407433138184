import   './DirectSupport.css';
import { Link } from 'react-router-dom';


const DirectSupport = () =>{
    return(
     <div className='directsupport'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                <form>
                    
                        <div className='form-group'>
                        <input type='text' placeholder='id'/>
                        </div>
                        <div className='form-group'>
                          <input type='text' placeholder='name'/>
                        </div>
                        <button type='submit' className='seachbtn'><i class="fa fa-search" aria-hidden="true"></i></button>
                    </form>
                    <div className='row'>
            <div className='col-md-4'>
                <div className='card'>
                    <h2>Maintained Level</h2>
                     <span className='grdlabel'>Super Star</span>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card'>
                    <h2>Highest Level</h2>
                     <span className='grdlabel'>Super Star</span>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card'>
                    <h2>Status</h2>
                     <span className='grdlabel'>Super Star</span>
                </div>
            </div>
         </div>
                    <table className='table'>
                    <thead>
                    <tr> 
                        <th>Name</th>
                        <th>Count</th>
                        <th >Amount for Referral</th>
                        <th>% Level</th>
                        <th colSpan={7}>Current Month BV</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr> 
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={2}><b>PBV</b></td>
                        <td colSpan={2}><b>GBV</b></td>
                        <td colSpan={3}><b>TBV</b></td>
                    </tr>
                    <tr> 
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>BV</b></td>
                        <td><b>Extra</b></td>
                        <td><b>BV</b></td>
                        <td><b>Extra</b></td>
                        <td><b>BV</b></td>
                        <td><b>Extra</b></td>
                        <td><b>Total</b></td>
                    </tr>
                 
                    <tr>
                        <td>ARCHANA SMRITI SINHA</td>
                        <td>436</td>
                        <td>12646</td>
                        <td>15</td>
                        <td>0</td>
                        <td>0</td>
                        <td>8198</td>
                        <td>0</td>
                        <td>8198</td>
                        <td>0</td>
                        <td>8198</td>
                    </tr>
              
              
          
                
                    </tbody>
             
                 </table>
                 <div className='row'>
            <div  className='col-md-4'>
                <div className='card'>
                    <Link className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <h2>PAN Verification Status</h2>
                    <span>Verified by at 09/12/2017 - 4:03 PM</span>
                    <div className='note'>
                    Your PAN is Verified and Your PAN 
                       status is Confirmed
                    </div>
                </div>
            </div>
            <div  className='col-md-4'>
                <div className='card'>
                    <Link className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <h2>KYC Verification Status</h2>
                    <span>Verified by at 09/12/2017 - 4:03 PM</span>
                    <div className='note'>
                    Your KYC status is Confirmed
                    </div>
                </div>
            </div>
            <div  className='col-md-4'>
                <div className='card'>
                    <Link className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <h2>Bank Account Verification..</h2>
                    <span>Verified by at 09/12/2017 - 4:03 PM</span>
                    <div className='note'>
                    Your Bank Account is Verified and Your 
Bank status is Confirmed
                    </div>
                </div>
            </div>
        </div>
        <div className='row'>
        <div  className='col-md-12'>
            <div className='tableblock'>
            <div className='tableblock-top'><h2>Ticket Log</h2> <Link><i class="fa fa-pencil" aria-hidden="true"></i> Generate Ticket</Link></div>
            <div className='tableBlockbody'>
                <p>No ticket has been created</p>
            </div>
            </div>
           
            </div>
            </div>
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default DirectSupport;