import   './MyUpline.css';
import crown from "../../../../assets/img/crown.png"
import starg from "../../../../assets/img/starg.png"
import star from "../../../../assets/img/star.png"


import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';

const MyUpline = () =>{
    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
 
    const dispatch = useDispatch();
 
    const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [direct_downline, setDirectDownline] = useState({IsLoading: true, list: [], total_count: 0});
    const [list_limit, setListLimit] = useState(20);
    const [page_selected, setPageSelected] = useState(null);

    useEffect(() => {
        dispatch(setPageName('My Upline'));
    }, []);
 
    useEffect(() => {
     if(userToken != ''){
       DownlineFetch(0,list_limit); 
     }
   }, [userToken]); 
 
 
    const DownlineFetch = (offset, limit) => {
 
       let post_data = {
        offset: offset, 
        limit: limit,
      };
 
       setLoading(true);
 
       postWithToken(API_URL, 'user/my-uplines', post_data, userToken).then((result) => {
        console.log(result);
           if(result.status === true){
             setLoading(false);
             setDirectDownline({IsLoading : false, list: result.data, total_count: result.total_count});
           }
       })
       .catch((error) => {
           console.log("Error : ", error);
       });
   }
 
    //alert start
   
    const [alert, setAlert] = useState({
       type: null,
       text: [],
       show: false
 })
 
 function onCloseAlert(){
       setAlert({
       show: false,
       type: null,
       text: [],
       })
 }
 
 function onShowAlert(type,msg){
       setAlert({
       type: type,
       text: msg,
       show: true
       })
 }
 
 //alert end 
 
        const [itemOffset, setItemOffset] = useState(0);
         function PaginatedItems({ itemsPerPage }) {
             // Here we use item offsets; we could also use page offsets
             // following the API or data you're working with.
            
             // Simulate fetching items from another resources.
             // (This could be items from props; or items loaded in a local state
             // from an API endpoint with useEffect and useState)
             const endOffset = itemOffset + itemsPerPage;
             //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
             //const currentItems = roleList.list.slice(itemOffset, endOffset);
             const pageCount = Math.ceil(direct_downline.total_count / itemsPerPage);
           
             // Invoke when user click to request another page.
             const handlePageClick = (event) => {
               const newOffset = (event.selected * itemsPerPage) % direct_downline.total_count;
               /*console.log(
                 `User requested page number ${event.selected}, which is offset ${newOffset}`
               );*/
 
               setPageSelected(event.selected);
               DownlineFetch(newOffset, list_limit);
               setItemOffset(newOffset);
             };
             return (
               <>
                 <ReactPaginate
                   breakLabel="..."
                   nextLabel=" > "
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   previousLabel=" < "
                   renderOnZeroPageCount={null}
                   forcePage={page_selected}
                 />
               </>
             );
           }

 

    return(
     <div className='myupline'>
        <CustomAlert
              header={"Downline Search"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                  <table className='table'>
                    <thead>
                        <th>Rank</th>
                        <th>ID No.</th>
                        <th>Name</th>
                        <th>Mobile</th>
                    </thead>
                    <tbody>
                    {
                       direct_downline.IsLoading === false && direct_downline.list.length > 0 ?
                      
                       direct_downline.list.map((item, index) => {

                            let row_c = "";

                            if(parseInt(index + 1) % 2 == 0){
                                row_c = 'subheading';
                            }

                            return(
                                <tr key={index}>
                                <td>
                                    {/*<label className='crown'><img src={crown}></img>Kohinoor Crown President</label>*/}
                                    <label>{item.level}</label>
                                </td>
                                <td>{item.id_no}</td>
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                </tr>
                            );
                        })

                        : direct_downline.IsLoading === false && direct_downline.list.length == 0 ?

                        <tr>
                           <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                       </tr>

                       : direct_downline.IsLoading === true ?

                           <tr>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                           </tr>

                       : null }     
                        
                    </tbody>
                  </table>
       
             
   
    
                </div>
                {
                    direct_downline.IsLoading === false && direct_downline.list.length > 0 ?
                        <div className="pagination_div">
                                <PaginatedItems itemsPerPage={list_limit} />
                    </div>
                    :
                    null
                }       
            </div>
        </div>
     </div>
    )
 }
 export default MyUpline;