import './IdCard.css';
import profile_image from "../../../../assets/img/profile.png";
import logo_image from "../../../../assets/img/logo.png";
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithTokenFormData} from "../../../../Service/service";
import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';

const IdCard_Associate = () =>{

    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
    const dispatch = useDispatch();
    const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
  
    const pagename = useCallback(() => {
        dispatch(setPageName('Id Card'));
    },[pageName])

    useEffect(() => {
        pagename();
        fetchUserData();
    }, []);

     //alert start
  
     const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
       
    const fetchUserData = () => {
      postWithToken(API_URL, 'get-user-details', {}, userToken).then((result) => {
         if(result.status === true){
              dispatch(setUserDataAfterLogin(result.data));
          }else{
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

    return(
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <CustomAlert
              header={"Bank Document"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
            {
              loading === true ?
              <div className="activityLoader">
                    <GridLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      Loader="GridLoader"
                    /> 
            </div>     
            : null
            }

                  {
                        userData.address_proof_path != null ?
                           <>
                            {userData.is_address_verified == 1 ?
                            <>

                              <div style={{marginBottom: 20, paddingLeft: 20, textAlign: 'center'}}>
                                      <a className='idCardDownloadBtn' href={SITE_URL + 'user-id-card-generate/' + userData.user_id} target='_blank' download style={{color: '#fff', textDecoration: 'none', marginLeft: 10}}>Download ID Card</a>
                              </div>
                            <tr>
                              <td align="center" valign="top"  class="outer">
                                <table width="350" border="0" cellspacing="0" cellpadding="0" className="mobile-shell " >
                                  <tr>
                                    <td class="td" className='outerBorder' style={{width:"350px", minWidth:"350px", fontSize:"0pt", lineHeight:"0pt", margin:0, fontWeight:"normal", padding: "10px 30px", background: '#5b8f66a6'}}>
                                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                          <td>
                                        
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                              <tr>
                                                <td>
                                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                    <tr>
                                                      <th class="column" style={{fontSize:"0pt", lineHeight:"0pt", padding:0, margin:0, fontWeight:"normal"}}>
                                                        <table width="100%" border="0" cellspacing="0"
                                                          cellpadding="0">
                                                          <tr>
                                                            <td class="img m-center" style={{fontSize:"0pt", lineHeight:"0pt", textAlign:"left", padding: "15px 0 15px"}}>
                                                              <a href="#"><img src={logo_image}  width="100" border="0" alt="" /></a>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </th>
                                                      <th class="column-empty"  width="1" style={{fontSize:"0pt", lineHeight:"0pt", padding:0, margin:0, fontWeight:"normal"}}>
                                                      </th>
                                                      <th class="column" style={{fontSize:"0pt", lineHeight:"0pt", padding:0, margin:0, fontWeight:"normal"}}>
                                                        <table width="100%" border="0" cellspacing="0"
                                                          cellpadding="0">
                                                          <tr>
                                                            <td class="text-header" style={{padding: "15px 20px 15px 20px", color:"#000000",  fontSize:"16px",  textAlign:"right"}}>
                                                              <multiline>No : {userData.user_id}</multiline>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </th>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                        
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style={{borderBottom: ".5pt solid transparent", margin:"0 0 10px 0"}}>
                                              <tr><td></td></tr>
                                            </table>
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                              <tr>
                                                <td class="p30-15" style={{padding: 0}} align="center">
                                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        
                                                    <tr>
                                                      <td>
                                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                          <tr>
                                                            <th class="column-top" width="100" style={{fontSize:"0pt", lineHeight:"0pt", padding:0, margin:0, fontWeight:"normal", verticalAlign:"top"}}>
                                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                <tr>
                                                                  <td class="fluid-img pb15" style={{fontSize:"0pt", lineHeight:"0pt", textAlign:"left"}}>
                                                                    {
                                                                      userData != null ?

                                                                      userData.profile_image_path != null ?
                                                                      <img src={userData.profile_image_path} width="100"  border="0" alt="" />
                                                                      : 
                                                                      <img src={profile_image} width="100"  border="0" alt="" />
                                                                      : null
                                                                    }
                                                                    
                                                                    </td> 
                                                                </tr>
                                                              
                                                              </table>
                                                            </th>
                                                            <th class="column-empty2" width="11"
                                                              style={{fontSize:"0pt", lineHeight:"0pt", padding:0, margin:0, fontWeight:"normal"}}>
                                                            </th>
                                                            <th   style={{paddingTop: "5px", margin:0, fontWeight:"normal", verticalAlign:"top"}}>
                                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                <tr>
                                                                  <td width="70"  class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>Name: </td>
                                                                  <td class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>{userData != null ? userData.full_name : null}</td>
                                                                </tr>
                        
                                                                <tr>
                                                                  <td class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>Dob: </td>
                                                                  <td class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>{userData != null ? userData.dob : null}</td>
                                                                </tr>
                        
                                                                <tr>
                                                                  <td class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>Gender: </td>
                                                                  <td class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>{userData != null ? userData.gender : null}</td>
                                                                </tr>
                        
                                                                <tr>
                                                                  <td class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>B- Group : </td>
                                                                  <td class="text2 pb20" style={{color:"#000000",  fontSize:"14px", lineHeight:"10px", textAlign:"left", paddingBottom:"10px"}}>{userData != null ? userData.blood_group : null}</td>
                                                                </tr>
                        
                                                              </table>
                                                            </th>
                                                            
                                                          </tr>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                        
                        
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style={{borderBottom: "2pt solid #000", margin:"12px 0 15px 0"}}>
                                              <tr><td></td></tr>
                                            </table>
                                    
                        
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                              <tr>
                                                  <td class="text pb20" style={{color:"#000",  fontSize:"22px", lineHeight:"24px", textAlign:"center", fontWeight: "600"}}>
                                                    Veda Natural Herbal
                                                  </td>
                                              
                                              </tr>
                                            </table>
                        
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            </>

                            : userData.is_address_verified == 0 ?

                            <p style={{textAlign: 'center', marginTop: 200}}>Your KYC status is <span style={{color: '#fba300'}}>Pendding for admin verify</span></p>
                            : null}

                            </>
                         : 
                          <p style={{textAlign: 'center', marginTop: 200}}>Please upload your KYC Document for admin verify</p>   
                    }


            
        </table>
    )
 }
 export default IdCard_Associate;