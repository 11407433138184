import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import  './ProductList.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName } from "../../../../Store/Reducers/CommonReducer";


const ProductList = () =>{

  const dispatch = useDispatch();

  const { API_URL, userData, pageName} = useSelector(state => state.common);

  useEffect(() => {
      dispatch(setPageName('Product List'));
  }, []);


    return(
     <div className='mydocument productlist'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                  <h3 className='hading'>Product List Offer Products(134)</h3>
                  <Tabs>
                    <TabList>
                        <Tab>Offer Products(134)</Tab>
                        <Tab>Regular Products(639)</Tab>
                        <Tab>New Products (6217)</Tab>
                    </TabList>
                    <div className='tabcontent'>
                    <TabPanel>
                    <Accordion>
                    <AccordionItem header="Health And Nutrition (28)">
                    <table className='table'>
                     <thead>
                        <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Unit</th>
                        <th>MRP</th>
                        <th>Price</th>
                        <th>Bv</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                        <td>#1</td>
                        <td>Buy 1 Immune 20X Tablet & Get 1 Aloe Digest</td>
                        <td>1124601</td>
                        <td>Pcs</td>
                        <td>270</td>
                        <td>270</td>
                        <td>72</td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>Buy 1 Immune 20X Tablet & Get 1 Aloe Digest</td>
                        <td>1124601</td>
                        <td>Pcs</td>
                        <td>270</td>
                        <td>270</td>
                        <td>72</td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>Buy 1 Immune 20X Tablet & Get 1 Aloe Digest</td>
                        <td>1124601</td>
                        <td>Pcs</td>
                        <td>270</td>
                        <td>270</td>
                        <td>72</td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>Buy 1 Immune 20X Tablet & Get 1 Aloe Digest</td>
                        <td>1124601</td>
                        <td>Pcs</td>
                        <td>270</td>
                        <td>270</td>
                        <td>72</td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>Buy 1 Immune 20X Tablet & Get 1 Aloe Digest</td>
                        <td>1124601</td>
                        <td>Pcs</td>
                        <td>270</td>
                        <td>270</td>
                        <td>72</td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>Buy 1 Immune 20X Tablet & Get 1 Aloe Digest</td>
                        <td>1124601</td>
                        <td>Pcs</td>
                        <td>270</td>
                        <td>270</td>
                        <td>72</td>
                        </tr>
                        <tr>
                        <td>#1</td>
                        <td>Buy 1 Immune 20X Tablet & Get 1 Aloe Digest</td>
                        <td>1124601</td>
                        <td>Pcs</td>
                        <td>270</td>
                        <td>270</td>
                        <td>72</td>
                        </tr>
                     </tbody>
                     </table>
                    </AccordionItem>
                    <AccordionItem header="Skin Care (5)">
           
                    </AccordionItem>
                    </Accordion>
           
                    </TabPanel>
                    <TabPanel>
                    <h2>Any content 2</h2>
                    </TabPanel>
                    <TabPanel>
                    <h2>Any content 3</h2>
                    </TabPanel>
               
                    </div>
             
                  </Tabs>
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default ProductList;