import './Menu.css';
import logo from '../../assets/img/logo.png'
import React, {useEffect, useState, CSSProperties, useRef} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';

const Menu = () =>{
    
   const [indexHum, setIndexNum] = useState(0);
   const [indexNum, setIndexNumsub] = useState(0)
   const dispatch = useDispatch();

   const { API_URL, userData} = useSelector(state => state.common);
   const [menu, setUserMenu] = useState([]);
   
    const menu_franchise_state =[
        {
            name:"Home",
            url:"/franchise/home",
            icon:"fa fa-home",
            all_menu: ['dashboard', 'kycocuments', 'bankdocuments'],
            dropdown:[
                {
                    name:"Dashboard",
                    url:"/franchise/dashboard",   
                },
                {
                    name:"KYC Documents",
                    url:"/franchise/kycocuments",   
                },
                {
                    name:"Bank Documents",
                    url:"/franchise/bankdocuments",   
                },
               
            ]
        },
              
        {
            name:"Sale",
            url:"/franchise/sale",
            icon:"fa fa-archive",
            all_menu: ['sale-franchise', 'sale-associate'],
            dropdown:[
                {
                    name:"Sale Franchise",
                    url:"/franchise/sale-franchise",   
                },
                {
                    name:"Sale Associate",
                    url:"/franchise/sale-associate",   
                },
            ]
        },
        {
            name:"Income",
            url:"/franchise/income-associate",
            icon:"fa fa-money",
            all_menu: ['income-associate', 'income-franchise'],
            dropdown:[
                {
                    name:"Income Associate",
                    url:"/franchise/income-associate",   
                },
                {
                    name:"Income Franchise",
                    url:"/franchise/income-franchise",   
                },
            ]
        },
        {
            name:"My Purchase",
            url:"/franchise/mypurchase",
            icon:"fa fa-file-text",
            all_menu: ['mypurchase'],
        },
        {
            name:"Report",
            url:"/franchise/report",
            icon:"fa fa-file-text",
            all_menu: ['report'],
        },
        {
            name:"Account",
            url:"/franchise/account",
            icon:"fa fa-user",
            all_menu: ['profile', 'changepassword'],
            dropdown:[
                {
                    name:"Profile",
                    url:"/franchise/profile",   
                },
                {
                    name:"Change Password",
                    url:"/franchise/changepassword",   
                },
            ]
        },
        
        {
            name:"Support",
            url:"/franchise/support",
            icon:"fa fa-question-circle",
            all_menu: ['support'],
        }
    ]

    const menu_franchise_block =[
        {
            name:"Home",
            url:"/franchise/home",
            icon:"fa fa-home",
            all_menu: ['dashboard', 'kycocuments', 'bankdocuments'],
            dropdown:[
                {
                    name:"Dashboard",
                    url:"/franchise/dashboard",   
                },
                {
                    name:"KYC Documents",
                    url:"/franchise/kycocuments",   
                },
                {
                    name:"Bank Documents",
                    url:"/franchise/bankdocuments",   
                },
               
            ]
        },
              
        {
            name:"Sale",
            url:"/franchise/sale",
            icon:"fa fa-archive",
            all_menu: ['sale-associate'],
            dropdown:[
                {
                    name:"Sale Associate",
                    url:"/franchise/sale-associate",   
                },
            ]
        },
        {
            name:"Income",
            url:"/franchise/income-associate",
            icon:"fa fa-money",
            all_menu: ['income-associate'],
        },
        {
            name:"My Purchase",
            url:"/franchise/mypurchase",
            icon:"fa fa-file-text",
            all_menu: ['mypurchase'],
        },
        {
            name:"Report",
            url:"/franchise/report",
            icon:"fa fa-file-text",
            all_menu: ['report'],
        },
        {
            name:"Account",
            url:"/franchise/account",
            icon:"fa fa-user",
            all_menu: ['profile', 'changepassword'],
            dropdown:[
                {
                    name:"Profile",
                    url:"/franchise/profile",   
                },
                {
                    name:"Change Password",
                    url:"/franchise/changepassword",   
                },
            ]
        },
        
        {
            name:"Support",
            url:"/franchise/support",
            icon:"fa fa-question-circle",
            all_menu: ['support'],
        }
    ]

    const menu_ASSOCIATE =[
        {
            name:"Home",
            url:"/associate/dashboard",
            icon:"fa fa-home",
            all_menu: ['dashboard', 'kycocuments', 'bankdocuments', 'qualifications', 'notifications', 'mydocuments', 'productlist', 'storelocator', 'mypurchase', 'productoffers', 'register'],
            dropdown:[
                {
                    name:"Dashboard",
                    url:"/associate/dashboard",   
                },
                {
                    name:"Qualifications",
                    url:"/associate/qualifications",   
                },
                /*{
                    name:"Notifications",
                    url:"/associate/notifications",   
                },*/
                {
                    name:"KYC Documents",
                    url:"/associate/kycocuments",   
                },
                {
                    name:"Bank Documents",
                    url:"/associate/bankdocuments",   
                },
                {
                    name:"Id Card",
                    url:"/associate/idcard",   
                },
                /*{
                    name:"My Documents",
                    url:"/associate/mydocuments",   
                },
                {
                    name:"Product List",
                    url:"/associate/productlist",   
                },*/
                {
                    name:"Store Locator",
                    url:"/associate/storelocator",   
                },
                {
                    name:"My Purchase",
                    url:"/associate/mypurchase",   
                },
                /*{
                    name:"Order Booking",
                    url:"/associate/orderbooking",   
                },*/
                {
                    name:"Product Offers",
                    url:"/associate/productoffers",   
                },
                /*{
                    name:"Register",
                    url:"/associate/register",   
                },*/

            ]
        },
        {
            name:"Downline",
            url:"/associate/downline",
            icon:"fa fa-home",
            all_menu: ['mydownline', 'directdownline', 'downlinesearch', 'downlinesupport', 'myupline', 'mypcs', 'directreferrals'],
            dropdown:[
                {
                    name:"My Downline",
                    url:"/associate/mydownline",   
                },
                {
                    name:"Direct Downline",
                    url:"/associate/directdownline",   
                },
                {
                    name:"Downline Search",
                    url:"/associate/downlinesearch",   
                },
              
                /*{
                    name:"Downline Support",
                    url:"/associate/downlinesupport",   
                },*/
                {
                    name:"My Upline",
                    url:"/associate/myupline",   
                },
                /*{
                    name:"My PCs",
                    url:"/associate/mypcs",   
                },*/
                {
                    name:"Direct Referrals",
                    url:"/associate/directreferrals",   
                },
            ]
        },
        /*{
            name:"BV Transfer",
            url:"/associate/bv-transfer",
            icon:"fa fa-random",
            all_menu: ['bv-transfer', 'summary'],
            dropdown:[
                {
                    name:"Make Transfer",
                    url:"/associate/bv-transfer",   
                },
                {
                    name:"Summary",
                    url:"/associate/summary",   
                },
            ]
        },*/
        
        {
            name:"Income",
            url:"/associate/incentive",
            icon:"fa fa-money",
            all_menu: ['incentive', 'accountstatement'],
            dropdown:[
                {
                    name:"My Incentive",
                    url:"/associate/incentive",   
                },
                /*{
                    name:"Account Statement",
                    url:"/associate/accountstatement",   
                },*/
            ]
        },
        {
            name:"Wallet",
            url:"/associate/wallet",
            icon:"fa fa-google-wallet",
            all_menu: ['wallet', 'walletpayment'],
            dropdown:[
                {
                    name:"Wallet Withdraws",
                    url:"/associate/wallet",   
                },
                {
                    name:"Wallet Payment",
                    url:"/associate/walletpayment",   
                },
            ]
        },
        {
            name:"Report",
            url:"/associate/report",
            icon:"fa fa-file-text",
            all_menu: ['report'],
        },
        {
            name:"Account",
            url:"/associate/account",
            icon:"fa fa-user",
            all_menu: ['account', 'profile', 'changepassword'],
            dropdown:[
                /*{
                    name:"Welcome Letter",
                    url:"/associate/account",   
                },
                {
                    name:"ID Card",
                    url:"/associate/account",   
                },*/
                {
                    name:"Profile",
                    url:"/associate/profile",   
                },
                {
                    name:"Change Password",
                    url:"/associate/changepassword",   
                },
            ]
        },
        /*{
            name:"Respects",
            url:"/associate/respects",
            icon:"fa fa-handshake-o",
            all_menu: ['respects'],
        },*/
        {
            name:"Support",
            url:"/associate/support",
            icon:"fa fa-question-circle",
            all_menu: ['support'],
        }
    ]

    useEffect(() => {
        let path = window.location.pathname;
        let path_arr = path.split("/");
        let path_last_index = path_arr[path_arr.length - 1];

        if(userData != null){
            if(userData.role_name == 'FRANCISE'){
                if(userData.level == 1){
                    setUserMenu(menu_franchise_state);
                    menu_franchise_state.map((item, index) => {
                        if(item.all_menu.includes(path_last_index)){
                            setIndexNum(index);
                            setIndexNumsub(index)
                        }
                    })

                }else if(userData.level == 2){
                    setUserMenu(menu_franchise_block);
                    menu_franchise_block.map((item, index) => {
                        if(item.all_menu.includes(path_last_index)){
                            setIndexNum(index);
                            setIndexNumsub(index)
                        }
                    })
                }

            }else if(userData.role_name == 'CLIENT'){
                setUserMenu(menu_ASSOCIATE);
                menu_ASSOCIATE.map((item, index) => {
                    if(item.all_menu.includes(path_last_index)){
                        setIndexNum(index);
                        setIndexNumsub(index)
                    }
                })
            }
        }
        
    }, [userData]);
 
     
    return(
     <div className="menuCom">
         <nav>
            <div className='logo'> 
                <Link><img src={logo}/></Link>
            </div>
            <ul className='menuUl'>
                {
                    menu.map((item, index)=>{
                    return (

                        item.dropdown == undefined ?     
                            <li id="main_li" onClick={()=>setIndexNum(index)} key={index} ><Link  className={indexHum == index? "active":""} to={item.url}><i className={item.icon} aria-hidden="true"></i>{item.name}</Link></li>
                            :
                            <li  key={index} className={indexHum == index? "active":""}><a onClick={()=>setIndexNum(index)}><i className={item.icon} aria-hidden="true"></i>{item.name}</a>
                                <ul id="submenu" className={indexHum === index? "dropdown active":" dropdown "}>
                                {
                                    item.dropdown.map((item, index)=>{
                                        return (
                                                <li onClick={()=>setIndexNumsub(index)} key={index}>
                                                    <NavLink
                                                            to={item.url}
                                                            className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                            }
                                                        >{item.name}</NavLink>
                                                </li>
                                            )
                                    })
                                }
                                </ul>
                            </li>
                        )    
                    })
                }
            </ul>
             <p className='copy'>Veda Harbal Copyright © 2024, Version 0.0.1</p>
         </nav>
     </div>
    )
 }
 export default Menu;