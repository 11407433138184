import {Outlet
} from "react-router-dom";
import './Layout.css';
import Menu from "../menu/Menu";
import Header from "../header/Header";
const Layout = () =>{
    return(
     <div className="mainwapper">
        <div className="menu">
           <Menu/>
        </div>
        <div className="wapper">
        <div className="header">
        <Header/>
        </div>
        <div className="heawapper-body">
            <Outlet/>
        </div>
        </div>
     </div>
    )
 }
 export default Layout;