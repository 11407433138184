import './Signup.css';
import logo from '../assets/img/logo.png';
import mail from '../assets/img/mail.png';
import lock from '../assets/img/lock.png';
import user from '../assets/img/users.png';
import call from '../assets/img/call.png';

import React, {useEffect, useState, CSSProperties, useRef} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const FranchiseCheckCode = () =>{
    const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [code, setCode] = useState("");
 

    //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setCode("");
 
  }

  const CodeAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if (code.search(/\S/) == -1) {
      all_error_msg.push("Enter your sign up code");

      if (flg === true) {
        flg = false;
      }
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'francise/check-francise-code', {francise_code: code}).then((result) => {
          if(result.status === true){
                setLoading(false);
                navigate("/franchise/signup", {state: {code: code}})
            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

  


 return(
    <div className="authapper">
    <div className="authapper-body">
    <CustomAlert
              header={"Signup"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
              


    <div className="authapper-left">
        <div className='logo'>
            <img src={logo}/>
        </div>
        <p>Lorem Ipsum is simply dummy text of the printing and 
        typesetting industry. Lorem Ipsum has been the industry's 
        standard dummy text ever since the 1500s</p>
        <p><a href='mailto:info@yoursitename.com'><i className="fa fa-envelope" aria-hidden="true"></i> info@yoursitename.com</a></p>
        <p><a href='tel:+00 00000000'><i className="fa fa-phone" aria-hidden="true"></i> +00 00000000</a></p>
        <span className='copyright'>Veda Harbal Copyright © 2024, Version 0.0.1</span>
     </div>
     <div className="authapper-right">
         <h2>Enter Your Sign Up Code</h2>
         <form onSubmit={CodeAction}>
            <div className='form-group'>
               <img src={lock}/>
                <input type='text' placeholder='Enter Your Code' onChange={(event) => setCode(event.target.value)}/>
            </div>
           
            <button type='submit' className='themeBtn'>Submit</button>
            <div className='formbtm'>
             {/*<p>Direct Seller Login |Preferred Customer Login </p>*/}
             <p>Already have an account ? <Link to={'/'}>LOGIN</Link></p>
            </div>
         </form>
     </div>
     </div>
    

    </div>

 )
}
export default FranchiseCheckCode;