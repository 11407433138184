import   './Summary.css';
const Summary = () =>{
    return(
     <div className='summary'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                 <p className='noresult'>Sorry Now result Found</p>
                  
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default Summary;