import   './Sale.css';
import product from '../../../../assets/img/product.png'
import invoice from '../../../../assets/img/invoice.png'
import logo from '../../../../assets/img/logo.png'
import bin from '../../../../assets/img/delete.svg';
import invoice_logo from '../../../../assets/img/pdf_logo.png';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import Modal from 'react-modal';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'

const Sale_Franchise = () =>{

    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;

    const dispatch = useDispatch();

    const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [product, setProduct] = useState({isLoading: true, list: []});
    const [associate_user, setAssociateUser] = useState({isLoading: true, list: []});

    const [associate_id,setAssociateId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [static_selectedProduct, setStaticSelectedProduct] = useState([{product_id: '', quantity: '', price: ''}]);
    const [new_selectedProduct, setNewSelectedProduct] = useState([]);

    const [view_invoice, setViewInvoice] = useState(false);
    const [invoice_info, setInvoiceInfo] = useState(null);

    let total_cgst_amount = 0;
    let total_sgst_amount = 0;
    let total_igst_amount = 0;
    let total_cgst = 0;
    let total_sgst = 0;
    let total_igst = 0;
    let total_rate = 0;
    let total_qty = 0;
    let total_unit = 0;
    let total_tbv = 0;

   
    const pagename = useCallback(() => {
        dispatch(setPageName('Sale'));
    },[pageName])

    useEffect(() => {
        pagename();
        fetchProduct();
        fetchAssociateUser();
    }, []);

    const updateAssociateId = (id) => {
        setAssociateId(id);
        associate_user.list.find((x, index) => { 
            if(x.value == id){
                setName(x.name);
                setEmail(x.email);
                setPhone(x.phone);
            }
         })
    }

     //alert start
  
     const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const fetchAssociateUser = () => {
        postWithToken(API_URL, 'francise/block-francise-list', {}, userToken).then((result) => {
            console.log(result);
            if(result.status === true){
                setAssociateUser({
                 isLoading: false,
                 list: result.data
             })
             }else{
                 setLoading(false);
             }
         })
         .catch((error) => {
             console.log("Error : ", error);
         });
    }

    const fetchProduct = () => {
      
        postWithToken(API_URL, 'francise/products-list', {}, userToken).then((result) => {
           // console.log(result.data);
           if(result.status === true){
            setProduct({
                isLoading: false,
                list: result.data
            })
            }else{
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const AddAnyOther = () => {
        if(new_selectedProduct.length > 0){
            let arr = {product_id: '', quantity: '', price: ''};
            setNewSelectedProduct([...new_selectedProduct,arr]);
        }else{
            setNewSelectedProduct([{product_id: '', quantity: '', price: ''}]);
        }
     }

     const chageStaticValue = (field_type, index, val, id) => {
        if(field_type == "id"){
            static_selectedProduct[index].product_id = val;
            setLoading(true);
            postWithToken(API_URL, 'francise/get-products-commission', {user_id: associate_id, product_id: val}, userToken).then((result) => {
                console.log(result);
                setLoading(false);
                if(result.status === true){
                    static_selectedProduct[index].price = result.product_price;
                    $('#' + id).val(result.product_price);

                 }else{
                    
                 }
             })
             .catch((error) => {
                 console.log("Error : ", error);
             });


        }else if(field_type == "qty"){
            static_selectedProduct[index].quantity = val;
            $('#' + id).val(val);
        }else if(field_type == "price"){
            static_selectedProduct[index].price = val;
        }
        
      }
 
     const chageNewValue = (field_type, index, val, id) => {
       if(field_type == "id"){
        new_selectedProduct[index].product_id = val;

            setLoading(true);
            postWithToken(API_URL, 'francise/get-products-commission', {user_id: associate_id, product_id: val}, userToken).then((result) => {
                console.log(result);
                setLoading(false);
                if(result.status === true){
                    new_selectedProduct[index].price = result.product_price;
                    $('#' + id).val(result.product_price);

                 }else{
                    
                 }
             })
             .catch((error) => {
                 console.log("Error : ", error);
             });
 
       }else if(field_type == "qty"){
        new_selectedProduct[index].quantity = val;
        $('#' + id).val(val);
       }else if(field_type == "price"){
        new_selectedProduct[index].price = val;
        $('#' + id).val(val);
       }
      
       
     }
 
     const DeleteNewRow = (index_delete) => {
       const arr = new_selectedProduct;
       const newArray = new_selectedProduct.filter((item, index) => index !== index_delete);
       setNewSelectedProduct(newArray);
     }

     const resetForm = () => {
        setStaticSelectedProduct([]);
        setAssociateId("");
        setName("");
        setEmail("");
        setPhone("");
        setTimeout(() => {
            setTimeout(setStaticSelectedProduct([{product_id: '', quantity: ''}]), 1000); 
        }, 1000);	
        
        setNewSelectedProduct([]);
     }

     const SellProduct = () => {
        let flg = true;
        const all_error_msg = [];
    
        if (associate_id == "") {
          all_error_msg.push("Please Select Associate");
    
          if (flg === true) {
            flg = false;
          }
        }
    
        if(static_selectedProduct.length > 0){
            console.log(static_selectedProduct);
            static_selectedProduct.map((item, index) => {
                if(flg === true){
                    
                    if(item.product_id == ''){
                        all_error_msg.push("Please select product");
                        if(flg == true){flg = false;}
                    }

                    if(item.quantity.search(/\S/) == -1){
                        all_error_msg.push("Please enter product quantity");
                        if(flg == true){flg = false;}
                    }

                   /* if(item.price.search(/\S/) == -1){
                        all_error_msg.push("price can not be empty");
                        if(flg == true){flg = false;}
                    }*/
                }    
            })
            if (flg === false) {
                onShowAlert("error", all_error_msg);
            }
          }

          if(new_selectedProduct.length > 0){
            new_selectedProduct.map((item, index) => {
                if(flg === true){
                    if(item.product_id == ''){
                        all_error_msg.push("Please select product");
                        if(flg == true){flg = false;}
                    }

                    if(item.quantity.search(/\S/) == -1){
                        all_error_msg.push("Please enter product quantity");
                        if(flg == true){flg = false;}
                    }

                    /*if(item.price.search(/\S/) == -1){
                        all_error_msg.push("price can not be empty");
                        if(flg == true){flg = false;}
                    }*/
                }   
            })
            if (flg === false) {
                onShowAlert("error", all_error_msg);
            }

          }
              
    
        if (flg === true) {

            
            total_cgst_amount = 0;
            total_sgst_amount = 0;
            total_igst_amount = 0;
            total_cgst = 0;
            total_sgst = 0;
            total_igst = 0;
            total_rate = 0;
            total_qty = 0;
            total_unit = 0;
            total_tbv = 0;

            let concate_product = static_selectedProduct.concat(new_selectedProduct);

           /*console.log(concate_product);
            console.log(associate_id);

            resetForm()

            return false;*/

          setLoading(true);
          postWithToken(API_URL, 'francise/block-francise-product-selling', {user_id: associate_id, products: concate_product}, userToken).then((result) => {
            console.log(result.data);
              if(result.status === true){
                    setLoading(false);
                    resetForm();
                    setInvoiceInfo(result.data);
                    setViewInvoice(true);
                    fetchProduct();
                   
                }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
                  setLoading(false);
                }
            })
            .catch((error) => {
              console.log("Error : ", error);
            });
          }else{
            onShowAlert("error", all_error_msg);
            
          } 
     }


     const mainbox = {
        border: "1px solid #ccc",
        boxSizing: "border-box"
     };
     const topAria = {
        padding: "15px",
        boxSizing: "border-box",
        position: "relative"
     }
     const hdn={
       margin: "0px",
       textTransform: "uppercase" ,
       fontSize: "20px",
       color: "#000",
       textAlign: "center",
       marginBottom:" 10px"
     }
     const pra ={
       margin: "0px",
       textTransform: "uppercase",
       fontSize: "16px",
       color: "#888",
       textAlign: "center"
     }
     const tabl ={
       width: "100%",
       borderTop: "1px solid #ccc", 
       borderBottom: "1px solid #ccc", 
       borderSpacing: "0px"
     }
     const tab2 ={
       width: "100%",
       borderBottom: "1px solid #ccc", 
       borderSpacing: "0px"
     }
     const tbtd ={
       verticalAlign: "top",
       width: "50%",
       padding: "15px",
       borderRight: "1px solid #ccc"
     }
     const tbtd2 ={
       verticalAlign: "top",
       width: "50%",
       padding: "15px"
   
     }
     const tdh3 ={
       fontSize: "18px",
       color: "#000",
       textTransform: "uppercase",
       margin: "0px",
       marginBottom: "10px",
     }
     const tdp = {
       margin: "0px",
       marginBottom: "10px", 
       fontSize: "14px",
       color: "#000"
     }
     const th3 = {
       margin: "0px",
       fontSize: "18px",
       color: "#000" ,
       textTransform: "uppercase",
       marginBottom: "10px"
     }
     const th4 ={
       fontSize: "20px", 
       margin: "0px",
       marginBottom: "10px"
     }
     const tdspn ={
       display: "inline-block"
     }
     const tabl3 ={
       width: "100%",
       borderSpacing: "0px"
     }
     const tabl3th ={
       borderRight: "1px solid #ccc",
       fontSize: "14px",
       color: "#000",
       padding: "10px",
     }
     const tabl3th1 ={
       borderRight: "1px solid #ccc", 
       fontSize: "14px",
       color: "#000",
       padding: "10px",
       borderBottom: "1px solid #ccc"
     }
     const tbl3td ={
       borderRight: "1px solid #ccc",
       fontSize: "14px",
       color: "#000",
       padding: "10px",
       borderBottom: "1px solid #ccc",
       textAlign: "center"
     }
     const tbl3td1 ={
       borderRight: "1px solid #ccc",
       fontSize: "14px", 
       color:"#000", 
       padding: "10px",
       borderBottom: "1px solid #ccc",
       textAlign: "left"
     }
     const tbl3td2 ={
       borderRight: "1px solid #ccc",
       fontSize: "14px", 
       color: "#000",
       padding: "10px",
       borderBottom: "1px solid #ccc", 
       textAlign: "right"
     }
     const tbl3td3={
       borderRight: "1px solid #ccc",
       fontSize: "14px",
       color: "#000",
       padding: "10px",
       borderBottom: "1px solid #ccc",
       textAlign: "center"
     }
     const tab4 ={
       width: "100%",
       boxSizing: "border-box",
       borderSpacing: "0px"
     }
     const tbl4td ={
       width: "50%",
       padding: "10px",
       verticalAlign: "top",
     }
     const tbl5={
       width: "100%",
       borderSpacing: "0px", 
       border: "1px solid #ccc", 
       borderBottom: "0px"
     }
     const tbl5td ={
       borderRight: "1px solid #ccc",
       fontSize: "14px", 
       color: "#000",
       padding: "10px",
       borderBottom: "1px solid #ccc"
     }
     const tbl5thlst = {
       fontSize: "14px",
       color: "#000",
       padding: "10px",
       borderBottom: "1px solid #ccc"
     }
     const tbl5lasttd ={
       fontSize: "14px" ,
       color: "#000", 
       padding: "10px",
       borderBottom: "1px solid #ccc",
       textAlign: "center"
     }
     const tbl4td1 ={
       width: "50%", 
       padding: "10px", 
       verticalAlign: "top", 
       textAlign: "right"
     }
     const tbl4td16 = {
       width: "60%", 
       display: "inline-block"
     }
     const tbl7 ={
       width: "100%",
       borderSpacing:"0px", 
       boxSizing: "border-box",
       border: "1px solid #ccc", 
       textAlign: "left"
     }
     const tbl7td1 ={
       borderRight: "1px solid #ccc", 
       fontSize: "14px",
       color: "#000",
       padding: "10px",
       borderBottom: "1px solid #ccc"
     }
     const tbl7td2 ={
       fontSize: "14px", 
       color: "#000", 
       padding: "10px",
       borderBottom: "1px solid #ccc"
     }
     const tbl7td3 ={
       borderRight: "1px solid #ccc", 
       fontSize: "14px",
       color: "#000",
       padding: "10px"
   }
   const tbl7td4 ={
       fontSize:"14px",
       color: "#000",
       padding: "10px"
   }
   const tbl8 ={   width: "100%",
       borderSpacing: "0px",
       boxSizing: "border-box", 
       borderTop: "1px solid #ccc"
   }
const tbl8td ={
   width: "60%",
   verticalAlign: "top",
   padding: "10px"
}
const tbl8h3 ={
   margin: "0px",
   marginBottom: "10px",
   fontSize: "20px",
   color:"#000"
}
const tbl8p ={
   margin: "0px",
   marginBottom: "10px", 
   fontSize: "14px",
   color: "#333"
}
const tbl8ltd ={
   width: "40%",
   textAlign: "right",
   verticalAlign: "top",
   padding: "10px"
}
const tbl8h4 ={
   fontSize: "30px", 
   color: "#000", 
   textTransform: "uppercase",
   margin: "0px"
}
const tbl8tl2={
   padding: "10px",
   borderBottom: "1px solid #ccc",  
   borderTop: "1px solid #ccc"
}
const tbl8l2p ={
   margin: "0px",
   fontSize: "14px", 
   color: "#333",
   textAlign: "center"
}
const tbl8tl4 ={
   margin: "0px", 
   fontSize: "14px",
   color: "#333", 
   textAlign: "center"
}
const tbpdn = {
   padding: '10px'
}



    return(
     <div className='product'>
         <CustomAlert
              header={"Sale Product"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>

        {
                view_invoice === true ?

                    <div  className='col-md-12'>
                            <div className='card'>
                                <div className='row'>

                                <div style={{marginBottom: 20, paddingLeft: 20}}>
                                        <button type='button' className='uploadBtn' onClick={() => {setViewInvoice(false); setInvoiceInfo([]);}} >Back To Sell</button>
                                        {
                                            invoice_info != null ?
                                            <a className='uploadBtn' href={SITE_URL + 'fancise-invoice-generate/' + invoice_info.order.custom_order_id} download style={{color: '#fff', textDecoration: 'none', marginLeft: 10}}>Download Invoice</a>
                                            : null
                                        }
                                    </div>

                                        
                                <div style={mainbox}>
                                    <div style={topAria}>
                                        <h2 style={hdn}>Tax Invoice</h2>
                                    <p style={pra}>(Original Form Buyer i Duplicat For Seller)</p>
                                    </div>
                                    <table style={tabl}>
                                        <tr>
                                            <td style={tbtd}>
                                            <h3 style={tdh3}>Veda Natural Herbal</h3>
                                            <p style={tdp}><b style={tdspn}>Register Office :</b>{invoice_info.setting.address} {invoice_info.setting.address_2} {invoice_info.setting.address_3}</p>
                                            <p style={tdp}><b style={tdspn}>Mobile :</b>{invoice_info.setting.phone}</p>
                                            <p style={tdp}><b style={tdspn}>Email :</b>{invoice_info.setting.email}</p>
                                            <p style={tdp}><b style={tdspn}>CIN No :</b>{invoice_info.setting.cin_no}</p>
                                            <p style={tdp}><b style={tdspn}>PAN No :</b>{invoice_info.setting.pan_no}</p>
                                            <p style={tdp}><b style={tdspn}>GSTIN :</b>{invoice_info.setting.gst_no}</p>
                                            <p style={tdp}><b style={tdspn}>Fssai :</b>{invoice_info.setting.fassai_no}</p>
                                            </td>
                                            <td style={tbtd2}>
                                            <p style={tdp}><b style={tdspn}>Invoice No :</b>{invoice_info != null ? invoice_info.order.invoice_no : null}</p>
                                            <p style={tdp}><b style={tdspn}>Invoice Date :</b>{invoice_info != null ? Moment(invoice_info.order.date).format('DD/MM/YYYY'): null}</p>
                                            <p style={tdp}><b style={tdspn}>Place of Supply :</b>{invoice_info.order.address} {invoice_info.order.address_1} {invoice_info.order.address_2}</p>
                                            </td>
                                        </tr>
                                    </table>
                                    <table style={tab2}>
                                        <tr>
                                            <td style={tbtd}>
                                            <h3 style={th3}>Bill To</h3>
                                            <p style={tdp}><b style={tdspn}>Associate Code :</b> {invoice_info.order.user.user_referral_code}</p>
                                            <h4 style={th4}>{invoice_info.order.customer_name}</h4>
                                            <p style={tdp}>{invoice_info.order.address} {invoice_info.order.address_1} {invoice_info.order.address_2} {invoice_info.order.city}
                                            {invoice_info.order.pin_code}</p>
                                            <p style={tdp}><b style={tdspn}>Email :</b> {invoice_info.order.customer_email}</p>
                                            <p style={tdp}><b style={tdspn}>Phone :</b> {invoice_info.order.customer_phone}</p>
                                            <p style={tdp}><b style={tdspn}>GSTN :</b> {invoice_info.order.user.gst_no}</p>
                                            {/*<p style={tdp}><b style={tdspn}>PAN :</b> </p>*/}

                                            </td>
                                            <td style={tbtd2}>
                                                <h3 style={th3}>Ship To</h3>
                                                <p style={tdp}><b style={tdspn}>Associate Code :</b> {invoice_info.order.user.user_referral_code}</p>
                                                <h4 style={th4}>{invoice_info.order.customer_name}</h4>
                                                <p style={tdp}>{invoice_info.order.address} {invoice_info.order.address_1} {invoice_info.order.address_2}
                                                {invoice_info.order.city}
                                                {invoice_info.order.pin_code}</p>
                                                <p style={tdp}><b style={tdspn}>Email :</b> {invoice_info.order.customer_email}</p>
                                                <p style={tdp}><b style={tdspn}>Phone :</b> {invoice_info.order.customer_phone}</p>
                                                <p style={tdp}><b style={tdspn}>GSTN :</b> {invoice_info.order.user.gst_no}</p>
                                                {/*<p style={tdp}><b style={tdspn}>PAN :</b> JHGHG44444</p>*/ }
                                
                                            </td>
                                        </tr>
                                    </table>
                                    <table style={tabl3}>
                                        <thead>
                                            <tr>
                                                <th style={tabl3th}>S.No</th>
                                                <th style={tabl3th}>Code</th>
                                                <th style={tabl3th}>Item Description</th>
                                                <th style={tabl3th}>HSN</th>
                                                <th style={tabl3th}>Unit</th>
                                                <th style={tabl3th}>Rate</th>
                                                <th style={tabl3th}>Qty</th>
                                                <th style={tabl3th}>TBV</th>
                                                <th style={tabl3th}>Taxable Amt</th>
                                                <th colspan="2" style={tabl3th1}>CGST</th>
                                                <th colspan="2" style={tabl3th1}>SGST</th>
                                                <th colspan="2" style={tabl3th1}>IGST</th>
                                                <th>Amount</th>
                                            </tr>
                                            <tr>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}></th>
                                                <th style={tabl3th1}>%</th>
                                                <th style={tabl3th1}>Amount</th>
                                                <th style={tabl3th1}>%</th>
                                                <th style={tabl3th1}>Amount</th>
                                                <th style={tabl3th1}>%</th>
                                                <th style={tabl3th1}>Amount</th>
                                                <th style={tabl3th1}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {

                                            invoice_info.order.order_details.map((item, index) => {

                                                total_cgst_amount += item.cgst_amount;
                                                total_sgst_amount += item.sgst_amount;
                                                total_igst_amount += item.igst_amount;
                                                total_cgst += item.cgst;
                                                total_sgst += item.sgst;
                                                total_igst += item.igst;
                                               
                                                total_rate += item.price;
                                                total_qty += item.quantity;
                                                total_unit += item.unit;
                                                total_tbv += item.tbv;

                                                return(
                                                   
                                                        <tr key={'item_' + index}>
                                                            <td style={tbl3td}>{ index + 1}</td>
                                                            <td style={tbl3td}>{item.product_code}</td>
                                                            <td style={tbl3td}>{item.product_name}</td>
                                                            <td style={tbl3td}>{item.hsn_no}</td>
                                                            <td style={tbl3td}>{item.unit}</td>
                                                            <td style={tbl3td}>{item.price}</td>
                                                            <td style={tbl3td}>{item.quantity}</td>
                                                            <td style={tbl3td}>{item.tbv}</td>
                                                            <td style={tbl3td}>{item.sub_total}</td>
                                                            <td style={tbl3td}>{item.cgst}</td>
                                                            <td style={tbl3td}>{item.cgst_amount}</td>
                                                            <td style={tbl3td}>{item.sgst}</td>
                                                            <td style={tbl3td}>{item.sgst_amount}</td>
                                                            <td style={tbl3td}>{item.igst}</td>
                                                            <td style={tbl3td}>{item.igst_amount}</td>
                                                            <td style={tbl3td}>{item.total}</td>
                                                        </tr>
                                                       
                                                )
                                            })}

                                            <tr>
                                                <td colspan="5" style={tbl3td2}>Total</td>
                                                <td style={tbl3td3}>{total_rate}</td>
                                                <td style={tbl3td3}>{total_qty}</td>
                                                <td style={tbl3td3}>{total_tbv}</td>
                                                <td style={tbl3td3}>{invoice_info.order.sub_total}</td>
                                                <td style={tbl3td3}></td>
                                                <td style={tbl3td3}>{total_cgst_amount}</td>
                                                <td style={tbl3td3}></td>
                                                <td style={tbl3td3}>{total_sgst_amount}</td>
                                                <td style={tbl3td3}></td>
                                                <td style={tbl3td3}>{total_igst_amount}</td>
                                                <td style={tbl3td3}>{invoice_info.order.total}</td>

                                            </tr>      
                                        </tbody>
                                    </table>
                                    <table style={tab4}>
                                        <tr>
                                            <td style={tbl4td}>
                                                <table style={tbl5}>
                                                    <thead>
                                                        <tr>
                                                            <th style={tbl5td}>
                                                                Sl.no
                                                            </th>
                                                           
                                                            <th  style={tbl5td}>
                                                                Taxable Amount
                                                            </th>
                                                            <th  style={tbl5td}>
                                                                CGST
                                                            </th>
                                                            <th  style={tbl5td}>
                                                                SGST
                                                            </th>
                                                            <th  style={tbl5td}>
                                                                IGST
                                                            </th>
                                                            <th  style={tbl5thlst}>
                                                                Total Amount
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={tbl3td3}>01</td>
                                                            <td style={tbl3td3}>{invoice_info.order.sub_total}</td>
                                                            <td style={tbl3td3}>{total_cgst_amount}</td>
                                                            <td style={tbl3td3}>{total_sgst_amount}</td>
                                                            <td style={tbl3td3}>{total_igst_amount}</td>
                                                            <td style={tbl5lasttd}>{invoice_info.order.total}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={tbl4td1}>
                                                <div style={tbl4td16}>
                                                    <table style={tbl7}>
                                                        <tr>
                                                            <td style={tbl7td1}>Total Taxable Value</td>
                                                            <td style={tbl7td2}>{invoice_info.order.sub_total}</td>
                                                        </tr>
                                                        {/*<tr>
                                                            <td style={tbl7td1}>Total Tax Value</td>
                                                            <td style={tbl7td2}>29.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tbl7td1}>Round Off Value</td>
                                                            <td style={tbl7td2}>0.00</td>
                                                        </tr>*/}
                                                        <tr>
                                                            <td style={tbl7td3}>Total Invoice Value</td>
                                                            <td style={tbl7td4}>{invoice_info.order.total}</td>
                                                        </tr>
                                                        </table>
                                                </div>
                                        
                                            
                                            </td>
                                        </tr>
                                    </table>
                                    <table style={tbl8}>
                                    <tr>
                                        <td style={tbl8td}>
                                            <h3 style={tbl8h3}>Terms and Conditions</h3>
                                            <p style={tbl8p}>1. Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                            <p style={tbl8p}>2. Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                            <p style={tbl8p}>3. Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                        </td>
                                        <td style={tbl8ltd}>
                                        <h4 style={tbl8h4}>For Veda Natural Herbal</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style={tbl8tl2}>
                                            <p style={tbl8l2p}> 
                                                
                                                This Is computer Genarated invoice no stamp & signature required 
                                        </p>

                                        </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style={tbpdn}>
                                                <p style={tbl8tl4}>
                                                <b>Customer Care (For Store): +{invoice_info.setting.phone}</b>
                                            </p>
                                    
                                            </td>
                                    </tr>
                                    </table>
                                </div>





                                </div>
                            </div>
                    </div>  
                    
                :
                
                
            <div  className='col-md-12'>
            <div className='card'>
                <div className='row'>
                <div  className='col-md-5'>
                  {
                    product.isLoading == false && product.list.length > 0 ?

                        <div className='productlist'>
                   
                            { product.list.map((item, index) => {

                                return(
                                    <div key={'product_' + index} className='productlistitem'>
                                        <div className='productlistitem-img'>
                                        <img src={item.image_path}/>
                                        </div>
                                        <div className='productlistitem-dtls'>
                                            <h3>{item.name}</h3>
                                            <p>Product Code: {item.product_code}</p>
                                            <p>Product Quantity: {item.quantity}</p>
                                            <b>{'\u20B9'} {item.price}</b>
                                        </div>
                                    </div>
                                )

                            }) }

                        </div>

                      : product.isLoading == false && product.list.length == 0 ?
                        <div className='no_record' >
                            <div>No Product Found</div>
                        </div>
                      :
                        <>
                            <div className=''>
                                <Skeleton height={50} />
                            </div>
                            <div className=''>
                                <Skeleton height={50} />
                            </div>
                            <div className=''>
                                <Skeleton height={50} />
                            </div>
                            <div className=''>
                                <Skeleton height={50} />
                            </div>
                        </>  
                  }
                  
             </div>
                <div  className='col-md-7'>                
                
                            <div className='saleForm'>
                                    <h3>Sell Product</h3>
                                    <div className='row'>
                                        <div className='col-md-12 search_select'>
                                    
                                            {/*<div className='form-group'>
                                                <select>
                                                    <option>
                                                    Select Associate ID
                                                    </option>
                                                </select>
                                            
                                            </div>*/}
                                        <SelectSearch options={associate_user.list} value={associate_id} placeholder="Search Franchise" search onChange={(values) => updateAssociateId(values)}/>

                                        </div>
                                    
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                <input type='text' placeholder='Name' value={name} disabled style={{background: '#ccc'}}/>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                <input type='text' placeholder='Email' value={email} disabled style={{background: '#ccc'}}/>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='form-group'>
                                                <input type='text' placeholder='Phone Number' value={phone} disabled style={{background: '#ccc'}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'> 
                                        { associate_id != "" ?
                                        <> 
                                        {
                                            static_selectedProduct.map((item, index) => {
                                                return(
                                                    <div className='col-md-12' key={'static_' + index}>
                                                        <div className="row">
                                                            <div className='col-md-6 search_select'>
                                                                {/*<div className='form-group product_select' >
                                                                    <select id={'static_product_' + index} onChange={(event) => {chageStaticValue('id', index, event.target.value, 'static_product_' + index)}}>
                                                                    <option key={'s_'} value="">Select Product</option>
                                                                        {
                                                                            product.list.map((item, index) => {

                                                                                return(
                                                                                    <option key={'s_' + index} value={item.product_id}>{item.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                        
                                                                        
                                                                    </select>
                                                                    </div>*/}
                                                                
                                
                                                                    <SelectSearch options={product.list} placeholder="Search Product" search onChange={(values) => chageStaticValue('id', index, values, 'static_price_' + index)}/>

                            
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <div className='form-group'>
                                                                    <input type='text' placeholder='Quantity' id={'static_qty_' + index} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                                return true;
                                        }else{event.preventDefault();}}} onChange={(event) => {chageStaticValue('qty', index, event.target.value, 'static_qty_' + index)}}/>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <div className='form-group'>
                                                                    <input type='text' placeholder='Price' id={'static_price_' + index} disabled />
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>
                                                )
                                            })

                                        }
                                        {
                                        
                                            new_selectedProduct.map((item, index) => {
                                                return(
                                                    <div className='col-md-12' id={'new_' + index} key={'new_' + index}>
                                                        <div className="row">
                                                            <div className='col-md-6 search_select'>
                                                                {/*<div className='form-group product_select' >
                                                                    <select id={'new_product_' + index} onChange={(event) => {chageNewValue('id', index, event.target.value, 'new_product_' + index)}}>
                                                                    <option key={'new_'} value="">Select Product</option>
                                                                        {
                                                                            product.list.map((item, index) => {

                                                                                return(
                                                                                    <option key={'new_' + index} value={item.product_id}>{item.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    </div>*/}

                                                                <SelectSearch options={product.list} placeholder="Search Product" search onChange={(values) => chageNewValue('id', index, values, 'new_price_' + index)}/>

                                                            </div>
                                                            <div className='col-md-3'>
                                                                <div className='form-group'>
                                                                    <input type='text' placeholder='Quantity' onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                                return true;
                                        }else{event.preventDefault();}}} id={'new_qty_' + index} onChange={(event) => {chageNewValue('qty', index, event.target.value, 'new_qty_' + index)}}/>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <div className='form-group'>
                                                                    <input type='text' placeholder='Price' id={'new_price_' + index} disabled />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-1'>
                                                                <button style={{ marginLeft: 6, height: 45, width: 45}} onClick={() => DeleteNewRow(index)}><img src={bin}/></button>
                                                            </div>
                                                        </div>   
                                                    </div>
                                                );
                                            })
                                        
                                        }

                                        <div className='col-md-12'>
                                            <button type="button" className="addProduct_more" onClick={() => {AddAnyOther()}}>+ Add More Product</button>
                                        </div>
                                    
                                        <button type='button' className='uploadBtn' onClick={() => {SellProduct()}} >Sell Now</button>
                                    </>

                                    : null}

                                    </div>
                                
                                </div>
                    </div>
                </div>
            
                
                </div>
            </div>
                    
        }          


        </div>
     </div>
    )
 }
 export default Sale_Franchise;