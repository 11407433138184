import './Header.css';
import user from "../../assets/img/profile.png";
import togle from '../../assets/img/toggle.png';
import { Component, useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useNavigate} from "react-router-dom";
import { setUserDataAfterLogin, clearUserData } from "../../Store/Reducers/CommonReducer";
import {postWithToken} from "../../Service/service";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
const Header = () =>{
   Moment.locale('en');
   const [navName, setnavName] = useState(window.location.pathname)
   const navigate = useNavigate();
   const dispatch = useDispatch();
   let [loading, setLoading] = useState(false);
   const { API_URL, userData, userToken, pageName} = useSelector(state => state.common);
   const newRef = useRef();
   const [open, setOpen] = useState(false);

   let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;

   useEffect(() => {
      if(sessionStorage.getItem("userId") == null){
         navigate("/login");
      }
      console.log(userToken);
      console.log(userData);
   },[userData]);

   const  goToLogout = () => {
     
      postWithToken(API_URL, 'logged-out', {}, userToken).then((result) => {
         if(result.status === true){
                          
              sessionStorage.removeItem("userData");
              sessionStorage.removeItem("userId");
              dispatch(clearUserData());

              navigate("/login");
          }else{
              setLoading(false);
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });

      
  }

    return(
     <div className="headerCom">
      {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='headerLeft'>
        <span><img src={togle}/></span><h1>{pageName != null ? pageName :  'Dashboard'}</h1>
        </div>
       <div className='headerRight'>
          <p>Your Last Login at  <a href="javascript:void(0)"> {userData.last_logged_in != null ?  Moment(userData.last_logged_in).format('DD/MM/YYYY') + ' - ' + Moment(userData.last_logged_in).format('hh:mm:ss A') : null}</a></p>
          <div className='user'>
            <div className='userIcon'>
            {
                  userData != null ?
                  
                  userData.profile_image_path != null ?
                  <img src={userData.profile_image_path}/>
                  :
                  <img src={user}/>
                  :null

               }
            </div>
            <div className='userDetails'>
                 <label>{userData != null ? userData.first_name :  null}</label>

                 <Link className="action"  onClick={()=>{setOpen(!open)}}>
                     <i className="fa fa-angle-down" aria-hidden="true"></i>
                        <ul className={open? "open":""} ref={newRef}>
                        
                           <li>
                           <Link onClick={() => goToLogout()}><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</Link>
                           </li>
                        
                       </ul>
                    </Link>

            </div>
          </div>
       </div>
     </div>
    )
 }
 export default Header;