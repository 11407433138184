import './Signup.css';
import logo from '../assets/img/logo.png';
import mail from '../assets/img/mail.png';
import lock from '../assets/img/lock.png';
import user from '../assets/img/users.png';
import call from '../assets/img/call.png';

import React, {useEffect, useState, CSSProperties, useRef} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import OtpInput from 'react-otp-input';

const FranchiseSignup = () =>{
    const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();
  const location = useLocation();

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [username, setUserName] = useState("");
  const [owner_name, setOwnerName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [modalEmailOpen, setModelEmailOpen] = useState(false);
  const [email_otp, setEmailOtp] = useState('');
  const [register_id, setRegisterId] = useState("");
  const [email_otp_status, setEmailOTPStatus] = useState(false);

  const [minutes_email, setMinutesEmail] = useState(1);
  const [seconds_email, setSecondsEmail] = useState(30);

  useEffect(() => {
    fetchUserData();
	}, []);

  
  useEffect(() => {
    if(modalEmailOpen === true){
      const interval_email = setInterval(() => {
        if (seconds_email > 0) {
          setSecondsEmail(seconds_email - 1);
        }
      
        if (seconds_email === 0) {
        if (minutes_email === 0) {
          clearInterval(interval_email);
        } else {
          setSecondsEmail(59);
          setMinutesEmail(minutes_email - 1);
        }
        }
      }, 1000);

      return () => {
        clearInterval(interval_email);
      };

    }
    
	}, [seconds_email, modalEmailOpen]);

  const fetchUserData = () => {
    postWithOutToken(API_URL, 'francise/check-francise-code', {francise_code: location.state.code}).then((result) => {
      console.log(result);
      if(result.status === true){
         setUserName(result.data.name);
         setPhone(result.data.phone);
         setEmail(result.data.email);
        }else{
          console.log(result.message);
        }
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
  }

    function CloseEmailModel(){
        setModelEmailOpen(false);
        resetForm();
    }

    //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setUserName("");
    setOwnerName("");
    setPhone("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  
  }

  const SignInAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if (username.search(/\S/) == -1) {
      all_error_msg.push("Enter your name");

      if (flg === true) {
        flg = false;
      }
    }

    if (owner_name.search(/\S/) == -1) {
      all_error_msg.push("Enter Owner Name");

      if (flg === true) {
        flg = false;
      }
    }

    if(phone.search(/\S/) == -1){
      all_error_msg.push("Please enter mobile number");
      if(flg == true){flg = false;} 
    }else{
        if(phone.length < 10){
            all_error_msg.push("Please enter 10 digit mobile number");
            if(flg == true){flg = false;}  
        }
    }

    if(email.search(/\S/) == -1){
     
        all_error_msg.push("Please enter email Id");
        if(flg == true){flg = false;}
      
    }else if(email.search(/\S/) != -1){
   
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(email) === false) {
        all_error_msg.push("Please enter valid email Id");
        if(flg == true){flg = false;}
      }
    }
    
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
	  const lowercaseRegExp   = /(?=.*?[a-z])/;
	  const digitsRegExp      = /(?=.*?[0-9])/;
	  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
	  const minLengthRegExp   = /.{8,}/;
    
    if (password.search(/\S/) == -1) {
      all_error_msg.push("Enter your password");
      if (flg === true) {
        flg = false;
      }
    }else if(password.search(/\S/) != -1){
      const passwordLength =      password.length;
      const uppercasePassword =   uppercaseRegExp.test(password);
      const lowercasePassword =   lowercaseRegExp.test(password);
      const digitsPassword =      digitsRegExp.test(password);
      const specialCharPassword = specialCharRegExp.test(password);
      const minLengthPassword =   minLengthRegExp.test(password);
  
      let errMsg ="";
      let flgg = true;
      if(!uppercasePassword){
          errMsg= "password at least one uppercase";
          flgg = false;
      }else if(!lowercasePassword){
          errMsg= "password at least one lowercase";
          flgg = false;
      }else if(!digitsPassword){
          errMsg= "password at least one digit";
          flgg = false;
      }else if(!specialCharPassword){
          errMsg= "password at least one special characters";
          flgg = false;
      }else if(!minLengthPassword){
          errMsg= "password at least minimum eight characters";
          flgg = false;
      }else{
        errMsg="";
      }
  
      if(flgg == false){
        all_error_msg.push(errMsg);
        if(flg == true){flg = false;}
      }
    }

    if (confirm_password.search(/\S/) == -1) {
      all_error_msg.push("please confirm password");
      if (flg === true) {
        flg = false;
      }
    }

    if(password != confirm_password){
      all_error_msg.push("Mismatch confirm password");
      if(flg == true){flg = false;}
      
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'francise/register', {name: username, email: email, phone: phone, owner_name: owner_name, password: password, password_confirmation: confirm_password, francise_code: location.state.code}).then((result) => {
          if(result.status === true){
                setRegisterId(result.data.id);
                setLoading(false);

                resetForm();
                all_error_msg.push(result.message);
                onShowAlert("success", all_error_msg);
                
                navigate("/");

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

  

  const sendEmailOtp = () => {
    const all_error_msg = [];
      postWithOutToken(API_URL, 'francise/resend-otp', {email: email}).then((result) => {
          if(result.status === true){
              all_error_msg.push(result.message);
              onShowAlert("success", all_error_msg); 
                          
              setMinutesEmail(1);
              setSecondsEmail(30);

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
  }

  const VerifyEmailOtp = () => {
    let flg = true;
    const all_error_msg = [];

   
      if(email_otp.length < 6){
        all_error_msg.push("Please enter 6 digit otp to verify your email");
        if(flg == true){flg = false;}
      }


    if(flg == true){
        setLoading(true);
        postWithOutToken(API_URL, 'francise/email-otp-verify', {email: email,otp: email_otp}).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                resetForm();
                setModelEmailOpen(false);

                all_error_msg.push(result.message);
                onShowAlert("success", all_error_msg);
                
                navigate("/");
                
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }


 return(
    <div className="authapper">
    <div className="authapper-body">
    <CustomAlert
              header={"Signup"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
              


    <div className="authapper-left">
        <div className='logo'>
            <img src={logo}/>
        </div>
        <p>Lorem Ipsum is simply dummy text of the printing and 
        typesetting industry. Lorem Ipsum has been the industry's 
        standard dummy text ever since the 1500s</p>
        <p><a href='mailto:info@yoursitename.com'><i className="fa fa-envelope" aria-hidden="true"></i> info@yoursitename.com</a></p>
        <p><a href='tel:+00 00000000'><i className="fa fa-phone" aria-hidden="true"></i> +00 00000000</a></p>
        <span className='copyright'>Veda Harbal Copyright © 2024, Version 0.0.1</span>
     </div>
     <div className="authapper-right">
         <h2>Sign Up</h2>
         <form onSubmit={SignInAction}>
            <div className='form-group'>
                <img src={user}/>
                <input type='text' placeholder='Name' disabled value={username} />
            </div>
            <div className='form-group'>
                <img src={user}/>
                <input type='text' placeholder='Owner Name' onChange={(event) => setOwnerName(event.target.value)} />
            </div>
            <div className='form-group'>
            <img src={mail}/>
                <input type='text' placeholder='Email' disabled value={email}/>
            </div>
            <div className='form-group'>
            <img src={call}/>
                <input type='text' placeholder='Phone' disabled value={phone} maxLength={10} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                      return true;
                              }else{event.preventDefault();}}} />
            </div>
           
            <div className='form-group'>
                <img src={lock}/>
                <input type='password' placeholder='Password'  onChange={(event) => setPassword(event.target.value)}/>
            </div>
            <div className='form-group'>
                <img src={lock}/>
                <input type='password' placeholder='Confirm Password'  onChange={(event) => setConfirmPassword(event.target.value)}/>
            </div>
            <button type='submit' className='themeBtn'>Signup</button>
            <div className='formbtm'>
             {/*<p>Direct Seller Login |Preferred Customer Login </p>*/}
             <p>Already have an account ? <Link to={'/'}>LOGIN</Link></p>
            </div>
         </form>
     </div>
     </div>

    
 <Modal isOpen={modalEmailOpen} onRequestClose={CloseEmailModel} contentLabel=" OTP Modal" >
    <div className='modal_header'>
    OTP Verification <button onClick={CloseEmailModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
    </button>
    </div>
    <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
        
            <div className='row'>
                    
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Enter Email OTP</h2>
                    </div>

                    <div className='col-md-12 otp_input_parent_div' >
                        
                        <OtpInput
                            value={email_otp}
                            onChange={setEmailOtp}
                            numInputs={6}
                            inputType={'tel'}
                            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                            renderInput={(props) => <input {...props} />}
                            />
                    </div>

                    <div style={{ marginTop: 20, alignSelf: "center", width: '100%', display: 'flex', flexDirection: 'column'}}>
                        <>
                        {
                        seconds_email > 0 || minutes_email > 0 ? 
                        <span style={{textAlign: 'center'}} >Time Remaining: {minutes_email < 10 ? `0${minutes_email}` : minutes_email}:{seconds_email < 10 ? `0${seconds_email}` : seconds_email}</span>
                        : <span style={{textAlign: 'center'}}>Didn't recieve code?</span>
                        }

                        <div style={{ marginTop: 20, alignSelf: "center" }}>
                            {
                                seconds_email > 0 || minutes_email > 0 ?
                                <button type="button" className='fadebtn_resend'>Resend Email OTP</button>
                                : 
                                <button type="button" className='fillbtn' onClick={sendEmailOtp}>Resend Email OTP</button>
                                
                                
                            }
                        </div>

                        </>

                        </div>

                                
                <div className='col-md-12'>
                    <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                        <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseEmailModel}>Cancel</button>
                        <button type='button' className='fillbtn' style={{width: 'auto'}} onClick={VerifyEmailOtp} >Verify OTP</button>
                    </div>
                </div>
            </div>
        
    </div>
    </Modal>
    

    </div>

 )
}
export default FranchiseSignup;