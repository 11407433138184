import   './ChangePassword.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
const ChangePassword_Franchise = () =>{
    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
 
    const dispatch = useDispatch();
    const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [old_password, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
  
 
    const pagename = useCallback(() => {
       dispatch(setPageName('Change Password'));
   },[pageName])
 
   useEffect(() => {
       pagename();
   }, []);

   const resetForm = () => {
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  
  }

  //alert start
  
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end 

  const ChangeAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if (old_password.search(/\S/) == -1) {
        all_error_msg.push("Enter your old password");
        if (flg === true) {
          flg = false;
        }
      }

    
    
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
	  const lowercaseRegExp   = /(?=.*?[a-z])/;
	  const digitsRegExp      = /(?=.*?[0-9])/;
	  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
	  const minLengthRegExp   = /.{8,}/;
    
    if (password.search(/\S/) == -1) {
      all_error_msg.push("Enter your password");
      if (flg === true) {
        flg = false;
      }
    }else if(password.search(/\S/) != -1){
      const passwordLength =      password.length;
      const uppercasePassword =   uppercaseRegExp.test(password);
      const lowercasePassword =   lowercaseRegExp.test(password);
      const digitsPassword =      digitsRegExp.test(password);
      const specialCharPassword = specialCharRegExp.test(password);
      const minLengthPassword =   minLengthRegExp.test(password);
  
      let errMsg ="";
      let flgg = true;
      if(!uppercasePassword){
          errMsg= "password at least one uppercase";
          flgg = false;
      }else if(!lowercasePassword){
          errMsg= "password at least one lowercase";
          flgg = false;
      }else if(!digitsPassword){
          errMsg= "password at least one digit";
          flgg = false;
      }else if(!specialCharPassword){
          errMsg= "password at least one special characters";
          flgg = false;
      }else if(!minLengthPassword){
          errMsg= "password at least minimum eight characters";
          flgg = false;
      }else{
        errMsg="";
      }
  
      if(flgg == false){
        all_error_msg.push(errMsg);
        if(flg == true){flg = false;}
      }
    }

    if (confirm_password.search(/\S/) == -1) {
      all_error_msg.push("please confirm password");
      if (flg === true) {
        flg = false;
      }
    }

    if(password != confirm_password){
      all_error_msg.push("Mismatch confirm password");
      if(flg == true){flg = false;}
      
    }

    if (flg === true) {
      setLoading(true);
      postWithToken(API_URL, 'change-password', {old_password: old_password, password: password, password_confirmation: confirm_password}, userToken).then((result) => {
          if(result.success === true){
                setLoading(false);
                resetForm();
                all_error_msg.push(result.message);
                onShowAlert("success", all_error_msg); 

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }
    return(
        <div className='ChangePassword'>
        <CustomAlert
             header={"Change Password"}
             btnText={"Accept"}
             text={alert.text}
             type={alert.type}
             show={alert.show}
             onClosePress={onCloseAlert}
             pressCloseOnOutsideClick={true}
             alertStyles={{}}
             headerStyles={{}}
             textStyles={{}}
             buttonStyles={{}}
             />  

             {
               loading === true ?
               <div className="activityLoader">
                     <GridLoader
                       color={color}
                       loading={loading}
                       cssOverride={override}
                       size={30}
                       aria-label="Loading Spinner"
                       data-testid="loader"
                       Loader="GridLoader"
                     /> 
              </div>     
              : null
             }
             
       <div className='row'>
           <div  className='col-md-12'>
           <div className='card'>
           <form onSubmit={ChangeAction}>
                  <div className='row'>
                  
                   <div className='col-md-12'>
                   <h2>Change Password</h2>
                   </div>
                   
                   <div className='col-md-4'>
                       <div className='form-group'>
                            <label>Old Password</label>
                            <input type='Password' placeholder='Old Password' value={old_password} onChange={(event) => setOldPassword(event.target.value)}/>
                       </div>
                   </div>
                   <div className='col-md-4'>
                       <div className='form-group'>
                            <label>New Password</label>
                            <input type='Password' placeholder='New Password' value={password}  onChange={(event) => setPassword(event.target.value)}/>
                       </div>
                   </div>
                   <div className='col-md-4'>
                       <div className='form-group'>
                            <label>Confirm Password</label>
                            <input type='Password' placeholder='Confirm Password' value={confirm_password}  onChange={(event) => setConfirmPassword(event.target.value)}/>
                       </div>
                   </div>
                 
                   <div className='col-md-4'>
                       <div className='form-group'>
                            <button type='submit' className='sucessBtn'>Submit</button>
                       </div>
                   </div>
                 
                  </div>
                  </form>
               </div>
           </div>
       </div>
    </div>
    )
 }
 export default ChangePassword_Franchise;