import  './ProductOffers.css';
import close from '../../../../assets/img/close.png';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';


const ProductOffers = () =>{

   Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [offer, setOffer] = useState({IsLoading: true, list: [], total_count: 0});
   const [list_limit, setListLimit] = useState(20);
   const [page_selected, setPageSelected] = useState(null);

   useEffect(() => {
       dispatch(setPageName('Product Offers'));
   }, []);

   useEffect(() => {
      if(userToken != ''){
        OfferFetch(0,list_limit); 
      }
    }, [userToken]); 
  
     const OfferFetch = (offset, limit) => {
      let post_data = {};
        
        setLoading(true);
  
        postWithToken(API_URL, "francise/offer-products-list", post_data, userToken).then((result) => {
          console.log(result);
            if(result.status === true){
              setLoading(false);
              setOffer({IsLoading : false, list: result.data, total_count: result.total_count});
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }
  
     //alert start
    
     const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
      
      function onCloseAlert(){
            setAlert({
            show: false,
            type: null,
            text: [],
            })
      }
      
      function onShowAlert(type,msg){
            setAlert({
            type: type,
            text: msg,
            show: true
            })
      }
  
  //alert end 
  
        

   {/*<div className='mypurchase'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>

                   <div className='note'>
                   No separate BV will be given for the Bills issued against Order Booking as you have already availed the BV against Order Booking
                   </div>
               
                </div>
            </div>
        </div>
     </div>*/}

    return(
     

     <div className='productoffer'>
      <CustomAlert
              header={"Direct Downline"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>

                  {
                       offer.IsLoading === false && offer.list.length > 0 ?
                      
                       offer.list.map((item, index) => {

                            return(
                              <div key={index} className='card'>
                                    <h3>{item.name}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                              </div>
                            );
                         })

                         : offer.IsLoading === false && offer.list.length == 0 ?

                           <div className='card'>

                              <div className='note'>
                                 No Offer Found
                              </div>
                           
                           </div>

                        : null }


                
               
            </div>
        </div>

     </div>

    )
 }
 export default ProductOffers;