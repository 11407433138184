
import  './MyPurchase.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithTokenFormData} from "../../../../Service/service";
import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';

const MyPurchase = () =>{
   Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [puchase, setPurchase] = useState({IsLoading: true, list: [], total_count: 0});
   const [list_limit, setListLimit] = useState(20);
   const [page_selected, setPageSelected] = useState(null);

   useEffect(() => {
       dispatch(setPageName('My Purchase'));
       MyPurchaseFetch(0,list_limit);
   }, []);

   const MyPurchaseFetch = (offset, limit) => {
      postWithToken(API_URL, 'user/my-purchases', {offset: offset, limit: limit}, userToken).then((result) => {
          console.log(result);
          if(result.status === true){
            setPurchase({IsLoading : false, list: result.data, total_count: result.total_count});
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

   //alert start
  
   const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
  })

  function onCloseAlert(){
        setAlert({
        show: false,
        type: null,
        text: [],
        })
  }

  function onShowAlert(type,msg){
        setAlert({
        type: type,
        text: msg,
        show: true
        })
  }

//alert end 

const [itemOffset, setItemOffset] = useState(0);
function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
   
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //const currentItems = roleList.list.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(puchase.total_count / itemsPerPage);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % puchase.total_count;
      /*console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );*/

      setPageSelected(event.selected);
      MyPurchaseFetch(newOffset, list_limit);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" > "
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel=" < "
          renderOnZeroPageCount={null}
          forcePage={page_selected}
        />
      </>
    );
  }


    return(
     <div className='mypurchase'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                   {/*<div className='note'>
                   No separate BV will be given for the Bills issued against Order Booking as you have already availed the BV against Order Booking
                  </div>*/}
                  <table className='table'>
                     <thead>
                        <tr>
                        <th>Date</th>
                        <th>Invoice No.</th>
                        <th>Franchise Name</th>
                        <th>Amount</th>
                        <th>Bv</th>
                        <th>Action</th>
                        </tr>
                     </thead>
                     
                     {
                       puchase.IsLoading === false && puchase.list.length > 0 ?
                              <tbody>
                                 {
                                puchase.list.map((item, index) => {
                                    return(
                                        <tr key={'purchase_' + index}>
                                        <td>{Moment(item.date).format('DD/MM/YYYY')}</td>
                                        <td>{item.invoice_no}</td>
                                        <td>{item.francise.name}</td>
                                        <td>{'\u20B9'} {item.total}</td>
                                        <td>{item.total_points}</td>
                                        <td><a href={SITE_URL + 'invoice-generate/' + item.custom_order_id} download style={{ textDecoration: 'none', marginLeft: 10}}>Download Invoice</a></td>
                                        </tr>
                                               
                                          );
                                       })
                                    }
                                    
                                    </tbody>
                                     : puchase.IsLoading === false && puchase.list.length == 0 ?
                                     <tbody>
                                          <tr>
                                              <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                          </tr>
                                      </tbody>
      
                                  : puchase.IsLoading === true ?
                                     <tbody>
                                      <tr>
                                        <td ><Skeleton height={24} /></td>
                                        <td ><Skeleton height={24} /></td>
                                        <td ><Skeleton height={24} /></td>
                                        <td ><Skeleton height={24} /></td>
                                        <td ><Skeleton height={24} /></td>
                                        <td ><Skeleton height={24} /></td>

                                    </tr>
      
                                     </tbody>
      
                              : null }
                     </table>
                </div>
                {
                    puchase.IsLoading === false && puchase.list.length > 0 ?
                        <div className="pagination_div">
                                <PaginatedItems itemsPerPage={list_limit} />
                    </div>
                    :
                    null
                }      
            </div>
        </div>
     </div>
    )
 }
 export default MyPurchase;