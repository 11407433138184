import   './DerictDownline.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
const DeriectDownline = () =>{

    Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [direct_downline, setDirectDownline] = useState({IsLoading: true, list: [], total_count: 0});
   const [list_limit, setListLimit] = useState(20);
   const [page_selected, setPageSelected] = useState(null);

   const [modalViewOpen, setViewModel] = useState(false);

   const [user_data, setUserData] = useState(null);

   const [list_type,  setListType] = useState('All');


   useEffect(() => {
       dispatch(setPageName('Direct Downline'));
   }, []);

   useEffect(() => {
    if(userToken != ''){
      DownlineFetch(0,list_limit,list_type); 
    }
  }, [userToken]); 

  const CloseViewModel = () => {
    setViewModel(false);
    setUserData(null);
}


   const DownlineFetch = (offset, limit, type) => {
    let post_data = {};

      if(type == 'All'){
        post_data = {
            offset: offset, 
            limit: limit,
            new_member: 0,
            active_member: 0,
            is_purchased: 0
          };
      }else if(type == 'new_member'){
        post_data = {
            offset: offset, 
            limit: limit,
            new_member: 1,
            active_member: 0,
            is_purchased: 0
          };
      }else if(type == 'active_member'){
        post_data = {
            offset: offset, 
            limit: limit,
            new_member: 0,
            active_member: 1,
            is_purchased : 0
          };
      }else if(type == 'is_purchased'){
        post_data = {
            offset: offset, 
            limit: limit,
            new_member: 0,
            active_member: 0,
            is_purchased : 1
          };
      }

      setLoading(true);

      postWithToken(API_URL, 'user/direct-downlines', post_data, userToken).then((result) => {
          if(result.status === true){
            setLoading(false);
            setDirectDownline({IsLoading : false, list: result.data, total_count: result.total_count});
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

   //alert start
  
   const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })

    function onCloseAlert(){
        setAlert({
        show: false,
        type: null,
        text: [],
        })
    }

    function onShowAlert(type,msg){
        setAlert({
        type: type,
        text: msg,
        show: true
        })
    }

//alert end 

       const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(direct_downline.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % direct_downline.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              DownlineFetch(newOffset, list_limit, list_type);
              setItemOffset(newOffset);
            };
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }

        const openDetails = (id) => {
            setLoading(true);
            postWithToken(API_URL, 'user/view-member', {user_id: id}, userToken).then((result) => {
                console.log(result);
                if(result.status === true){
                    setLoading(false);
                    setUserData(result.data);
                    setViewModel(true);
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }

        const listTypeChange = (type) => {
            setListType(type);
           
            DownlineFetch(0,list_limit,type); 
           
        }


    return(
     <div className='deriectdownline'>
        <CustomAlert
              header={"Direct Downline"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                    <ul>
                        <li><a onClick={() => {listTypeChange('All')}}><span className={list_type == "All" ? 'type_li active' : 'type_li'}>All</span></a></li>
                        <li><a onClick={() => {listTypeChange('new_member')}}><span className={list_type == "new_member" ? 'type_li active' : 'type_li'}>New</span></a></li>
                        <li><a onClick={() => {listTypeChange('active_member')}}><span className={list_type == "active_member" ? 'type_li active' : 'type_li'}>Active</span></a></li>
                        <li><a onClick={() => {listTypeChange('is_purchased')}}><span className={list_type == "is_purchased" ? 'type_li active' : 'type_li'}>Purchase Due</span></a></li>
                    </ul>
                 <table className='table'>
                    <thead>
                    <tr> 
                        <th>S.No.</th>
                        <th>ID No.</th>
                        <th colSpan={2}>Name</th>
                        <th>Count</th>
                        <th>% Level</th>
                        <th>Total PV</th>
                        <th>Total Purchase</th>
                        <th colSpan={7}>Current Month BV</th>
                    </tr>
                    </thead>
                   
                    <tbody>
                    <tr> 
                        <td></td>
                        <td></td>
                        <td colSpan={2}></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={1}><b>PBV</b></td>
                        <td colSpan={1}><b>TBV</b></td>
                    </tr>
                    <tr> 
                        <td></td>
                        <td></td>
                        <td colSpan={2}></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>BV</b></td>
                        <td><b>Total</b></td>
                    </tr>
                    {
                       direct_downline.IsLoading === false && direct_downline.list.length > 0 ?
                      
                        direct_downline.list.map((item, index) => {

                            let row_c = "";

                            if(parseInt(index + 1) % 2 == 0){
                                row_c = 'subheading';
                            }

                            return(
                                <tr key={index} className={row_c}>
                                    <td>{item.id}</td>
                                    <td>{item.id_no}</td>
                                    <td colSpan={2}><div className='flxtd'><span>{item.name} </span><div className='tdAction'><a onClick={() => {openDetails(item.id)}} ><i class="fa fa-eye" aria-hidden="true"></i></a ></div></div></td>
                                    <td>{item.user_count}</td>
                                    <td>{item.level != null ? item.level :  null}</td>
                                    <td>{item.total_points}</td>
                                    <td>{item.total_purchase}</td>
                                    <td>{item.bv}</td>
                                    <td>{item.total_bv}</td>
                                </tr>
                            );
                         })

                         : direct_downline.IsLoading === false && direct_downline.list.length == 0 ?
                            <tr>
                                <td colSpan={9} style={{textAlign: "center"}}><b>No Record Found</b></td>
                            </tr>
                        : direct_downline.IsLoading === true ?
                            <tr>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                            </tr>
                        : null }
                    </tbody>
                 </table>
                </div>
                {
                       direct_downline.IsLoading === false && direct_downline.list.length > 0 ?
                          <div className="pagination_div">
                                  <PaginatedItems itemsPerPage={list_limit} />
                        </div>
                       :
                       null
                }       
            </div>
        </div>

        <Modal isOpen={modalViewOpen} onRequestClose={CloseViewModel} contentLabel="Document Modal" >
            <div className='modal_header'>
            User Infomation <button onClick={CloseViewModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
            </button>
            </div>
            <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                <div className='row'>
                    <div className='col-md-12' >
                            <div className="row" >
                               <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Account Holder Name : </label>
                                        <span style={{display: 'flex', flex: 1}}>{user_data != null ? user_data.full_name :  null}</span> 
                                        </div>
                                </div> 
                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Email : </label>
                                        <span style={{display: 'flex', flex: 1}}>{user_data != null ? user_data.email :  null}</span> 
                                        </div>
                                </div>
                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Phone : </label>
                                        <span style={{display: 'flex', flex: 1}}>{user_data != null ? user_data.phone :  null}</span> 
                                        </div>
                                </div>
                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Referral Code : </label>
                                        <span style={{display: 'flex', flex: 1}}>{user_data != null ? user_data.user_referral_code :  null}</span> 
                                        </div>
                                </div>
                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Level : </label>
                                        <span style={{display: 'flex', flex: 1}}>{user_data != null ? user_data.current_level != null ? user_data.current_level.name : null :  null}</span> 
                                        </div>
                                </div>
                            </div>
                        </div>
                     <div className='col-md-12'>
                        <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                            <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseViewModel}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        </div>
    )
 }
 export default DeriectDownline;