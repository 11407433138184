import {NavLink, Link, useNavigate, useLocation} from 'react-router-dom';
import './Qualification.css';
import upload_image from "../../../../assets/img/upload.png";
import { useState, useEffect,useRef, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import Modal from 'react-modal';


const Qualification = () =>{

    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;

    const dispatch = useDispatch();
    const year = new Date().getFullYear();

    const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [q_data, setQData] = useState(null);

    const [s_passing_year, setSPY] = useState("");
    const [s_doc, setSDOC] = useState("");
    const [s_subject, setSSubject] = useState("");
    const [hs_passing_year, setHSPY] = useState("");
    const [hs_subject, setHSSubject] = useState("");
    const [hs_doc, setHSDOC] = useState("");
    const [g_passing_year, setGPY] = useState(""); 
    const [g_subject, setGSubject] = useState("");
    const [g_doc, setGDOC] = useState("");
    const [m_passing_year, setMPY] = useState("");
    const [m_subject, setMSubject] = useState("");
    const [m_doc, setMDOC] = useState("");

    const [modalDocumentViewOpen, setDocumentViewModel] = useState(false);
    const [document_url,setDocumentUrl] = useState(null);

    const [modalViewOpen, setViewModel] = useState(false);

    const [isActive, setisActive] =useState(false);

    const openModal = () =>{
        setisActive(true)
    }
    const closeModal = () =>{
     setisActive(false)
    }

    const CloseViewModel = () => {
        setViewModel(false);
    }

    const openInfo = () => {
        setViewModel(true);
    }

    const openModelByType = ()=> {
        setisActive(true)
    }


    const pagename = useCallback(() => {
        dispatch(setPageName('Qualification'));
    },[pageName])

    useEffect(() => {
        pagename();
        fetchData();
    }, []);

     //alert start
  
     const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    
    function onSelectFile(event, type) {
      event.preventDefault();
      if (event.target.files && event.target.files[0]) {
        let file = event.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/png'){
            const reader = new window.FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
      
             // console.log(reader.result);
             if(type == 'S'){
                setSDOC(event.target.files[0]);
             }else if(type == 'HS'){
                setHSDOC(event.target.files[0]);
             }else if(type == 'G'){
                setGDOC(event.target.files[0]);
             }else if(type == 'M'){
                setMDOC(event.target.files[0]);
             }
           
              //console.log(event.target.files[0]);
            };
        }else{
            const all_error_msg = [];
            all_error_msg.push("File type support only 'jpeg/png'");
            onShowAlert("error", all_error_msg);
        }
        
      }
    }


    const SubmitAction = (event) => {

        event.preventDefault();

        let flg = true;
        const all_error_msg = [];

        if (s_passing_year == "") {
            all_error_msg.push("Please select Secondary Passing Year");
      
            if (flg === true) {
              flg = false;
            }
        }

        if (s_doc == "") {
            all_error_msg.push("Please upload Secondary Document");
      
            if (flg === true) {
              flg = false;
            }
        }

        if (hs_passing_year == "") {
            all_error_msg.push("Please select Higher Secondary Passing Year");
      
            if (flg === true) {
              flg = false;
            }
        }

        if (hs_subject.search(/\S/) == -1) {
            all_error_msg.push("Please Enter Higher Secondary Subject");
      
            if (flg === true) {
              flg = false;
            }
        }

        if (hs_doc == "") {
            all_error_msg.push("Please upload Higher Secondary Document");
      
            if (flg === true) {
              flg = false;
            }
        }

        if (g_passing_year != "") {
            if(g_subject.search(/\S/) == -1){
                all_error_msg.push("Please Enter Graduation Subject");
        
                if (flg === true) {
                    flg = false;
                }
            }
            if(g_doc == ""){
                all_error_msg.push("Please upload Graduation Document");
      
                if (flg === true) {
                  flg = false;
                }
            }
        }

        if(g_subject.search(/\S/) != -1){
            if(g_passing_year == ""){
                all_error_msg.push("Please Select Graduation Passing Year");
        
                if (flg === true) {
                    flg = false;
                }
            }
            if(g_doc == ""){
                all_error_msg.push("Please upload Graduation Document");
      
                if (flg === true) {
                  flg = false;
                }
            }
        }

        if(g_doc != ""){
            if(g_passing_year == ""){
                all_error_msg.push("Please Select Graduation Passing Year");
        
                if (flg === true) {
                    flg = false;
                }
            }
            if(g_subject.search(/\S/) == -1){
                all_error_msg.push("Please Enter Graduation Subject");
        
                if (flg === true) {
                    flg = false;
                }
            }

        }

        if (m_passing_year != "") {
            if(m_subject.search(/\S/) == -1){
                all_error_msg.push("Please Enter Master's Subject");
        
                if (flg === true) {
                    flg = false;
                }
            }
            if(m_doc == ""){
                all_error_msg.push("Please upload Master's Document");
      
                if (flg === true) {
                  flg = false;
                }
            }
        }

        if (m_subject.search(/\S/) != -1) {
            if(m_passing_year == ""){
                all_error_msg.push("Please Select Master's Passing Year");
        
                if (flg === true) {
                    flg = false;
                }
            }
            if(m_doc == ""){
                all_error_msg.push("Please upload Master's Document");
      
                if (flg === true) {
                  flg = false;
                }
            }
        }

        if (m_doc != "") {
            if(m_passing_year == ""){
                all_error_msg.push("Please Select Master's Passing Year");
        
                if (flg === true) {
                    flg = false;
                }
            }
            
            if(m_subject.search(/\S/) == -1){
                all_error_msg.push("Please Enter Master's Subject");
        
                if (flg === true) {
                    flg = false;
                }
            }
        }

        if (flg === true) {
            setLoading(true);
            let post_data = {
                secondary_document: s_doc,
                higher_secondary_document: hs_doc,
                graduate_document: g_doc,
                masters_document: m_doc,
                secondary_year_of_passing: s_passing_year,
                higher_secondary_year_of_passing: hs_passing_year,
                graduate_year_of_passing: g_passing_year,
                masters_year_of_passing: m_passing_year,
                higher_secondary_subjects: hs_subject,
                graduate_subjects: g_subject,
                masters_subjects: m_subject,
            };
      
           postWithTokenFormData(API_URL, 'add-qualification', post_data, userToken).then((result) => {
               if(result.status === true){

                        fetchData();

                        setSPY("");
                        setSDOC("");
                        setSSubject("");
                        setHSPY("");
                        setHSSubject("");
                        setHSDOC("");
                        setGPY(""); 
                        setGSubject("");
                        setGDOC("");
                        setMPY("");
                        setMSubject("");
                        setMDOC("");

                        setLoading(false);
                        setisActive(false);
                }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }else{
            onShowAlert("error", all_error_msg);
            
        } 
    }

    const fetchData = () => {
        postWithToken(API_URL, 'get-qualification', {}, userToken).then((result) => {
            console.log(result);
           if(result.status === true){
                setQData(result.data);
            }else{
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const ClosePhotoModel = () => {
        setDocumentViewModel(false);
        setDocumentUrl(null);
    }

    const openDocumentInfo = (url) => {
        setDocumentUrl(url);
        setDocumentViewModel(true);
    }

    return(
     <div className='qualification'>
        <CustomAlert
              header={"Qualification"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-4'>
                <div className='card'>
                    <div className='actioncard'>
                        {
                            q_data == null ?
                            <button type='button' className='openModal' onClick={openModelByType}><i className="fa fa-file-text-o" aria-hidden="true"></i> Upload</button>
                            : null
                        }
                        {
                            q_data != null ?
                                <a onClick={() => {openInfo()}} className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></a>
                            : null
                        }  
                    </div>

                    <h2>Qualification</h2>

                    { q_data != null ?
                       <> 
                        <span>Uploaded by at { Moment(q_data.updated_at).format('DD/MM/YYYY') + ' - ' + Moment(q_data.updated_at).format('hh:mm:ss A')}</span>
                        {
                            q_data.masters_subjects !=  null ?
                            <p>{q_data.masters_subjects} in {q_data.masters_year_of_passing}</p>
                            : null
                        }
                        {
                            q_data.graduate_subjects != null ?
                            <p>{q_data.graduate_subjects} in {q_data.graduate_year_of_passing}</p>
                            : null
                        }
                        {
                            q_data.higher_secondary_subjects != null ?

                            <p>HS {q_data.higher_secondary_subjects} in {q_data.higher_secondary_year_of_passing}</p>
                            : null
                        }
                        {
                            q_data.secondary_year_of_passing != null ?
                            <p>Secondary in {q_data.secondary_year_of_passing}</p>
                            : null
                        }
                       </> 
                      : null
                    }
                </div>
            </div>
        </div>

        <div className={`modal_custom xlm ${isActive ? "open":""}`} id="modal">
            <div className='modalBody_custom'>
            <h3>Submit Qualification Information <Link onClick={closeModal}><i class="fa fa-times" aria-hidden="true"></i></Link></h3>
                    <form onSubmit={SubmitAction}>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Secondary Year*</label>
                                   
                                     <select value={s_passing_year} onChange={(event) => setSPY(event.target.value)}>
                                        <option value="">Select Year</option>
                                      {
                                            Array.from( new Array(50), (v,i) =>{
                                                return (
                                                <option key={i} value={year - i}>{year - i}</option>
                                                )
                                              }
                                            )
                                        
                                      }
                                    </select>   

                                </div>
                            </div>
                            <div className='col-md-4'>
                                {/*<div className='form-group'>
                                    <label>Subject*</label>
                                    <input type='text' value={s_subject} onChange={(event) => setSSubject(event.target.value)}/>
                                </div>*/}
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Upload Document*</label>
                                    <input type="file" accept="image/png, image/jpeg" onChange={(e) => onSelectFile(e,'S')} />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Higher Secondary Year*</label>

                                    <select value={hs_passing_year} onChange={(event) => setHSPY(event.target.value)}>
                                        <option value="">Select Year</option>
                                      {
                                            Array.from( new Array(50), (v,i) =>{
                                                return (
                                                <option key={i} value={year - i}>{year - i}</option>
                                                )
                                              }
                                            )
                                        
                                      }
                                    </select>   
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Subject*</label>
                                    <input type='text' value={hs_subject} onChange={(event) => setHSSubject(event.target.value)}/>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Upload Document*</label>
                                    <input type="file" accept="image/png, image/jpeg" onChange={(e) => onSelectFile(e,'HS')} />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Graduation Year</label>
                                    <select value={g_passing_year} onChange={(event) => setGPY(event.target.value)}>
                                        <option value="">Select Year</option>
                                      {
                                            Array.from( new Array(50), (v,i) =>{
                                                return (
                                                <option key={i} value={year - i}>{year - i}</option>
                                                )
                                              }
                                            )
                                        
                                      }
                                    </select>   
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Subject</label>
                                    <input type='text' value={g_subject} onChange={(event) => setGSubject(event.target.value)}/>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Upload Document</label>
                                    <input type="file" accept="image/png, image/jpeg" onChange={(e) => onSelectFile(e,'G')} />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Master's Year</label>
                                    <select value={m_passing_year} onChange={(event) => setMPY(event.target.value)}>
                                        <option value="">Select Year</option>
                                      {
                                            Array.from( new Array(50), (v,i) =>{
                                                return (
                                                <option key={i} value={year - i}>{year - i}</option>
                                                )
                                              }
                                            )
                                        
                                      }
                                    </select>  
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Subject</label>
                                    <input type='text' value={m_subject} onChange={(event) => setMSubject(event.target.value)}/>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Upload Document</label>
                                    <input type="file" accept="image/png, image/jpeg" onChange={(e) => onSelectFile(e,'M')} />
                                </div>
                            </div>
                        </div>

                        <div className='btngrp' style={{display: 'flex', justifyContent: 'center'}}>
                            <button type="button" onClick={closeModal} className="cancelBtn">Cancel</button>
                            <button type='submit' className="sucessBtn">Submit</button>
                        </div>

                    </form>            
                 
            </div>
        </div>


        <Modal isOpen={modalViewOpen} onRequestClose={CloseViewModel} contentLabel="Document Modal" >
            <div className='modal_header'>
            Qualificaiton Infomation View <button onClick={CloseViewModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
            </button>
            </div>
            <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                <div className='row'>
                    <div className='col-md-12' >

                            <div className="row" >
                                                             
                                <div className="col-md-8">
                                        <div class="form-group" style={{ marginTop: '35px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Secondary in {q_data != null ? q_data.secondary_year_of_passing : null}</label>
                                        </div>
                                </div> 
                                <div className="col-md-4">
                                        <div className="" style={{alignItems: 'center', justifyContent: 'center'}}>
                                            <div className="" >
                                                <a onClick={() => {openDocumentInfo(q_data.secondary_document_path)}} >
                                                    {
                                                        q_data != null ?

                                                        <img  src={q_data.secondary_document_path} style={{width: '75px'}} />

                                                        :  null
                                                    }
                                                </a>    
                                            </div>
                                            
                                        </div>   
                                </div>

                                
                                <div className="col-md-8">
                                        <div class="form-group" style={{marginTop: '35px',display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>HS {q_data != null ? q_data.higher_secondary_subjects : null} in {q_data != null ? q_data.higher_secondary_year_of_passing : null}</label>
                                        </div>
                                </div> 
                                <div className="col-md-4">
                                     <div className="" style={{alignItems: 'center', justifyContent: 'center'}}>
                                            <div className="">
                                            <a onClick={() => {openDocumentInfo(q_data.higher_secondary_document_path)}} >
                                                    {
                                                        q_data != null ?

                                                        <img src={q_data.higher_secondary_document_path} style={{width: '75px'}} />

                                                        :  null
                                                    }

                                              </a>      
                                                    
                                            </div>
                                            
                                        </div>   
                                </div> 

                                {
                                    q_data != null ?
                                            q_data.graduate_subjects != null ?

                                            <>
                                             
                                                <div className="col-md-8">
                                                        <div class="form-group" style={{marginTop: '35px',display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>{q_data != null ? q_data.graduate_subjects : null} in {q_data != null ? q_data.graduate_year_of_passing : null}</label>
                                                        </div>
                                                </div> 
                                                <div className="col-md-4">
                                                    <div className="" style={{alignItems: 'center', justifyContent: 'center'}}>
                                                        <div className="">
                                                        <a onClick={() => {openDocumentInfo(q_data.graduate_document_path)}} >
                                                                {
                                                                    q_data != null ?

                                                                    <img src={q_data.graduate_document_path} style={{width: '75px'}} />

                                                                    :  null
                                                                }
                                                        </a>        
                                                                
                                                        </div>
                                                        
                                                    </div>     
                                                </div> 
                                            </>    

                                            : null
                                    : null        
                                }  


                                {
                                    q_data != null ?
                                            q_data.masters_subjects != null ?

                                            <>
                                                                                             
                                                <div className="col-md-8">
                                                        <div class="form-group" style={{marginTop: '35px',display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>{q_data.masters_subjects} in {q_data.masters_year_of_passing}</label>
                                                        </div>
                                                </div> 
                                                <div className="col-md-4">
                                                  <div className="" style={{alignItems: 'center', justifyContent: 'center'}}>
                                                        <div className="">
                                                        <a onClick={() => {openDocumentInfo(q_data.masters_document_path)}} >
                                                                {
                                                                    q_data != null ?

                                                                    <img src={q_data.masters_document_path} style={{width: '75px'}} />

                                                                    :  null
                                                                }
                                                        </a>        
                                                                
                                                        </div>
                                                        
                                                    </div>        
                                                </div> 
                                            </>    

                                            : null
                                    : null        
                                }              

                                


                            </div>
                        

                        </div>
                                    
                    <div className='col-md-12'>
                        <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                            <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseViewModel}>Close</button>
                        </div>
                    </div>
                </div>
        
            </div>
        </Modal>


        <Modal isOpen={modalDocumentViewOpen} onRequestClose={ClosePhotoModel} contentLabel="Document Modal" >
                <div className='modal_header'>
                Document View <button onClick={ClosePhotoModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                    <div className='row'>
                        <div style={{ marginTop: 20, alignSelf: "center", width: '100%', display: 'flex', flexDirection: 'column'}}>
                            {
                                document_url != null ?
                                <img src={document_url} />
                                : null
                            }

                        </div>
                                        
                        <div className='col-md-12'>
                            <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={ClosePhotoModel}>Close</button>
                            </div>
                        </div>
                    </div>
            
                </div>
        </Modal>

     </div>
    )
 }
 export default Qualification;