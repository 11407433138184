import React, { useEffect, useState} from "react";
import { BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux';
import { setUserDataAfterLogin, localstorage_TokenAdd, setUserType } from "./Store/Reducers/CommonReducer";
import './App.css';
// js
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import ScrollToTop from "./component/ScrollToTop.js";

import Initial from './auth/Initial';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import FranchiseCheckCode from './auth/FranchiseCheckCode';
import FranchiseSignup from './auth/FranchiseSignup';
import AssociateSignup from './auth/AssociateSignup';
import Layout from './pages/layout/Layout';
import PageNotFound  from './auth/PageNotFound';

//Franchise
import Dashboard_Franchise from './pages/Franchise/dashboard/Dasboard';
import KYCDocument_Franchise from './pages/Franchise/dashboard/KYCDocument/KYCDocument';
import BankDocument_Franchise from './pages/Franchise/dashboard/BankDocuments/BankDocument';
import Profile_Franchise from './pages/Franchise/Account/Profile/Profile';
import ChangePassword_Franchise from './pages/Franchise/Account/ChangePassword/ChangePassword';
import MyIncentive_Franchise from './pages/Franchise/Income/MyIncentive/MyIncentive';
import AccountStatement_Franchise from './pages/Franchise/Income/AccountStatement/AccountStatement';
import IncomeAssociate_Franchise from './pages/Franchise/Income/IncomeAssociate/IncomeAssociate';
import IncomeFranchise_Franchise from './pages/Franchise/Income/IncomeFranchise/IncomeFranchise';
import Mypurchase_Franchise from './pages/Franchise/MyPurchase/mypurchaseFranchise';
import Report_Franchise from './pages/Franchise/Report/Report';
import Sale_Franchise from './pages/Franchise/Sale/saleFranchise/Sale';
import Sale_Associate from './pages/Franchise/Sale/saleAssociate/Sale';
import SaleList_Franchise from './pages/Franchise/SaleList/SaleList';
import Support_Franchise from './pages/Franchise/Support/support_franchise';


//ASSOCIATE
import Dashboard_Associate from './pages/Associate/dashboard/Dasboard';
import Qualification from './pages/Associate/dashboard/Qualification/Qualification';
import Notification from './pages/Associate/dashboard/Notification/Notification';
import KYCDocument_Associate from './pages/Associate/dashboard/KYCDocument/KYCDocument';
import BankDocument_Associate from './pages/Associate/dashboard/BankDocuments/BankDocument';
import IdCard_Associate from './pages/Associate/dashboard/IdCard/IdCard';

import MyDocuments from './pages/Associate/dashboard/MyDocument/MyDocuments';
import ProductList from './pages/Associate/dashboard/Product/ProductList';
import StoreLocate from './pages/Associate/dashboard/StoreLocate/StoreLocate';
import MyPurchase from './pages/Associate/dashboard/MyPurchase/MyPurchase';
import OrderBooking from './pages/Associate/dashboard/OrderBooking/OrderBooking';
import ProductOffers from './pages/Associate/dashboard/ProductOffers/ProductOffers';
import Register from './pages/Associate/dashboard/Register/Register';
import MyDownline from './pages/Associate/downline/MyDownline/MyDownline';
import DeriectDownline from './pages/Associate/downline/deriectdownline/DerictDownline';
import DownlineSearch from './pages/Associate/downline/DownlineSearch/DownlineSearch';
import DirectSupport from './pages/Associate/downline/DirectSupport/DirectSupport';
import MyUpline from './pages/Associate/downline/MyUpline/MyUpline';
import MyPcs from './pages/Associate/downline/MyPcs/MyPcs';
import DirectReferrals from './pages/Associate/downline/DirectReferrals/DirectReferrals';
import MakeTransfer from './pages/Associate/BVTransfer/MakeTransfer/MakeTransfer';
import Summary from './pages/Associate/BVTransfer/Summary/Summary';
import Product from './pages/Associate/Product/Product';
import MyIncentive from './pages/Associate/Income/MyIncentive/MyIncentive';
import AccountStatement from './pages/Associate/Income/AccountStatement/AccountStatement';
import EWalletWithdraws from './pages/Associate/E-Wallet/E-WalletWithdraws/EWalletWithdraws';
import EWalletPayment from './pages/Associate/E-Wallet/E-WalletPayment/EWalletPayment';
import Profile from './pages/Associate/Account/Profile/Profile';
import ChangePassword from './pages/Associate/Account/ChangePassword/ChangePassword';
import Support from './pages/Associate/Support/support';
import Report from './pages/Associate/Report/Report';

function App() {
  const dispatch = useDispatch();
  const {userType, userData} = useSelector(state => state.common);

  useEffect(() => {
      if(sessionStorage.getItem("userId") != null){
        dispatch(setUserDataAfterLogin(JSON.parse(sessionStorage.getItem("userData"))));
        dispatch(localstorage_TokenAdd(sessionStorage.getItem("userToken")));
        dispatch(setUserType(JSON.parse(sessionStorage.getItem("userData")).role_name));
      }
      
  	}, []);


  return (
    <BrowserRouter >
          <ScrollToTop>
            <Routes >
              <Route path="/" element={<Initial />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
              <Route path="/ResetPassword" element={<ResetPassword />}></Route>
              <Route path="/franchise/signupcode" element={<FranchiseCheckCode />}></Route>
              <Route path="/franchise/signup" element={<FranchiseSignup />}></Route>
              <Route path="/associate/signup" element={<AssociateSignup />}></Route>
              {  userType == 'FRANCISE' ?
                  <Route path="/franchise" element={<Layout />}>
                    <Route path="dashboard" element={<Dashboard_Franchise />} />
                    <Route path="kycocuments" element={<KYCDocument_Franchise />} />
                    <Route path="bankdocuments" element={<BankDocument_Franchise />} />
                    {
                       userData.level == 1 ?
                       <>
                       <Route path="sale-associate" element={<Sale_Associate />} />
                       <Route path="sale-franchise" element={<Sale_Franchise />} />
                       </>
                       :
                       <Route path="sale-associate" element={<Sale_Associate />} />
                    }
                   
                    <Route path="salelist" element={<SaleList_Franchise />} />
                    <Route path="income-associate" element={<IncomeAssociate_Franchise />} />
                    <Route path="income-franchise" element={<IncomeFranchise_Franchise />} />
                    <Route path="incentive" element={<MyIncentive_Franchise />} />
                    <Route path="accountstatement" element={<AccountStatement_Franchise />} />
                    <Route path="mypurchase" element={<Mypurchase_Franchise />} />
                    <Route path="report" element={<Report_Franchise />} />
                    <Route path="profile" element={<Profile_Franchise />} />
                    <Route path="changepassword" element={<ChangePassword_Franchise />} />
                    <Route path="support" element={<Support_Franchise />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Route>

                  : userType == 'CLIENT' ?

                    <Route path="/associate" element={<Layout />}>
                      <Route path="dashboard" element={<Dashboard_Associate />} />
                      <Route path="qualifications" element={<Qualification />} />
                      <Route path="notifications" element={<Notification />} />
                      <Route path="kycocuments" element={<KYCDocument_Associate />} />
                      <Route path="bankdocuments" element={<BankDocument_Associate />} />
                      <Route path="idcard" element={<IdCard_Associate />} />
                      <Route path="mydocuments" element={<MyDocuments />} />
                      <Route path="productlist" element={<ProductList />} />
                      <Route path="storelocator" element={<StoreLocate />} />
                      <Route path="mypurchase" element={<MyPurchase />} />
                      <Route path="orderbooking" element={<OrderBooking />} />
                      <Route path="productoffers" element={<ProductOffers />} />
                      <Route path="register" element={<Register />} />
                      <Route path="mydownline" element={<MyDownline />} />
                      <Route path="downline" element={<DeriectDownline />} />
                      <Route path="directdownline" element={<DeriectDownline />} />
                      <Route path="downlinesearch" element={<DownlineSearch />} />
                      <Route path="downlinesupport" element={<DirectSupport />} />
                      <Route path="myupline" element={<MyUpline />} />
                      <Route path="mypcs" element={<MyPcs />} />
                      <Route path="directreferrals" element={<DirectReferrals />} />
                      <Route path="bv-transfer" element={<MakeTransfer />} />
                      <Route path="summary" element={<Summary />} />
                      <Route path="product" element={<Product />} />
                      <Route path="incentive" element={<MyIncentive />} />
                      <Route path="accountstatement" element={<AccountStatement />} />
                      <Route path="wallet" element={<EWalletWithdraws />} />
                      <Route path="walletpayment" element={<EWalletPayment />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="changepassword" element={<ChangePassword />} />
                      <Route path="support" element={<Support />} />
                      <Route path="report" element={<Report />} />
                      <Route path="*" element={<PageNotFound />} />
                    </Route>
                    : null
                  }
            </Routes> 
          </ScrollToTop>
      </BrowserRouter>    
  );
}

export default App;
