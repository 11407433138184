import './BankDocument.css';
import upload_image from "../../../../assets/img/upload.png";
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import Modal from 'react-modal';

const BankDocument_Associate = () =>{

    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;

    const dispatch = useDispatch();

    const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [bank_proof_image, setPhotoImage] = useState("");
    const [bank_proof_image_pre, setPhotoImagePre] = useState("");
    const [account_holder_name, setAccountHolderName] = useState("");
    const [bank_name, setBankName] = useState("");
    const [branch_name, setBranchName] = useState("");
    const [account_no, setAccountNo] = useState("");
    const [ifsc_code, setIFSC] = useState("");

    const [modalDocumentViewOpen, setDocumentViewModel] = useState(false);

    const [isActive, setisActive] =useState(false);

    const openModal = () =>{
        setisActive(true)
    }
    const closeModal = () =>{
     setisActive(false)
    }

    const pagename = useCallback(() => {
        dispatch(setPageName('Bank Document'));
    },[pageName])

    useEffect(() => {
        pagename();
        fetchUserData();
    }, []);

     //alert start
  
     const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
    
    function onSelectFile(event) {
      event.preventDefault();
      if (event.target.files && event.target.files[0]) {
        let file = event.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/png'){
            const reader = new window.FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
              //upload_profile(reader.result);
      
             // console.log(reader.result);
                setPhotoImage(event.target.files[0]);
                setPhotoImagePre(reader.result);
           
              //console.log(event.target.files[0]);
            };
        }else{
            const all_error_msg = [];
            all_error_msg.push("File type support only 'jpeg/png'");
            onShowAlert("error", all_error_msg);
        }
        
      }
    }


    const openModelByType = ()=> {
        setisActive(true)
    }

    const SubmitAction = (event) => {

        event.preventDefault();

        let flg = true;
        const all_error_msg = [];

        if (account_holder_name.search(/\S/) == -1) {
            all_error_msg.push("Enter Account Holder Name");
      
            if (flg === true) {
              flg = false;
            }
          }

          if (bank_name.search(/\S/) == -1) {
            all_error_msg.push("Enter bank name");
      
            if (flg === true) {
              flg = false;
            }
          }

          if (branch_name.search(/\S/) == -1) {
            all_error_msg.push("Enter branch name");
      
            if (flg === true) {
              flg = false;
            }
          }

          if (account_no.search(/\S/) == -1) {
            all_error_msg.push("Enter account number");
      
            if (flg === true) {
              flg = false;
            }
          }

          if (ifsc_code.search(/\S/) == -1) {
            all_error_msg.push("Enter IFSC Code");
      
            if (flg === true) {
              flg = false;
            }
          }

          if(bank_proof_image == ''){
            all_error_msg.push("Please upload account proof document");
            if(flg == true){flg = false;}
          }

        if (flg === true) {
            setLoading(true);

            let post_data = {
                account_holder_name: account_holder_name,
                bank_name: bank_name,
                branch_name: branch_name,
                account_no: account_no,
                ifsc_code: ifsc_code,
                bank_proof: bank_proof_image
              };
      
           postWithTokenFormData(API_URL, 'user/bank-account-upload', post_data, userToken).then((result) => {
            if(result.status === true){

                    setAccountHolderName("");
                    setBankName("");
                    setBranchName("");
                    setAccountNo("");
                    setIFSC("");

                    setPhotoImage("");
                    setPhotoImagePre("");

                    fetchUserData();
                }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }else{
            onShowAlert("error", all_error_msg);
            
        } 
    }

    const fetchUserData = () => {
      
        postWithToken(API_URL, 'get-user-details', {}, userToken).then((result) => {
           if(result.status === true){
                setLoading(false);
                setisActive(false);
                dispatch(setUserDataAfterLogin(result.data));
                sessionStorage.setItem("userData",JSON.stringify(result.data));
            }else{
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const CloseViewModel = () => {
        setDocumentViewModel(false);
    }

    const openDocumentInfo = (url) => {
        setDocumentViewModel(true);
    }

    return(
        <div className='notifications'>
        <CustomAlert
          header={"Bank Document"}
          btnText={"Accept"}
          text={alert.text}
          type={alert.type}
          show={alert.show}
          onClosePress={onCloseAlert}
          pressCloseOnOutsideClick={true}
          alertStyles={{}}
          headerStyles={{}}
          textStyles={{}}
          buttonStyles={{}}
          />  
          {
            loading === true ?
            <div className="activityLoader">
                  <GridLoader
                    color={color}
                    loading={loading}
                    cssOverride={override}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    Loader="GridLoader"
                  /> 
           </div>     
           : null
          }
    <div className='row'>
        <div  className='col-md-4'>
            <div className='card'>
            <div className='actioncard'>
                    {
                        userData.bank_proof_path == null ?
                        <button type='button' className='openModal' onClick={openModelByType}><i className="fa fa-file-text-o" aria-hidden="true"></i> Upload</button>
                        : null
                    }
                    {
                        userData.bank_proof_path != null ?
                            <a onClick={() => {openDocumentInfo()}} className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></a>
                        : null
                    }  
                </div>
                <h2>Bank Document</h2>
                <span>{userData.is_bank_verified == 1 ? 'Verified by at ' + Moment(userData.bank_verified_at).format('DD/MM/YYYY') + ' - ' + Moment(userData.bank_verified_at).format('hh:mm:ss A') : null}</span>
                <p>You can provide following documents</p>
                <p>Cancelled Cheque</p>
                <p>Bank Passbook</p>
                <p>Bank Certification</p>
                {
                    userData.bank_proof_path != null ?
                        userData.is_bank_verified == 1 ?
                        <div className='note'>
                            Your Bank Info is Verified
                        </div>
                        : userData.is_bank_verified == 0 ?

                        <div className='note_pendding' >
                            Your Bank Info is Pendding for Verify
                        </div>
                        : null
                     :null   
                }
               
            </div>
        </div>
    </div>
    <div className={`modal_custom xlm ${isActive ? "open":""}`} id="modal">
        <div className='modalBody_custom'>
            <h3>Submit Bank Infomation <Link onClick={closeModal}><i class="fa fa-times" aria-hidden="true"></i></Link></h3>
              
             <div className='uploadDocument' style={{marginBottom: 20}}>
                <div className='uploadDocument-img'>
                              {bank_proof_image_pre != '' ? 
                              <img src={bank_proof_image_pre} />
                              :
                              <img src={upload_image} />
                            }
                    
                    <input type="file" accept="image/png, image/jpeg" onChange={(e) => onSelectFile(e)} />
                </div>
              
             </div>
             <form onSubmit={SubmitAction}>
                <div className='row'>
                    <div className='col-12'>
                     <div className='form-group'>
                      <input type='text' placeholder='Account Holder Name' onChange={(event) => setAccountHolderName(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-12'>
                     <div className='form-group'>
                      <input type='text' placeholder='Bank Name' onChange={(event) => setBankName(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-12'>
                     <div className='form-group'>
                      <input type='text' placeholder='Branch Name' onChange={(event) => setBranchName(event.target.value)}/>
                     </div>
                    </div>
                    <div className='col-6'>
                     <div className='form-group'>
                      <input type='text' placeholder='Account Number' onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                  return true;
                          }else{event.preventDefault();}}} onChange={(event) => setAccountNo(event.target.value)}/>
                     </div>
                    </div> <div className='col-6'>
                     <div className='form-group'>
                      <input type='text' placeholder='IFSC Code' onChange={(event) => setIFSC(event.target.value)}/>
                     </div>
                   
                    </div>
                </div>
                <div className='btngrp' style={{display: 'flex', justifyContent: 'center'}}>
                    <button type="button" onClick={closeModal} className="cancelBtn">Cancel</button>
                    <button type='submit' className="sucessBtn">Submit</button>
                </div>
             </form>
             
        </div>
    </div>

    
    <Modal isOpen={modalDocumentViewOpen} onRequestClose={CloseViewModel} contentLabel="Document Modal" >
        <div className='modal_header'>
         Bank Infomation View <button onClick={CloseViewModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
        </button>
        </div>
        <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
            <div className='row'>
                <div className='col-md-12' >

                        <div className="row" >
                            <div className="col-md-12" >
                                    <div className="photoUpload_bank" style={{alignItems: 'center', justifyContent: 'center'}}>
                                        <div className="uploadePic_bank">
                                                {
                                                    userData.bank_proof_path != null ?

                                                    <img src={userData.bank_proof_path} />

                                                    :  null
                                                }
                                                
                                        </div>
                                        
                                    </div>
                            </div>
                            
                            <div className="col-md-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Account Holder Name : </label>
                                    <span style={{display: 'flex', flex: 1}}>{userData.account_holder_name}</span> 
                                    </div>
                            </div> 

                            <div className="col-md-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Bank Name : </label>
                                    <span style={{display: 'flex', flex: 1}}>{userData.bank_name}</span> 
                                    </div>
                            </div> 

                            <div className="col-md-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Branch Name : </label>
                                    <span style={{display: 'flex', flex: 1}}>{userData.branch_name}</span> 
                                    </div>
                            </div> 


                            <div className="col-md-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Account Number : </label>
                                    <span style={{display: 'flex', flex: 1}}>{userData.account_no}</span> 
                                    </div>
                            </div>

                            <div className="col-md-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <label style={{fontWeight: 700, display: 'flex', flex: 1}}>IFSC Code : </label>
                                    <span style={{display: 'flex', flex: 1}}>{userData.ifsc_code}</span> 
                                    </div>
                            </div>


                        </div>
                       

                    </div>
                                
                <div className='col-md-12'>
                    <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                        <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseViewModel}>Close</button>
                    </div>
                </div>
            </div>
    
        </div>
</Modal>



 </div>
    )
 }
 export default BankDocument_Associate;