import './StoreLocate.css';
import location from'../../../../assets/img/location.png'
import phone from'../../../../assets/img/phone.png'

import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';



const StoreLocate = () =>{

    Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;
   const dispatch = useDispatch();

   const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [locate, setLocate] = useState({IsLoading: true, list: [], total_count: 0});
   const [list_limit, setListLimit] = useState(20);
   const [page_selected, setPageSelected] = useState(null);

   const [state, setState] = useState("");
   const [district, setDistrict] = useState("");

   const [state_list, setStateList] = useState([]);
   const [district_list, setDistrictList] = useState([]);

  useEffect(() => {
      dispatch(setPageName('Store Locate'));
      stateList();
  }, []);

  useEffect(() => {
    if(userToken != ''){
        LocateFetch(0,list_limit); 
    }
  }, [userToken]); 

  const stateList = () => {
    postWithToken(API_URL, 'state-list', {}, userToken).then((result) => {
       console.log(result);
         if(result.status === true){
              setStateList(result.data);
           }else{
             
           }
       })
       .catch((error) => {
         console.log("Error : ", error);
       });
   }

   const DistrictList = (state_id) => {

    postWithToken(API_URL, 'district-list', {state_id: state_id}, userToken).then((result) => {
       console.log(result);
         if(result.status === true){
          setDistrictList(result.data);
           }else{
             
           }
       })
       .catch((error) => {
         console.log("Error : ", error);
       });
   }

   const LocateFetch = (offset, limit) => {
    let post_data = {
        offset: offset, 
        limit: limit,
        search_state: state,
        search_district: district
    };

      setLoading(true);

      postWithToken(API_URL, "user/store-locators", post_data, userToken).then((result) => {
        console.log(result);
          if(result.status === true){
            setLoading(false);
            setLocate({IsLoading : false, list: result.data, total_count: result.total_count});
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const searchStore = () => {
    LocateFetch(0,list_limit); 
  }

   //alert start
  
   const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
    })

    function onCloseAlert(){
        setAlert({
        show: false,
        type: null,
        text: [],
        })
    }

    function onShowAlert(type,msg){
        setAlert({
        type: type,
        text: msg,
        show: true
        })
    }

    //alert end 

       const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(locate.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % locate.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              LocateFetch(newOffset, list_limit);
              setItemOffset(newOffset);
            };
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }

    return(
     <div className='storeLocate'>
        <CustomAlert
              header={"Direct Downline"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
              <div className='row'>
                  <div className='col-md-12'>
                      <div className='card'>
                          <form>
                              <label>Search Store</label>
                              <div className='form-group'>
                                  <select value={state} onChange={(event) => {setState(event.target.value); DistrictList(event.target.value);}}>
                                      <option value="">Select State</option>
                                      {
                                          state_list.length > 0 ?
                                          state_list.map((item, index) => {

                                            return(
                                                <option key={'state_' + index} value={item.id}>{item.name}</option> 
                                            )

                                          })
                                          : null
                                      }
                                </select>
                              </div>
                              <div className='form-group'>
                                  <select value={district} onChange={(event) => {setDistrict(event.target.value)}}>
                                      <option value="">Select District</option>
                                      {
                                          district_list.length > 0 ?
                                          district_list.map((item, index) => {

                                            return(
                                                <option key={'dis_' + index} value={item.id}>{item.district_name}</option> 
                                            )

                                          })
                                          : null
                                      }
                                  </select>
                              </div>
                              <button type='button' className='seachbtn' onClick={() =>  searchStore()} ><i class="fa fa-search" aria-hidden="true"></i> Search Now</button>
                          </form>
                      </div>
                  </div>

                  {
                  locate.IsLoading === false && locate.list.length > 0 ?
                  
                  locate.list.map((item, index) => {

                      return(
                          <div  className='col-md-6'>
                              <div className='card'>
                                  <div className='phone'>
                                      <Link>
                                      <img src={phone}/>
                                      <span>
                                      <label>Contact No</label>
                                      <h5>{item.phone}</h5>
                                      </span>
                                      </Link>
                                  
                                  </div>
                                  <h2>{item.name}</h2>
                                  {/*<p><label>Consignee Sales Agent</label> <small>(Code 68998)</small></p> */}
                                  <p><label>State :</label> {item.state.name}</p>
                                  <p><label>District :</label> {item.district}</p>
                                  <p className='flxp'><img src={location}/> {item.address != null ? item.address : null} {item.address_1 != null ? ", " + item.address_1 : null} {item.address_2 != null ? ", " + item.address_2 : null}</p>
                              </div>
                          </div>
                    );
                  })

                  : locate.IsLoading === false && locate.list.length == 0 ?
                  <div className='col-md-12'>
                    <div className="row">
                      <div style={{textAlign: "center", marginLeft: '40%',marginTop: '10%'}}>
                        <div style={{textAlign: "center"}}><b>No Record Found</b></div>
                      </div>
                    </div>
                </div>   

                : locate.IsLoading === true ?
                      <>
                          <div  className='col-md-6'>
                              <div className='card'>
                                <Skeleton height={200} />
                              </div>
                          </div>
                          <div  className='col-md-6'>
                              <div className='card'>
                                <Skeleton height={200} />
                              </div>
                          </div>
                      </>

                : null }      
        
          
              </div>

        {
            locate.IsLoading === false && locate.list.length > 0 ?
                <div className="pagination_div">
                        <PaginatedItems itemsPerPage={list_limit} />
                 </div>
            :
            null
        }     
     </div>
    )
 }
 export default StoreLocate;