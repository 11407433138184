import './Login.css';
import logo from '../assets/img/logo.png';
import mail from '../assets/img/mail.png';
import lock from '../assets/img/lock.png'
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";
import { setUserDataWithToken, setUserType } from "../Store/Reducers/CommonReducer";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const Login = () =>{
    const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //alert start

  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setEmail("");
    setPassword("");
  }

  const loginAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if (email.trim() === '') {
      all_error_msg.push("Enter your user Id");

      if (flg === true) {
        flg = false;
      }
    }
    
    if (password.trim() === '') {
      all_error_msg.push("Enter your password");
      if (flg === true) {
        flg = false;
      }
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'login', {email: email, password: password}).then((result) => {
          if(result.status === true){
                setLoading(false);
                dispatch(setUserDataWithToken(result));
                dispatch(setUserType(result.data.role_name));
                sessionStorage.setItem("userData",JSON.stringify(result.data));
                sessionStorage.setItem("userId", result.data.id);
                sessionStorage.setItem("userToken", result.token);
                resetForm();
                if(result.data.role_name == 'FRANCISE'){
                  navigate("/franchise/dashboard");
                }else if(result.data.role_name == 'CLIENT'){
                  navigate("/associate/dashboard");
                }
                   
            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

   
   return(
    <div className="authapper">
        <div className="authapper-body">
        <CustomAlert
              header={"Login"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className="authapper-left">
            <div className='logo'>
                <img src={logo}/>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and 
            typesetting industry. Lorem Ipsum has been the industry's 
            standard dummy text ever since the 1500s</p>
            <p><a href='mailto:info@yoursitename.com'><i className="fa fa-envelope" aria-hidden="true"></i> info@yoursitename.com</a></p>
            <p><a href='tel:+00 00000000'><i className="fa fa-phone" aria-hidden="true"></i> +00 00000000</a></p>
            <span className='copyright'>Veda Harbal Copyright © 2024, Version 0.0.1</span>
         </div>
         <div className="authapper-right">
             <h1>welcome Back</h1>
             <h2>Login</h2>
             <form onSubmit={loginAction}>
                <div className='form-group'>
                <img src={mail}/>
                    <input type='text' placeholder='User Id' onChange={(event) => setEmail(event.target.value)}/>
                </div>
                <div className='form-group'>
                <img src={lock}/>
                    <input type='password' placeholder='Password' onChange={(event) => setPassword(event.target.value)}/>
                </div>
                <button type='submit' className='themeBtn'>LOGIN</button>
                <div className='formbtm'>
                <Link to="/ForgotPassword">forgot my password</Link>
                 { /*<p>Direct Seller Login |Preferred Customer Login </p>
                 <Link>User Login</Link>*/ }
                 <p>Don't have an account ? <Link to="/franchise/signupcode">Franchise</Link> | <Link to="/associate/signup">Associate</Link> </p>
                </div>
             </form>
         </div>
         </div>
    </div>
   )
}
export default Login;