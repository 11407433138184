import './Signup.css';
import logo from '../assets/img/logo.png';
import mail from '../assets/img/mail.png';
import lock from '../assets/img/lock.png';
import user from '../assets/img/users.png';
import call from '../assets/img/call.png';

import React, {useEffect, useState, CSSProperties, useRef} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import OtpInput from 'react-otp-input';

const ResetPassword = () =>{
    const location = useLocation();
    const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

    //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setPassword("");
    setConfirmPassword("");
  
  }

  const ResetAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];
        
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
	  const lowercaseRegExp   = /(?=.*?[a-z])/;
	  const digitsRegExp      = /(?=.*?[0-9])/;
	  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
	  const minLengthRegExp   = /.{8,}/;
    
    if (password.search(/\S/) == -1) {
      all_error_msg.push("Enter your password");
      if (flg === true) {
        flg = false;
      }
    }else if(password.search(/\S/) != -1){
      const passwordLength =      password.length;
      const uppercasePassword =   uppercaseRegExp.test(password);
      const lowercasePassword =   lowercaseRegExp.test(password);
      const digitsPassword =      digitsRegExp.test(password);
      const specialCharPassword = specialCharRegExp.test(password);
      const minLengthPassword =   minLengthRegExp.test(password);
  
      let errMsg ="";
      let flgg = true;
      if(!uppercasePassword){
          errMsg= "password at least one uppercase";
          flgg = false;
      }else if(!lowercasePassword){
          errMsg= "password at least one lowercase";
          flgg = false;
      }else if(!digitsPassword){
          errMsg= "password at least one digit";
          flgg = false;
      }else if(!specialCharPassword){
          errMsg= "password at least one special characters";
          flgg = false;
      }else if(!minLengthPassword){
          errMsg= "password at least minimum eight characters";
          flgg = false;
      }else{
        errMsg="";
      }
  
      if(flgg == false){
        all_error_msg.push(errMsg);
        if(flg == true){flg = false;}
      }
    }

    if (confirm_password.search(/\S/) == -1) {
      all_error_msg.push("please confirm password");
      if (flg === true) {
        flg = false;
      }
    }

    if(password != confirm_password){
      all_error_msg.push("Mismatch confirm password");
      if(flg == true){flg = false;}
      
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'reset-password', {email: location.state.email, password: password, password_confirmation: confirm_password}).then((result) => {
          if(result.success === true){
                setLoading(false);

                all_error_msg.push(result.message);
                onShowAlert("success", all_error_msg);
                            
                setTimeout(function() {onCloseAlert(); }, 1500);

                setTimeout(() => {
                  navigate("/login");
                }, 2000);

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

  

 return(
    <div className="authapper">
    <div className="authapper-body">
    <CustomAlert
              header={"Reset Password"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
              


    <div className="authapper-left">
        <div className='logo'>
            <img src={logo}/>
        </div>
        <p>Lorem Ipsum is simply dummy text of the printing and 
        typesetting industry. Lorem Ipsum has been the industry's 
        standard dummy text ever since the 1500s</p>
        <p><a href='mailto:info@yoursitename.com'><i className="fa fa-envelope" aria-hidden="true"></i> info@yoursitename.com</a></p>
        <p><a href='tel:+00 00000000'><i className="fa fa-phone" aria-hidden="true"></i> +00 00000000</a></p>
        <span className='copyright'>Veda Harbal Copyright © 2024, Version 0.0.1</span>
     </div>
     <div className="authapper-right">
         <h2>Reset Password</h2>
         <form onSubmit={ResetAction}>
                               
            <div className='form-group'>
                <img src={lock}/>
                <input type='password' placeholder='Password'  onChange={(event) => setPassword(event.target.value)}/>
            </div>
            <div className='form-group'>
                <img src={lock}/>
                <input type='password' placeholder='Confirm Password'  onChange={(event) => setConfirmPassword(event.target.value)}/>
            </div>
            <button type='submit' className='themeBtn'>Reset Password</button>
            <div className='formbtm'>
             <p>Already have an account ? <Link to={'/'}>LOGIN</Link></p>
            </div>
         </form>
     </div>
     </div>
   

    </div>

 )
}
export default ResetPassword;