import   './MyIncentive.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';


const MyIncentive_Franchise = () =>{

    Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);

   const [from_date, setFromDate] = useState("");
   const [to_date, setToDate] = useState("");

   const pagename = useCallback(() => {
      dispatch(setPageName('Income'));
     },[pageName])

    useEffect(() => {
        pagename();
    }, []);

    //alert start
  
   const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end 



 $(function(){
    var dtToday = new Date();
  
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
  
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
  
    var maxDate = year + '-' + month + '-' + day;    
    $('#from_date').attr('max', maxDate);
    $('#to_date').attr('max', maxDate);
  });

  const setDate = (val, type) => {

    console.log(val + "-" + type);

    const all_error_msg = [];

    if(type == "from"){

        if(to_date != ""){
            if(Date.parse(val) > Date.parse(to_date)){
                all_error_msg.push("From date must be less then to date");
               onShowAlert("error", all_error_msg);
            }else{
                setFromDate(val);
            }
        }else{
            setFromDate(val);
        }

    }else if(type == "to"){
        if(from_date != ""){
            if(Date.parse(from_date) > Date.parse(val)){
                all_error_msg.push("To date must be greater than from date");
                onShowAlert("error", all_error_msg);
            }else{
                setToDate(val);
            }
        }else{
            setToDate(val);
        }
    }

  }

  const clear_date = () => {
    setFromDate("");
    setToDate("");
  }

    return(
     <div className='myincentive'>

         <CustomAlert
               header={"Profile Update"}
               btnText={"Accept"}
               text={alert.text}
               type={alert.type}
               show={alert.show}
               onClosePress={onCloseAlert}
               pressCloseOnOutsideClick={true}
               alertStyles={{}}
               headerStyles={{}}
               textStyles={{}}
               buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>

            <div  className='col-md-12'>
                <div className="row">
                   <div className='col-md-2'>
                     <div className='form-group'>
                        <input type='date' style={{height: 45, paddingLeft: 10}} id="from_date" placeholder='From Date' value={from_date} onChange={(event) => {setDate(event.target.value,'from')}}/>
                     </div>
                    </div>
                    <div className='col-md-2'>
                     <div className='form-group'>
                        <input type='date' style={{height: 45, paddingLeft: 10}} id="date_of_birth" placeholder='To Date' value={to_date} onChange={(event) => {setDate(event.target.value,'to')}}/>
                     </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                             <button type='button' className='sucessBtn' onClick={() => {clear_date()}} >Filter</button>
                             <button type='button' className='cancelBtn' onClick={() => {clear_date()}}>Clear</button>
                        </div>
                    </div>
                </div>

            </div>

            <div  className='col-md-12'>
                <div className='card'>
                  <table className='table'>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Closing</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Feb 2024</td>
                            <td>01/03/2024</td>
                            <td>154.05</td>
                            <td><Link><i class="fa fa-eye" aria-hidden="true"></i> Statement</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> Summary</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> View LSB Detail</Link></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Feb 2024</td>
                            <td>01/03/2024</td>
                            <td>154.05</td>
                            <td><Link><i class="fa fa-eye" aria-hidden="true"></i> Statement</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> Summary</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> View LSB Detail</Link></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Feb 2024</td>
                            <td>01/03/2024</td>
                            <td>154.05</td>
                            <td><Link><i class="fa fa-eye" aria-hidden="true"></i> Statement</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> Summary</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> View LSB Detail</Link></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Feb 2024</td>
                            <td>01/03/2024</td>
                            <td>154.05</td>
                            <td><Link><i class="fa fa-eye" aria-hidden="true"></i> Statement</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> Summary</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> View LSB Detail</Link></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Feb 2024</td>
                            <td>01/03/2024</td>
                            <td>154.05</td>
                            <td><Link><i class="fa fa-eye" aria-hidden="true"></i> Statement</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> Summary</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> View LSB Detail</Link></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Feb 2024</td>
                            <td>01/03/2024</td>
                            <td>154.05</td>
                            <td><Link><i class="fa fa-eye" aria-hidden="true"></i> Statement</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> Summary</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> View LSB Detail</Link></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Feb 2024</td>
                            <td>01/03/2024</td>
                            <td>154.05</td>
                            <td><Link><i class="fa fa-eye" aria-hidden="true"></i> Statement</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> Summary</Link> <Link><i class="fa fa-eye" aria-hidden="true"></i> View LSB Detail</Link></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default MyIncentive_Franchise;