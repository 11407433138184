import './KYCDocument.css';
import upload_image from "../../../../assets/img/upload.png";
import React, {useEffect, useState, useCallback, useRef} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin } from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import Modal from 'react-modal';

const KYCDocument_Franchise = () =>{
    Moment.locale('en');
    const ref = useRef();
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;

    const dispatch = useDispatch();

    const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [photo_image, setPhotoImage] = useState("");
    const [photo_image_pre, setPhotoImagePre] = useState("");
    const [identity_proof_image, setIdentityProofImage] = useState("");
    const [identity_proof_image_pre, setIdentityProofImagePre] = useState("");
    const [address_proof_image, setAddressProofImage] = useState("");
    const [address_proof_image_pre, setAddressProofImagePre] = useState("");
    const [upload_type, setUploadType] = useState("");

    const [modalDocumentViewOpen, setDocumentViewModel] = useState(false);
    const [document_url,setDocumentUrl] = useState(null);

    const [isActive, setisActive] =useState(false)
    
    const closeModal = () =>{
     setisActive(false)
    }

    const pagename = useCallback(() => {
        dispatch(setPageName('KYC Document'));
    },[pageName])

    useEffect(() => {
        pagename();
        fetchUserData();
    }, []);

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
    
    function onSelectFile(event,type) {
      event.preventDefault();
      if (event.target.files && event.target.files[0]) {
        let file = event.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/png'){
            const reader = new window.FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
              //upload_profile(reader.result);
      
             // console.log(reader.result);
             if(type == 'photo'){
                setPhotoImage(event.target.files[0]);
                setPhotoImagePre(reader.result);
  
             }else if(type == 'identity'){
                setIdentityProofImage(event.target.files[0]);
                setIdentityProofImagePre(reader.result);

             }else if(type == 'address'){
                setAddressProofImage(event.target.files[0]);
                setAddressProofImagePre(reader.result);

             }
              //console.log(event.target.files[0]);
            };
        }else{
            const all_error_msg = [];
            all_error_msg.push("File type support only 'jpeg/png'");
            onShowAlert("error", all_error_msg);
        }
        
      }
    }


    const openModelByType = (type)=> {
        setUploadType(type);
        setisActive(true)
    }

    function uploadDocument(){

        let flg = true;
        const all_error_msg = [];

        let url = '';
        let post_data = {};

        if(upload_type == 'photo'){
            if(photo_image == ''){
                all_error_msg.push("Please upload your photo");
                if(flg == true){flg = false;}
            }

            post_data = {
                profile_image: photo_image,
              };

            url = 'francise/photo-upload';  

        }else if(upload_type == 'identity'){
            if(identity_proof_image == ''){
                all_error_msg.push("Please upload your identity proof");
                if(flg == true){flg = false;}
            }

            post_data = {
                id_proof: identity_proof_image,
              };

              url = 'francise/id-proof-upload';  

        }else if(upload_type == 'address'){
            if(address_proof_image == ''){
                all_error_msg.push("Please upload your address proof");
                if(flg == true){flg = false;}
            }

            post_data = {
                address_proof: address_proof_image,
              };

              url = 'francise/address-proof-upload';   
        }

        if (flg === true) {
            setLoading(true);
            ref.current.value  = "";
           postWithTokenFormData(API_URL, url, post_data, userToken).then((result) => {
            if(result.status === true){
                    setPhotoImage("");
                    setPhotoImagePre("");
                    setIdentityProofImage("");
                    setIdentityProofImagePre("");
                    setAddressProofImage("");
                    setAddressProofImagePre("");
                    fetchUserData();
                }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }else{
            onShowAlert("error", all_error_msg);
            
        } 
    }

    const fetchUserData = () => {
      
        postWithToken(API_URL, 'get-user-details', {}, userToken).then((result) => {
           if(result.status === true){
                setLoading(false);
                setisActive(false);
                dispatch(setUserDataAfterLogin(result.data));
                sessionStorage.setItem("userData",JSON.stringify(result.data));
            }else{
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const ClosePhotoModel = () => {
        setDocumentViewModel(false);
        setDocumentUrl(null);
    }

    const openDocumentInfo = (url) => {
        setDocumentUrl(url);
        setDocumentViewModel(true);
    }

    return(
        <div className='notifications'>
            <CustomAlert
              header={"Document"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-4'>
                <div className='card'>
                    <div className='actioncard'>
                      {
                            userData.profile_image_path == null ?
                            <button type='button' className='openModal' onClick={() => {openModelByType('photo')}}><i className="fa fa-file-text-o" aria-hidden="true"></i> Upload</button>
                            : null
                        }
                        
                        {
                            userData.profile_image_path != null ?
                                <a onClick={() => {openDocumentInfo(userData.profile_image_path)}} className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></a>
                            : null
                        }    
                    </div>
                   
                    <h2>Photo</h2>
                    <span className='blockspan'>{userData.is_photo_verified == 1 ? 'Verified by at ' + Moment(userData.photo_proof_verified_at).format('DD/MM/YYYY') + ' - ' + Moment(userData.photo_proof_verified_at).format('hh:mm:ss A') : null}</span>
                    <p>Colour Passport size Photo (Max. Resolution 300px X 450px)</p>
                    <div className='note'>
                        <b>Note</b> <p>Upload JPG, PNG only, PDF file will not be accepted</p>
                        <p>After uploading, please check that image 
should be readable for verification</p>
<p>Please call Customer Care in case of any 
doubt</p>
                    </div>

                    {
                        userData.profile_image_path != null ?
                            userData.is_photo_verified == 1 ?
                            <div className='note'>
                                Your Photo is Verified
                            </div>
                            : userData.is_photo_verified == 0 ?

                            <div className='note_pendding' >
                                Your Photo is Pendding for Verify
                            </div>
                            : null
                         :null   
                    }

                </div>
            </div>
            <div  className='col-md-4'>
                <div className='card'>
                <div className='actioncard'>
                            {
                                userData.id_proof_path == null ?
                                <button type='button' className='openModal' onClick={() => {openModelByType('identity')}}><i className="fa fa-file-text-o" aria-hidden="true"></i> Upload</button>
                                : null
                            }
                         {
                            userData.id_proof_path != null ?
                                <a onClick={() => {openDocumentInfo(userData.id_proof_path)}} className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></a>
                            : null
                        }  
                    </div>
                    <h2>Identity Proof</h2>
                    <span className='blockspan'>{userData.is_id_proof_verified == 1 ? 'Verified by at ' + Moment(userData.id_proof_verified_at).format('DD/MM/YYYY') + ' - ' + Moment(userData.id_proof_verified_at).format('hh:mm:ss A') : null}</span>
                    <b>You can provide following documents</b>
                    <p>PAN Card</p>

                    {
                        userData.id_proof_path != null ?
                            userData.is_id_proof_verified == 1 ?
                            <div className='note'>
                                Your Id Proof is Verified
                            </div>
                            : userData.is_id_proof_verified == 0 ?

                            <div className='note_pendding' >
                                Your Id Proof is Pendding for Verify
                            </div>
                            : null
                         :null   
                    }

                </div>

                

            </div>
            <div  className='col-md-4'>
                <div className='card'>
                <div className='actioncard'>
                        {
                            userData.address_proof_path == null ?
                                <button type='button' className='openModal' onClick={() => {openModelByType('address')}}><i className="fa fa-file-text-o" aria-hidden="true"></i> Upload</button>
                            : null
                        }    
                        {
                            userData.address_proof_path != null ?
                                <a onClick={() => {openDocumentInfo(userData.address_proof_path)}} className='viewBtn'><i className="fa fa-eye" aria-hidden="true"></i></a>
                            : null
                        }  
                    </div>
                    <h2>Address Proof</h2>
                    <span className='blockspan'>{userData.is_address_verified == 1 ? 'Verified by at ' + Moment(userData.address_verified_at).format('DD/MM/YYYY') + ' - ' + Moment(userData.address_verified_at).format('hh:mm:ss A') : null}</span>
                    <b>You can provide following documents</b>
                    <p>Aadhar Card (Back Only)</p>
                    <p>Passport</p>
                    <p>Voter ID Card</p>
                    <p>ID Card Issued by Center/State</p>
                    <p>Permanent Driving License</p>
                    <p>Rent/Lease Agreement</p>
                    <p>Telephone/Electricity/Water Bill</p>
                    <p>Self-declaration attested by Gazetted officer</p>
                    <p>Ration Card</p>
                    <p>Flat allotment/possession letter</p>
                    <p>LIC Policy</p>
                    <p>Bank/Credit Card Statement</p>
                    <p>Job Card issued by NREGA</p>

                    {
                        userData.address_proof_path != null ?
                            userData.is_address_verified == 1 ?
                            <div className='note'>
                                Your Address Proof is Verified
                            </div>
                            : userData.is_address_verified == 0 ?

                            <div className='note_pendding' >
                                Your Address Proof is Pendding for Verify
                            </div>
                            : null
                         :null   
                    }

                </div>
               
            </div>
        </div>
        <div className={`modal_custom ${isActive ? "open":""}`} id="modal">
            <div className='modalBody_custom'>
                <h3>Upload { upload_type == 'photo' ? 'Photo' : upload_type == 'identity' ? 'Identity Proof' : upload_type == 'address' ? 'Address Proof' : null} <Link onClick={closeModal}><i class="fa fa-times" aria-hidden="true"></i></Link></h3>
                  
                 <div className='uploadDocument'>
                    <div className='uploadDocument-img'>
                        {
                            upload_type == 'photo' ?
                                photo_image_pre != '' ? 
                                <img src={photo_image_pre} />
                                :
                                <img src={upload_image} />
                            : null   
                        }
                        {
                            upload_type == 'identity' ?
                                identity_proof_image_pre != '' ? 
                                <img src={identity_proof_image_pre} />
                                :
                                <img src={upload_image} />
                            : null   
                        }
                        {
                            upload_type == 'address' ?
                                address_proof_image_pre != '' ? 
                                <img src={address_proof_image_pre} />
                                :
                                <img src={upload_image} />
                            : null   
                        }


                        <input type="file" ref={ref} accept="image/png, image/jpeg" onChange={(e) => onSelectFile(e, upload_type)} />
                    </div>
                    
                    <button type='button' className='uploadBtn' onClick={() => {uploadDocument()}} >Submit</button>
                 </div>
            </div>
        </div>




        <Modal isOpen={modalDocumentViewOpen} onRequestClose={ClosePhotoModel} contentLabel="Document Modal" >
            <div className='modal_header'>
             Document View <button onClick={ClosePhotoModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
            </button>
            </div>
            <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                <div className='row'>
                    <div style={{ marginTop: 20, alignSelf: "center", width: '100%', display: 'flex', flexDirection: 'column'}}>
                        {
                            document_url != null ?
                            <img src={document_url} />
                            : null
                        }

                    </div>
                                    
                    <div className='col-md-12'>
                        <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                            <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={ClosePhotoModel}>Close</button>
                        </div>
                    </div>
                </div>
        
            </div>
    </Modal>



     </div>
    )
 }
 export default KYCDocument_Franchise;