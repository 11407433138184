import { Link } from 'react-router-dom';
import Mydoc from './MyDocuments.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName } from "../../../../Store/Reducers/CommonReducer";


const MyDocuments = () =>{

    const dispatch = useDispatch();

    const { API_URL, userData, pageName} = useSelector(state => state.common);

    useEffect(() => {
        dispatch(setPageName('My Document'));
    }, []);



    return(
     <div className='mydocument'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className={Mydoc.card}>
                  <h3 className={Mydoc.hading}>Documents for Form-16A(AY2023-24) (4)</h3>
                  <Tabs>
                    <TabList>
                        <Tab>Form-16A(AY2023-24) (4)</Tab>
                        <Tab>5000 PBV Certificate (1)</Tab>
                        <Tab>Form-16A(AY2022-23) (1)</Tab>
                        <Tab>Form-16A(AY2021-22) (4)</Tab>
                    </TabList>
                    <div className={Mydoc.tabcontent}>
                    <TabPanel>
                     <table className={Mydoc.table}>
                     <thead>
                        <tr>
                        <th>S.No.</th>
                        <th>Date</th>
                        <th>Document Name</th>
                        <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                            <td><p>#1</p></td>
                            <td><p>20/07/2023</p></td>
                            <td><p>Form16A A.Y.2023-24(Q4)</p></td>
                            <td><span className={Mydoc.grdlabel}><i className="fa fa-download" aria-hidden="true"></i> Download</span></td>
                        </tr>
                        <tr>
                            <td><p>#1</p></td>
                            <td><p>20/07/2023</p></td>
                            <td><p>Form16A A.Y.2023-24(Q4)</p></td>
                            <td><span className={Mydoc.grdlabel}><i className="fa fa-download" aria-hidden="true"></i> Download</span></td>
                        </tr>
                        <tr>
                            <td><p>#1</p></td>
                            <td><p>20/07/2023</p></td>
                            <td><p>Form16A A.Y.2023-24(Q4)</p></td>
                            <td><span className={Mydoc.grdlabel}><i className="fa fa-download" aria-hidden="true"></i> Download</span></td>
                        </tr>
                        <tr>
                            <td><p>#1</p></td>
                            <td><p>20/07/2023</p></td>
                            <td><p>Form16A A.Y.2023-24(Q4)</p></td>
                            <td><span className={Mydoc.grdlabel}><i className="fa fa-download" aria-hidden="true"></i> Download</span></td>
                        </tr>
                        <tr>
                            <td><p>#1</p></td>
                            <td><p>20/07/2023</p></td>
                            <td><p>Form16A A.Y.2023-24(Q4)</p></td>
                            <td><span className={Mydoc.grdlabel}><i className="fa fa-download" aria-hidden="true"></i> Download</span></td>
                        </tr>
                     </tbody>
                     </table>
                    </TabPanel>
                    <TabPanel>
                    <h2>Any content 2</h2>
                    </TabPanel>
                    <TabPanel>
                    <h2>Any content 3</h2>
                    </TabPanel>
                    <TabPanel>
                    <h2>Any content 4</h2>
                    </TabPanel>
                    </div>
             
                  </Tabs>
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default MyDocuments;