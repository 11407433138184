import './Dashboard.css';
import check from '../../../assets/img/check.png'
import user from "../../../assets/img/profile.png";
import check_gray from '../../../assets/img/check-gray.png'
import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import Modal from 'react-modal';


const Dashboard = () =>{

    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;

    const dispatch = useDispatch();

    const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [dashboard_data, setDashboardData] = useState(null);

    const pagename = useCallback(() => {
        dispatch(setPageName('Dashboard'));
    },[pageName])

    useEffect(() => {
        pagename();
        fetchUserData();
        dashboardFetch();
    }, []);


    const fetchUserData = () => {
        postWithToken(API_URL, 'get-user-details', {}, userToken).then((result) => {
           if(result.status === true){
                dispatch(setUserDataAfterLogin(result.data));
            }else{
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const dashboardFetch = () => {
        postWithToken(API_URL, 'user/dashboard', {}, userToken).then((result) => {
           if(result.status === true){
            setDashboardData(result.data);
            }else{
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const [modalDocumentViewOpen, setDocumentViewModel] = useState(false);
    const [document_url,setDocumentUrl] = useState(null);
    const [modalBankViewOpen, setBankViewModel] = useState(false);

    const openDocumentInfo = (url) => {
        setDocumentUrl(url);
        setDocumentViewModel(true);
    }

    const ClosePhotoModel = () => {
        setDocumentViewModel(false);
        setDocumentUrl(null);
    }

    const CloseViewModel = () => {
        setBankViewModel(false);
    }

    const openBankInfo = () => {
        setBankViewModel(true);
    }


    return(
     <div className="dashboard">
         <CustomAlert
              header={"Document"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
         <div className='row'>
            <div className='col-md-4'>
                <div className='card'>
                    <h2>Maintained Level</h2>
                    {
                        dashboard_data != null ?
                        <span className='grdlabel'>{ dashboard_data.maintained_level}</span>
                        : null
                    }

                     
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card'>
                    <h2>Highest Level</h2>
                    { dashboard_data != null ?

                     <span className='grdlabel'>{ dashboard_data.hightest_level}</span>
                     : null}
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card'>
                    <h2>Status</h2>
                    {dashboard_data != null ?
                     <span className='grdlabel'>{ dashboard_data.maintained_level}</span>
                     : null}
                </div>
            </div>
         </div>
         <div className='row'>
           <div className='col-md-4'>
                <div className='card' style={{height: 280}}>
                   <div className='user'>
                   <div className='usericon'>
                    {
                        userData != null ?
                        
                        userData.profile_image_path != null ?
                        <img src={userData.profile_image_path}/>
                        :
                        <img src={user}/>
                        :null

                    }
                    </div>
                    <label>{userData != null ? userData.full_name :  null}</label>
                   </div>
                   <p><label>Count :</label> {dashboard_data != null ? dashboard_data.user_count : null}</p>
                   <p><label>Level :</label> {dashboard_data != null ? dashboard_data.level_commission_percentage + "%": null}</p>
                   <p><label>Joining Date:</label> {userData != null ? userData.joining_date : null}</p>
                   {/*<p><label>Purchase Amount for Referral :</label> 15%</p>*/}

                  
                </div>
            </div>

            <div className='col-md-4'>
            <div className='card' style={{height: 280}}>
            <img className='cardIcon' src={userData.is_bank_verified == 1 ? check : check_gray}/>
                <h2>Bank Account Verification Status</h2>
                {
                        userData.bank_proof_path != null ?
                        <>
                          {  userData.is_bank_verified == 1 ?
                          <>
                            <p>Your Bank Account is <span>Verified</span> and Your Bank status is <span>Confirmed</span></p>
                            <p>Bank Account No. : <span>{userData.account_no}</span></p>
                            <p>IFSC Code : <span>{userData.ifsc_code}</span></p>
                            <p>Bank Name : <span>{userData.bank_name}</span></p>
                            <p>Branch : <span>{userData.branch_name}</span></p>
                          </>   
                            : userData.is_bank_verified == 0 ?
                            <p>Your Bank Account is <span style={{color: '#fba300'}}>Pendding</span> for Verify</p>
                            : null}
                            <a onClick={() => {openBankInfo()}} className='viewBtn'>Click here to view Bank Documents <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                            </>
                         :null   
                    }

            </div>
            </div>

            <div className='col-md-4'>
            <div className='card' style={{height: 280}}>
            <img className='cardIcon' src={userData.is_address_verified == 1 ? check : check_gray}/>
                <h2>KYC Verification Status</h2>
                {
                        userData.address_proof_path != null ?
                           <>
                            {userData.is_address_verified == 1 ?
                            <p>Your KYC status is <span>Confirmed</span></p>
                            : userData.is_address_verified == 0 ?

                            <p>Your KYC status is <span style={{color: '#fba300'}}>Pendding</span></p>
                            : null}

                            <a onClick={() => {openDocumentInfo(userData.address_proof_path)}} className='viewBtn'>Click here to view KYC Documents <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                            </>
                         :null   
                    }
                
            </div>
        </div>
           
        </div>
        <div className='row'>
       
        
        <div className='col-md-4'>
            <div className='card'>
            <img className='cardIcon' src={userData.is_id_proof_verified == 1 ? check : check_gray}/>
                <h2>PAN Verification Status</h2>

                {
                        userData.id_proof_path != null ?
                           <>
                            {userData.is_id_proof_verified == 1 ?
                            <p>Your PAN is <span>Verified</span> and Your PAN status 
                            is <span>Confirmed</span></p>
                            : userData.is_id_proof_verified == 0 ?

                            <p>Your PAN is <span style={{color: '#fba300'}}>Pendding</span> for Verify</p>
                            : null}

                            <a onClick={() => {openDocumentInfo(userData.id_proof_path)}} className='viewBtn'>Click here to view Pan Documents <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                            </>
                         :null   
                }
            </div>
        </div>
        </div>


        <Modal isOpen={modalDocumentViewOpen} onRequestClose={ClosePhotoModel} contentLabel="Document Modal" >
            <div className='modal_header'>
             Document View <button onClick={ClosePhotoModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
            </button>
            </div>
            <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                <div className='row'>
                    <div style={{ marginTop: 20, alignSelf: "center", width: '100%', display: 'flex', flexDirection: 'column'}}>
                        {
                            document_url != null ?
                            <img src={document_url} />
                            : null
                        }

                    </div>
                                    
                    <div className='col-md-12'>
                        <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                            <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={ClosePhotoModel}>Close</button>
                        </div>
                    </div>
                </div>
        
            </div>
       </Modal>

       <Modal isOpen={modalBankViewOpen} onRequestClose={CloseViewModel} contentLabel="Document Modal" >
            <div className='modal_header'>
             Bank Infomation View <button onClick={CloseViewModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
            </button>
            </div>
            <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                <div className='row'>
                    <div className='col-md-12' >

                            <div className="row" >
                                <div className="col-md-12" >
                                        <div className="photoUpload_bank" style={{alignItems: 'center', justifyContent: 'center'}}>
                                            <div className="uploadePic_bank">
                                                    {
                                                        userData.bank_proof_path != null ?

                                                        <img src={userData.bank_proof_path} />

                                                        :  null
                                                    }
                                                    
                                            </div>
                                            
                                        </div>
                                </div>
                                
                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Account Holder Name : </label>
                                        <span style={{display: 'flex', flex: 1}}>{userData.account_holder_name}</span> 
                                        </div>
                                </div> 

                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Bank Name : </label>
                                        <span style={{display: 'flex', flex: 1}}>{userData.bank_name}</span> 
                                        </div>
                                </div> 

                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Branch Name : </label>
                                        <span style={{display: 'flex', flex: 1}}>{userData.branch_name}</span> 
                                        </div>
                                </div> 


                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Account Number : </label>
                                        <span style={{display: 'flex', flex: 1}}>{userData.account_no}</span> 
                                        </div>
                                </div>

                                <div className="col-md-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <label style={{fontWeight: 700, display: 'flex', flex: 1}}>IFSC Code : </label>
                                        <span style={{display: 'flex', flex: 1}}>{userData.ifsc_code}</span> 
                                        </div>
                                </div>


                            </div>
                           

                        </div>
                                    
                    <div className='col-md-12'>
                        <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                            <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseViewModel}>Close</button>
                        </div>
                    </div>
                </div>
        
            </div>
        </Modal>


     </div>
    )
 }
 export default Dashboard;