import   './IncomeAssociate.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';


const IncomeAssociate_Franchise = () =>{

  Moment.locale('en');
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();

  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [income_list, setIncomeList] = useState({IsLoading: true, list: [], total_count: 0});
  const [list_limit, setListLimit] = useState(20);
  const [page_selected, setPageSelected] = useState(null);
  const [total_sale, setTotalSale] = useState(null);
  const [total_income, setTotalIncome] = useState(null);


  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");

  const pagename = useCallback(() => {
     dispatch(setPageName('Income Associate'));
    },[pageName])

   useEffect(() => {
       pagename();
   }, []);

   useEffect(() => {
   if(userToken != ''){
       IncomeFetch(0,list_limit); 
   }
   }, [userToken]); 
   
   const IncomeFetch = (offset, limit) => {
       postWithToken(API_URL, 'francise/associate-incomes', {offset: offset, limit: limit, from_date: from_date, to_date: to_date}, userToken).then((result) => {
           //console.log(result);
           if(result.status === true){
               setIncomeList({IsLoading : false, list: result.data, total_count: result.total_count});
               setTotalSale(result.total);
               setTotalIncome(result.total_income);
           }
       })
       .catch((error) => {
           console.log("Error : ", error);
       });
   }

   //alert start
 
  const [alert, setAlert] = useState({
   type: null,
   text: [],
   show: false
 })

 function onCloseAlert(){
   setAlert({
     show: false,
     type: null,
     text: [],
   })
 }

 function onShowAlert(type,msg){
   setAlert({
     type: type,
     text: msg,
     show: true
   })
 }

//alert end 



$(function(){
   var dtToday = new Date();
 
   var month = dtToday.getMonth() + 1;
   var day = dtToday.getDate();
   var year = dtToday.getFullYear();
 
   if(month < 10)
       month = '0' + month.toString();
   if(day < 10)
       day = '0' + day.toString();
 
   var maxDate = year + '-' + month + '-' + day;    
   $('#from_date').attr('max', maxDate);
   $('#to_date').attr('max', maxDate);
 });

 const setDate = (val, type) => {

   const all_error_msg = [];

   if(type == "from"){

       if(to_date != ""){
           if(Date.parse(val) > Date.parse(to_date)){
               all_error_msg.push("From date must be less then to date");
              onShowAlert("error", all_error_msg);
           }else{
               setFromDate(val);
           }
       }else{
           setFromDate(val);
       }

   }else if(type == "to"){
       if(from_date != ""){
           if(Date.parse(from_date) > Date.parse(val)){
               all_error_msg.push("To date must be greater than from date");
               onShowAlert("error", all_error_msg);
           }else{
               setToDate(val);
           }
       }else{
           setToDate(val);
       }
   }

 }

 const clear_date = () => {
   setFromDate("");
   setToDate("");
   IncomeFetch(0,list_limit); 
 }

 const filter_by_date = () => {

   let flg = true;
   const all_error_msg = [];

   if (from_date == "") {
     all_error_msg.push("please select from date");

     if (flg === true) {
       flg = false;
     }
   }
   
   if (to_date == "") {
     all_error_msg.push("please select to date");
     if (flg === true) {
       flg = false;
     }
   }

   if(from_date != "" && to_date != ""){
       if(Date.parse(from_date) > Date.parse(to_date)){
           all_error_msg.push("From date must be less then to date");
       }

       if(Date.parse(from_date) > Date.parse(to_date)){
           all_error_msg.push("To date must be greater than from date");
       }
   }

   if (flg === true) {
       IncomeFetch(0,list_limit); 
   }else{
       onShowAlert("error", all_error_msg);
       
   } 

 }

 const [itemOffset, setItemOffset] = useState(0);
       function PaginatedItems({ itemsPerPage }) {
           // Here we use item offsets; we could also use page offsets
           // following the API or data you're working with.
          
           // Simulate fetching items from another resources.
           // (This could be items from props; or items loaded in a local state
           // from an API endpoint with useEffect and useState)
           const endOffset = itemOffset + itemsPerPage;
           //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
           //const currentItems = roleList.list.slice(itemOffset, endOffset);
           const pageCount = Math.ceil(income_list.total_count / itemsPerPage);
         
           // Invoke when user click to request another page.
           const handlePageClick = (event) => {
             const newOffset = (event.selected * itemsPerPage) % income_list.total_count;
             /*console.log(
               `User requested page number ${event.selected}, which is offset ${newOffset}`
             );*/

             setPageSelected(event.selected);
             IncomeFetch(newOffset, list_limit);
             setItemOffset(newOffset);
           };
           return (
             <>
               <ReactPaginate
                 breakLabel="..."
                 nextLabel=" > "
                 onPageChange={handlePageClick}
                 pageRangeDisplayed={5}
                 pageCount={pageCount}
                 previousLabel=" < "
                 renderOnZeroPageCount={null}
                 forcePage={page_selected}
               />
             </>
           );
         }

   return(
    <div className='incomeA'>

        <CustomAlert
              header={"Income Franchise"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
             />  

             {
               loading === true ?
               <div className="activityLoader">
                     <GridLoader
                       color={color}
                       loading={loading}
                       cssOverride={override}
                       size={30}
                       aria-label="Loading Spinner"
                       data-testid="loader"
                       Loader="GridLoader"
                     /> 
              </div>     
              : null
             }
       <div className='row'>

           <div  className='col-md-12'>
               <div className="row">
                  <div className='col-md-2'>
                    <div className='form-group'>
                       <input type='date' style={{height: 45, paddingLeft: 10}} id="from_date" placeholder='From Date' value={from_date} onChange={(event) => {setDate(event.target.value,'from')}}/>
                    </div>
                   </div>
                   <div className='col-md-2'>
                    <div className='form-group'>
                       <input type='date' style={{height: 45, paddingLeft: 10}} id="date_of_birth" placeholder='To Date' value={to_date} onChange={(event) => {setDate(event.target.value,'to')}}/>
                    </div>
                   </div>
                   <div className='col-md-3'>
                       <div className='form-group'>
                            <button type='button' className='sucessBtn' onClick={() => {filter_by_date()}} >Filter</button>
                            <button type='button' className='cancelBtn' onClick={() => {clear_date()}}>Clear</button>
                       </div>
                   </div>

                   <div className='col-md-2'>
                       <div className='form-group' style={{paddingTop: 10, fontWeight: 600}}>
                           Total Sale : {total_sale != null ? <>{'\u20B9'} {total_sale}</> :  null}
                       </div>
                   </div>
                   <div className='col-md-2'>
                       <div className='form-group' style={{paddingTop: 10, fontWeight: 600}}>
                           Total Income : { total_income != null ? <>{'\u20B9'} {total_income}</> : null}
                       </div>
                   </div>
               </div>

           </div>

           <div  className='col-md-12'>
               <div className='card'>
                 <table className='table'>
                   <thead>
                       <tr>
                           <th>ID</th>
                           <th>Products Name</th>
                           <th>Date</th>
                           <th>Amount</th>
                           <th>Sale To</th>
                           <th>BPV</th>
                           <th>Profit</th>
                       </tr>
                   </thead>
                   {
                      income_list.IsLoading === false && income_list.list.length > 0 ?
                           <tbody>
                                {
                                       income_list.list.map((item, index) => {
                                       return(
                                       <tr key={'income_' + index}>
                                               <td>{item.id}</td>
                                               <td >
                                                   <ul className="product_details_ul" >
                                                       {
                                                           item.order_details.map((item, index) => {

                                                               return(
                                                                   <li key={'product_ordered_' + index}>{item.product.name} x {item.quantity}</li>
                                                               )
                                                           })
                                                       }
                                                   </ul>
                                               </td>
                                               <td>{Moment(item.date).format('DD/MM/YYYY')}</td>
                                               <td>{'\u20B9'} {item.total}</td>
                                               <td>{item.user.name}</td>
                                               <td>{item.total_points}</td>
                                               <td>{'\u20B9'} {item.total_income}</td>
                                       </tr>
                                       
                                       );
                                       })
                                   }
                                                              
                           </tbody>
                       : income_list.IsLoading === false && income_list.list.length == 0 ?
                       <tbody>
                           <tr>
                               <td colSpan={7} style={{textAlign: "center"}}><b>No Record Found</b></td>
                           </tr>
                       </tbody>

                       : income_list.IsLoading === true ?
                           <tbody>
                           <tr>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                               <td ><Skeleton height={24} /></td>
                           </tr>

                           </tbody>

                   : null }
                 </table>
               </div>

               { income_list.IsLoading === false && income_list.list.length > 0 ?

                  <div className="pagination_div">
                          <PaginatedItems itemsPerPage={list_limit} />
                  </div>
                  : null
                }   
           </div>
       </div>
    </div>
   )
 }
 export default IncomeAssociate_Franchise;