import   './MyPcs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const MyPcs = () =>{
    return(
     <div className='MyPcs'>
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
              
                <Tabs>
                    <TabList>
                        <Tab>All (0)</Tab>
                        <Tab>Preferred Customer (0)</Tab>
                        <Tab>Become An Associate (0)</Tab>
                        <Tab>Converted To Associate (0)</Tab>
                    </TabList>
                    <div className='tabcont'>
                    <TabPanel>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th colSpan={8}></th>
                                    <th colSpan={2}>Current Month</th>
                                    <th colSpan={2}>Total Cummulative</th>
                                    <th colSpan={2}></th>
                                </tr>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Registered On</th>
                                    <th>ID No.</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>District</th>
                                    <th>City</th>
                                    <th>Mobile No</th>
                                    <th>BV</th>
                                    <th>Amount</th>
                                    <th>BV</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Associate No.</th>
                                </tr>
                            </thead>
                            <tbody>
                             <tr>
                                <td colSpan={14}>
                                    <p>No Result Found</p>
                                </td>
                             </tr>
                            </tbody>
                        </table>
                    </TabPanel>
                    </div>
                 
                    </Tabs>
             
   
    
                </div>
            </div>
        </div>
     </div>
    )
 }
 export default MyPcs;