import './Signup.css';
import logo from '../assets/img/logo.png';
import mail from '../assets/img/mail.png';
import lock from '../assets/img/lock.png';
import user from '../assets/img/users.png';
import call from '../assets/img/call.png';

import React, {useEffect, useState, CSSProperties, useRef} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import OtpInput from 'react-otp-input';

const ForgotPassword = () =>{
    const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [email, setEmail] = useState("");

  const [modalEmailOpen, setModelEmailOpen] = useState(false);
  const [email_otp, setEmailOtp] = useState('');

  const [minutes_email, setMinutesEmail] = useState(1);
  const [seconds_email, setSecondsEmail] = useState(30);

  
  useEffect(() => {
    if(modalEmailOpen === true){
      const interval_email = setInterval(() => {
        if (seconds_email > 0) {
          setSecondsEmail(seconds_email - 1);
        }
      
        if (seconds_email === 0) {
        if (minutes_email === 0) {
          clearInterval(interval_email);
        } else {
          setSecondsEmail(59);
          setMinutesEmail(minutes_email - 1);
        }
        }
      }, 1000);

      return () => {
        clearInterval(interval_email);
      };

    }
    
	}, [seconds_email, modalEmailOpen]);

    function CloseEmailModel(){
        setModelEmailOpen(false);
        resetForm();
    }

    //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setEmail("");
    setEmailOtp("");
  
  }

  const ForgotAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if(email.search(/\S/) == -1){
     
        all_error_msg.push("Please enter email Id");
        if(flg == true){flg = false;}
      
    }else if(email.search(/\S/) != -1){
   
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(email) === false) {
        all_error_msg.push("Please enter valid email Id");
        if(flg == true){flg = false;}
      }
    }
    
    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'forgot-password', {email: email}).then((result) => {
          if(result.success === true){
                setLoading(false);
                setModelEmailOpen(true);
            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

  

  const sendEmailOtp = () => {
    const all_error_msg = [];
      postWithOutToken(API_URL, 'forgot-password', {email: email}).then((result) => {
          if(result.status === true){
              all_error_msg.push(result.message);
              onShowAlert("success", all_error_msg); 
                          
              setMinutesEmail(1);
              setSecondsEmail(30);

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
  }

  const VerifyEmailOtp = () => {
    let flg = true;
    const all_error_msg = [];

   
      if(email_otp.length < 6){
        all_error_msg.push("Please enter 6 digit otp to verify your email");
        if(flg == true){flg = false;}
      }


    if(flg == true){
        setLoading(true);
        postWithOutToken(API_URL, 'forgot-password-check-otp', {email: email,otp: email_otp}).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.success === true){
                resetForm();
                setModelEmailOpen(false);

                  navigate("/ResetPassword", {state: {email: email}});
               
                
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }


 return(
    <div className="authapper">
    <div className="authapper-body">
    <CustomAlert
              header={"Forgot Password"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
              


    <div className="authapper-left">
        <div className='logo'>
            <img src={logo}/>
        </div>
        <p>Lorem Ipsum is simply dummy text of the printing and 
        typesetting industry. Lorem Ipsum has been the industry's 
        standard dummy text ever since the 1500s</p>
        <p><a href='mailto:info@yoursitename.com'><i className="fa fa-envelope" aria-hidden="true"></i> info@yoursitename.com</a></p>
        <p><a href='tel:+00 00000000'><i className="fa fa-phone" aria-hidden="true"></i> +00 00000000</a></p>
        <span className='copyright'>Veda Harbal Copyright © 2024, Version 0.0.1</span>
     </div>
     <div className="authapper-right">
         <h2>Forgot Password</h2>
         <form onSubmit={ForgotAction}>
                     
            <div className='form-group'>
            <img src={mail}/>
                <input type='text' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
            </div>
           
            <button type='submit' className='themeBtn'>Submit</button>
            <div className='formbtm'>
             <p>Already have an account ? <Link to={'/'}>LOGIN</Link></p>
            </div>
         </form>
     </div>
     </div>

    
 <Modal isOpen={modalEmailOpen} onRequestClose={CloseEmailModel} contentLabel=" OTP Modal" >
    <div className='modal_header'>
    OTP Verification <button onClick={CloseEmailModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
    </button>
    </div>
    <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
        
            <div className='row'>

                    
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Enter Email OTP</h2>
                    </div>

                    <div className='col-md-12 otp_input_parent_div' >
                        
                        <OtpInput
                            value={email_otp}
                            onChange={setEmailOtp}
                            numInputs={6}
                            inputType={'tel'}
                            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                            renderInput={(props) => <input {...props} />}
                            />
                    </div>

                    <div style={{ marginTop: 20, alignSelf: "center", width: '100%', display: 'flex', flexDirection: 'column'}}>
                        <>
                        {
                        seconds_email > 0 || minutes_email > 0 ? 
                        <span style={{textAlign: 'center'}} >Time Remaining: {minutes_email < 10 ? `0${minutes_email}` : minutes_email}:{seconds_email < 10 ? `0${seconds_email}` : seconds_email}</span>
                        : <span style={{textAlign: 'center'}}>Didn't recieve code?</span>
                        }

                        <div style={{ marginTop: 20, alignSelf: "center" }}>
                            {
                                seconds_email > 0 || minutes_email > 0 ?
                                <button type="button" className='fadebtn_resend'>Resend Email OTP</button>
                                : 
                                <button type="button" className='fillbtn' onClick={sendEmailOtp}>Resend Email OTP</button>
                                
                                
                            }
                        </div>

                        </>

                        </div>

                                
                <div className='col-md-12'>
                    <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                        <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseEmailModel}>Cancel</button>
                        <button type='button' className='fillbtn' style={{width: 'auto'}} onClick={VerifyEmailOtp} >Verify OTP</button>
                    </div>
                </div>
            </div>
        
    </div>
    </Modal>
    

    </div>

 )
}
export default ForgotPassword;