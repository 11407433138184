import   './MyIncentive.css';
import React, {useEffect, useState, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';
import ReactPaginate from 'react-paginate';

const MyIncentive = () =>{

    Moment.locale('en');
   const navigate = useNavigate();
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#017A08");
 
   const CSSProperties = {
     display: "block",
     margin: "0 auto",
     borderColor: "red",
   };
 
   const override = CSSProperties;

   const dispatch = useDispatch();

   const { API_URL, SITE_URL, userData, pageName, userToken} = useSelector(state => state.common);
   const [income, setIncome] = useState({IsLoading: true, list: [], total_count: 0});
   const [list_limit, setListLimit] = useState(20);
   const [page_selected, setPageSelected] = useState(null);

   const [list_type,  setListType] = useState('P');

   const [total_commission, setTotalCommission] = useState(0);
   const [total_point, setTotalPoint] = useState(0);


   useEffect(() => {
       dispatch(setPageName('My incentive'));
   }, []);

   useEffect(() => {
    if(userToken != ''){
      IncentiveFetch(0,list_limit,list_type); 
    }
  }, [userToken]); 

   const IncentiveFetch = (offset, limit, type) => {
    let post_data = {};
    let api_url = "";

      if(type == 'P'){
        post_data = {
            offset: offset, 
            limit: limit,
          };
          api_url = "user/my-incomes";  
      }else if(type == 'L'){
        post_data = {
            offset: offset, 
            limit: limit,
          };
          api_url = "user/leadership-incomes"; 
      }else if(type == 'D'){
        post_data = {
            offset: offset, 
            limit: limit,
          };
          api_url = "user/development-incomes"; 
      }else if(type == 'A'){
        post_data = {
            offset: offset, 
            limit: limit,
          };
          api_url = "user/all-incomes"; 
      }

      setLoading(true);

      postWithToken(API_URL, api_url, post_data, userToken).then((result) => {
        console.log(result);
          if(result.status === true){
            setLoading(false);
            setIncome({IsLoading : false, list: result.data, total_count: result.total_count});

            setTotalCommission(result.total_commission);
            setTotalPoint(result.total_point);
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

   //alert start
  
   const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
})

function onCloseAlert(){
      setAlert({
      show: false,
      type: null,
      text: [],
      })
}

function onShowAlert(type,msg){
      setAlert({
      type: type,
      text: msg,
      show: true
      })
}

//alert end 

       const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(income.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % income.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              IncentiveFetch(newOffset, list_limit, list_type);
              setItemOffset(newOffset);
            };
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }


          const listTypeChange = (type) => {
            setListType(type);
           
            IncentiveFetch(0,list_limit,type); 
        }


    return(
     <div className='myincentive'>
        <CustomAlert
              header={"Direct Downline"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className='row'>
            <div  className='col-md-12'>
                <div className='card'>
                   <ul>
                        <li><a onClick={() => {listTypeChange('P')}}><span className={list_type == "P" ? 'type_li active' : 'type_li'}>Purchase</span></a></li>
                        <li><a onClick={() => {listTypeChange('L')}}><span className={list_type == "L" ? 'type_li active' : 'type_li'}>Leadership</span></a></li>
                        <li><a onClick={() => {listTypeChange('D')}}><span className={list_type == "D" ? 'type_li active' : 'type_li'}>Development</span></a></li>
                        <li><a onClick={() => {listTypeChange('A')}}><span className={list_type == "A" ? 'type_li active' : 'type_li'}>All Incentive</span></a></li>
                    </ul>


                  <table className='table'>
                    <thead>
                        <tr>
                            <th>ID.</th>
                            <th>Date</th>
                            <th>Total Point</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                       income.IsLoading === false && income.list.length > 0 ?
                      
                       income.list.map((item, index) => {

                            return(
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{Moment(item.date).format('DD/MM/YYYY')}</td>
                                    <td>{item.total_points}</td>
                                    <td>{'\u20B9'} {item.commission_amount}</td>

                                </tr>
                            );
                         })

                         : income.IsLoading === false && income.list.length == 0 ?

                         <tr>
                            <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                        </tr>

                        : income.IsLoading === true ?

                            <tr>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                                <td ><Skeleton height={24} /></td>
                            </tr>

                        : null }
                   
                      
                    </tbody>
                    

                  </table>
                 
                  </div>

                  { income.IsLoading === false && income.list.length > 0 ?

                        <div style={{textAlign: 'right', padding: 20}}>
                                <div> <b>Total Point :</b> {total_point}</div>
                                <div style={{marginTop: 10}}> <b>Total Amount :</b> {'\u20B9'} {total_commission}</div>
                        </div> 

                        : null
                        
                  }
                      
                



                {
                    income.IsLoading === false && income.list.length > 0 && list_type != 'A'?
                        <div className="pagination_div">
                                <PaginatedItems itemsPerPage={list_limit} />
                        </div>
                    :
                    null
                }       
            </div>
        </div>
     </div>
    )
 }
 export default MyIncentive;