import logo from '../assets/img/logo.png';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";

const PageNotFound = () =>{
   
   return(
            <div className="pagenotfound">
                <div>
                        Page Not Found
                </div>
                
            </div>
   )
}
export default PageNotFound;